<h2 mat-dialog-title>
  {{title}}
</h2>

<div mat-dialog-content>
  <p>{{message}}</p>
  <div class="row" *ngIf="showCategory">
    <div class="col-md-12">
      <div class="position-relative form-group">
        <mat-form-field class="field-full-width" appearance="outline">
          <mat-label>Categoría</mat-label>
          <mat-select [(ngModel)]="idCategoria" [required]="true" [disabled]="false" (valueChange)="onSelectCategory($event)">
            <mat-option *ngFor="let categ of listCategories" [value]="categ.id">
              {{ categ.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="showCategory">
    <div class="col-md-12">
      <div class="position-relative form-group">
        <mat-form-field class="field-full-width" appearance="outline">
          <mat-label>Usuario</mat-label>
          <mat-select [(ngModel)]="idJefeTurno" [required]="true" [disabled]="false">
            <mat-option *ngFor="let user of listUser" [value]="user.id">
              {{ user.nombreApellidos }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div> 
  <div class="row" *ngIf="!showCategory">
    <div class="col-md-12">
      <div class="position-relative form-group">
        <mat-form-field class="field-full-width" appearance="outline">
          <mat-label>J. Turno</mat-label>
          <mat-select [(ngModel)]="idJefeTurno" [required]="false" [disabled]="false">
            <mat-option *ngFor="let jefeTurmo of listJefeTurno" [value]="jefeTurmo.id">
              {{ jefeTurmo.nombreApellidos }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>  
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="onConfirm()" *ngIf="confirmBottom && !showCategory">{{confirmLabelBottom}}</button>
  <button mat-raised-button color="primary" (click)="onConfirm()" *ngIf="confirmBottom && showCategory" [disabled]="!idCategoria || !idJefeTurno">{{confirmLabelBottom}}</button>
  <button mat-button (click)="onDismiss()" *ngIf="dismissBottom">{{dismissLabelBottom}}</button>
</div>
