import {
  LoginRequest,
  ProvinceEntity,
} from './../../../shared/models/login-request.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Routes } from 'src/app/shared/consts/routes';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CategoryEntity } from 'src/app/shared/models/user-model';
import { MatDialog } from '@angular/material/dialog';
import {TracesService} from "../../../features/traces/services/traces.service";
import {ModoTrabajo} from "../../../shared/consts/modo-trabajo";
import {
  CreateShiftDialogComponent,
  ShiftDialogModel
} from "../../../shared/components/create-shift-dialog/create-shift-dialog.component";
import { ErrorDialogComponent, ErrorDialogModel } from 'src/app/shared/components/error-dialog/error-dialog.component';
import { Subscription } from 'rxjs';
import { WebServiceWorkerService } from 'src/app/shared/services/web-service-worker.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm!: FormGroup;
  public routes: typeof Routes = Routes;

  /** Categories */
  listCategories: CategoryEntity[] = [];

  /** Provinces */
  listProv: ProvinceEntity[] = [];
  selectedProv: ProvinceEntity[] = [];
  listWorkMode: any[] = [];

  /**para el manejo de errores */
  errors: Array<{ name: string; message: string }> = [];
  MSG_EMPTY?: string = 'none';

  traceHeadInsert = 'Entró al sistema: ';
  traceHeadOpenShift = 'Se abrió el turno: ';
  traceUserName = 'Nombre: ';
  traceUserCategory = 'Categoría: ';
  traceUser = 'Usuario: ';
  traceEmail = 'Correo: ';
  traceTelefCelular = 'Teléfono Celular: ';
  traceUserProvince = 'Provincia: ';
  traceSeparator = ', ';

  isNewAppVersionAvailable: boolean = false;
  newAppUpdateAvailableSubscription: Subscription | undefined;

  annoActual: number | undefined;

  constructor(
    private formBuilder: FormBuilder,
    protected dialog: MatDialog,
    protected authService: AuthService,
    protected router: Router,
    protected ngxLoaderService: NgxUiLoaderService,
    private notificacionService: NotificationService,
    private tracesService: TracesService, private webServiceWorker: WebServiceWorkerService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required],
      category: ['', Validators.required],
      prov: [1, Validators.required],
      workMode: [ModoTrabajo.ONLINE, Validators.required],
    });
    this.saveWorkMode();

    this.loadCategoriesData();
    this.loadProvincesData();
    this.loadWorkMode();

    this.checkIfAppUpdated();

    const fechaActual = new Date();
    this.annoActual = fechaActual.getFullYear();
  }

  checkIfAppUpdated() {
    this.newAppUpdateAvailableSubscription = this.webServiceWorker.$isAnyNewUpdateAvailable.subscribe((versionAvailableFlag: boolean) => {
      this.isNewAppVersionAvailable = versionAvailableFlag;
      if(this.isNewAppVersionAvailable){
        const dialogData = new ConfirmDialogModel('Información', 'Una nueva versión de la aplicación está disponible. Actualice la ventana de su navegador o haga clic en la opción Actualizar',true,'Actualizar', true, 'Cerrar');

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          maxWidth: '400px',
          data: dialogData,
        });

        dialogRef.afterClosed().subscribe((dialogResult) => {
          if (dialogResult) {
            localStorage.clear();
            window.location.reload();
          }
        });
        //this.notificacionService.notificationInfoUpdate('Una nueva versión de la aplicación está disponible. Actualice la ventana de su navegador o haga clic en la opción Actualizar');
      }
    });
  }  

  ngOnDestroy() {
    this.newAppUpdateAvailableSubscription?.unsubscribe();
  }

  get f() {
    return this.loginForm.controls;
  }

  login() {
    const loginRequest: LoginRequest = {
      username: this.f.user.value,
      password: this.f.password.value,
      idCategoria: this.f.category.value
    };

    this.ngxLoaderService.start();
    this.authService.login(loginRequest, false, 'USUARIO', 'WINDOWS').subscribe({
      next: (res) => {
        setTimeout(() => {
          this.ngxLoaderService.stop();
          this.saveUserProvince();

          if((res.result.rolDescCorta && res.result.rolDescCorta === 'OPER_PRINCIPAL') || (res.result.categoria && (res.result.categoria === 'Refuerzo' || res.result.categoria === 'Ayudante'))){
            this.router.navigate([this.routes.LIST_SERVICES, 'in_process', 'tab', 'searching']);
          } else if (res.result.rolDescCorta && res.result.rolDescCorta === 'HORARIO') {
            this.router.navigate([this.routes.LIST_HORARIO]);
          } else {
            this.router.navigate([this.routes.HOME]);
          }

          /** Insertando traza */
          let detail = this.traceHeadInsert + '\n';
          detail += this.traceUserName + (res.result.nombreApellidos ? res.result.nombreApellidos : '-') + this.traceSeparator + '\n';
          detail += this.traceUserCategory + (res.result.categoria ? res.result.categoria : '-') + this.traceSeparator + '\n';
          detail += this.traceUser + (res.result.username ? res.result.username : '-') + this.traceSeparator + '\n';
          detail += this.traceEmail + (res.result.email ? res.result.email : '-') + this.traceSeparator + '\n';
          detail += this.traceTelefCelular + (res.result.telefCelular ? res.result.telefCelular : '-') + this.traceSeparator + '\n';
          detail += this.traceUserProvince + (this.selectedProv[0].nombre ? this.selectedProv[0].nombre : '-') + this.traceSeparator + '\n';

          this.tracesService.saveTrace('usuario_logueado', 'Login', detail, undefined, undefined,
            res.result.id, res.result.categoriaId, res.result.nombreApellidos, res.result.telefCelular);
        }, 1000);
      },
      error: (err) => {
        this.ngxLoaderService.stop();
        if (err === 'No existe ningún turno abierto. ¿Desea abrirlo?') {
          /** No existe turno creado */
          this.ngxLoaderService.stop();
          this.showPopupCreateShift(
            'No existe ningún turno abierto. ¿Desea abrirlo?'
          );
        } else {
          if(err.name=='HttpErrorResponse'){
            //this.notificacionService.notificationError('Revise su conexión');
            let msg = 'Por favor, revise su conexión.';
            const dialogData = new ErrorDialogModel('Error', msg);
            const dialogRef = this.dialog.open(ErrorDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });

            dialogRef.afterClosed().subscribe((dialogResult) => {
              if (dialogResult) {
              }
            });
          }          
          else {
          //this.notificacionService.notificationError(err);
            const dialogData = new ErrorDialogModel('Error', err);
            const dialogRef = this.dialog.open(ErrorDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });

            dialogRef.afterClosed().subscribe((dialogResult) => {
              if (dialogResult) {
              }
            });
          }
        }
      },
    });
  }

  showPopupCreateShift(msg: string) {
    const dialogData = new ShiftDialogModel('Crear Turno', msg,
      true, 'Sí', true, 'No', false);

    const dialogRef = this.dialog.open(CreateShiftDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult.result) {
        const loginRequest: LoginRequest = {
          username: this.f.user.value,
          password: this.f.password.value,
          idCategoria: this.f.category.value,
          idJefeTurno: dialogResult.idJefeTurno
        };

        this.ngxLoaderService.start();
        this.authService.login(loginRequest, true, 'USUARIO', 'WINDOWS').subscribe({
          next: (res) => {
            setTimeout(() => {
              this.ngxLoaderService.stop();
              this.saveUserProvince();

              if((res.result.rolDescCorta && res.result.rolDescCorta === 'OPER_PRINCIPAL') || (res.result.categoria && (res.result.categoria === 'Refuerzo' || res.result.categoria === 'Ayudante'))){
                this.router.navigate([this.routes.LIST_SERVICES, 'in_process', 'tab', 'searching']);
              } else {
                this.router.navigate([this.routes.HOME]);
              }

              /** Insertando traza */
              let detail = this.traceHeadOpenShift + (res.result.turnoId ? res.result.turnoId : '-') + this.traceSeparator + '\n';
              detail += this.traceUserName + (res.result.nombreApellidos ? res.result.nombreApellidos : '-') + this.traceSeparator + '\n';
              detail += this.traceUserCategory + (res.result.categoria ? res.result.categoria : '-') + this.traceSeparator + '\n';
              detail += this.traceUser + (res.result.username ? res.result.username : '-') + this.traceSeparator + '\n';
              detail += this.traceEmail + (res.result.email ? res.result.email : '-') + this.traceSeparator + '\n';
              detail += this.traceTelefCelular + (res.result.telefCelular ? res.result.telefCelular : '-') + this.traceSeparator + '\n';
              detail += this.traceUserProvince + (this.selectedProv[0].nombre ? this.selectedProv[0].nombre : '-') + this.traceSeparator + '\n';

              this.tracesService.saveTrace('usuario_logueado', 'Login', detail);
            }, 1000);
          },
          error: (err) => {
            this.ngxLoaderService.stop();            
            if (err === 'No existe ningún turno abierto. ¿Desea abrirlo?') {
              /** No existe turno creado */
              this.ngxLoaderService.stop();
              this.showPopupCreateShift(
                'No existe ningún turno abierto. ¿Desea abrirlo?'
              );
            } else {
              //this.notificacionService.notificationError(err);
              const dialogData = new ErrorDialogModel('Error', err);
              const dialogRef = this.dialog.open(ErrorDialogComponent, {
                maxWidth: '400px',
                data: dialogData,
              });

              dialogRef.afterClosed().subscribe((dialogResult) => {
                if (dialogResult) {
                }
              });
            }
          },
        });

        /*** Este es el crear turno viejo*/
        // this.authService.createShift(loginRequest).subscribe({
        //   next: (res) => {
        //     setTimeout(() => {
        //       this.ngxLoaderService.stop();
        //       this.saveUserProvince();
        //       this.router.navigate([this.routes.HOME]);
        //     }, 1000);
        //   },
        //   error: (err) => {
        //     this.ngxLoaderService.stop();
        //     this.notificacionService.notificationError(err);
        //   },
        // });
      }
    });
  }

  showError(e: string): void {
    this.errors = [];
    const nom: string = e;
    if (this.loginForm.contains(nom)) {
      const errNom = this.loginForm.controls[nom];
      if (errNom.errors) {
        if (errNom.errors.required === true) {
          this.errors.push({ name: nom, message: 'Campo requerido' });
        }
      }
    }
  }

  getMessageError(messageKey: string) {
    return this.errors.filter((item) => item.name === messageKey).length > 0
      ? this.errors.filter((item) => item.name === messageKey)[0].message
      : this.MSG_EMPTY;
  }

  loadCategoriesData(): void {
    if (localStorage.getItem('listCategories') != null){
      const categories = localStorage.getItem('listCategories');
      if (categories) {
        this.listCategories = JSON.parse(categories).listCategories;
      }
    } else {
      this.ngxLoaderService.start();
      this.authService.getCategories().subscribe({
        next: (res) => {
          this.listCategories = res.result;

          localStorage.setItem(
            'listCategories',
            JSON.stringify({
              listCategories: res.result,
            })
          );

          this.ngxLoaderService.stop();
        },
        error: (err) => {
          this.ngxLoaderService.stop();
          let msg = 'Lo sentimos, ocurrió un error al obtener las categorías.';
          const dialogData = new ErrorDialogModel('Error', msg);
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            maxWidth: '400px',
            data: dialogData,
          });
        },
      });
    }
  }

  loadProvincesData(): void {
    if (localStorage.getItem('listProvince') != null){
      const provinces = localStorage.getItem('listProvince');
      if (provinces) {
        this.listProv = JSON.parse(provinces).listProvince;
      }
    } else {
      this.ngxLoaderService.start();
      this.authService.getProvinces().subscribe({
        next: (res) => {
          this.listProv = res.result;

          localStorage.setItem(
            'listProvince',
            JSON.stringify({
              listProvince: res.result,
            })
          );

          this.ngxLoaderService.stop();
        },
        error: (err) => {
          this.ngxLoaderService.stop();
          let msg = 'Lo sentimos, ocurrió un error al obtener las provincias.';
          const dialogData = new ErrorDialogModel('Error', msg);
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            maxWidth: '400px',
            data: dialogData,
          });
        },
      });
    }
  }

  saveUserProvince(): void {
    this.selectedProv = this.listProv.filter(
      (prov) => prov.id === this.f.prov.value
    );
    this.authService.saveCurrentUserProvince(this.selectedProv);
  }

  saveWorkMode(): void {
    this.authService.saveWorkMode(this.loginForm.controls.workMode.value);
  }

  /**
   * Metodo para obtener los modos de trabajo
   */
  loadWorkMode() {
    this.ngxLoaderService.start();
    this.listWorkMode = this.authService.getWorkMode();
    this.ngxLoaderService.stop();
  }

  onSelectWorkMode(event: any){
    this.authService.saveWorkMode(event);
  }

  refreshWorkMode() {
    this.loadCategoriesData();
    this.loadProvincesData();
  }
}
