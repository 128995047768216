import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {RouteService} from "../../services/route.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {PersonService} from "../../../features/users/services/persons.service";
import {NotificationService} from "../../services/notification.service";
import {CategoryEntity, PersonEntity, PersonRequest} from "../../models/person-model";
import { AuthService } from 'src/app/core/auth/auth.service';
import { ErrorDialogComponent, ErrorDialogModel } from '../error-dialog/error-dialog.component';
import { OpenTurnModel } from '../../models/turn-model';

@Component({
  selector: 'app-create-shift-dialog',
  templateUrl: './create-shift-dialog.component.html',
  styleUrls: ['./create-shift-dialog.component.scss']
})
export class CreateShiftDialogComponent implements OnInit, OnDestroy{

  title: string;
  message: string;
  confirmBottom: boolean;
  confirmLabelBottom: string;
  dismissBottom:boolean;
  dismissLabelBottom:string;
  showCategory: boolean;

  idJefeTurno!: number;
  listJefeTurno: any; // PersonEntity[] = [];

  closeModalSubscription: Subscription;

  idCategoria!: any;
  listUser: PersonEntity[] = [];

  /** Categories */
  listCategories: CategoryEntity[] = [];

  turn!: OpenTurnModel;

  constructor(public dialogRef: MatDialogRef<CreateShiftDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ShiftDialogModel, private personService: PersonService,
              private routeService: RouteService, protected ngxLoaderService: NgxUiLoaderService,
              private notificacionService: NotificationService, protected authService: AuthService, protected dialog: MatDialog) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;

    this.confirmBottom = data.confirmBottom;
    this.confirmLabelBottom = data.confirmLabelBottom;
    this.dismissBottom = data.dismissBottom;
    this.dismissLabelBottom = data.dismissLabelBottom;

    this.showCategory = data.showCategory;
    
    if(data.turn)
      this.turn = data.turn;

    this.closeModalSubscription = this.routeService.closeModals$.subscribe(closeModal => {
      if (closeModal) {
        this.dialogRef.close(false);
      }
    });
  }

  ngOnInit(): void {
    this.ngxLoaderService.start();
    if (this.showCategory) {
      this.loadCategoriesData();
    }
    this.personService.getJTurnPersons().subscribe({
      next: (res) => {
        console.log(res);
        this.ngxLoaderService.stop();

        this.listJefeTurno = res;
      },
      error: (err) => {
        this.ngxLoaderService.stop();
        this.notificacionService.notificationError(
          'Lo sentimos, ocurrió un error al cargar la persona'
        );
      },
    });
  }

  ngOnDestroy(): void {
    if (this.closeModalSubscription){
      this.closeModalSubscription.unsubscribe();
    }
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close({result: true, idJefeTurno: this.idJefeTurno, idcategoria: this.idCategoria});
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close({result: false});
  }

  /**
   * Evento al seleccionar la categoria
   * @param event
   */
  onSelectCategory(event: any) {
    this.idCategoria = event;
    if(this.turn){
      let existCategory = false;
      switch(this.idCategoria){
        case 5:
          if (this.turn.operPpalNombreApel){
            existCategory = true;
          }
          break    
        case 1:
          if (this.turn.precioNombreApel){
            existCategory = true;
          }
          break 
        case 2:
          if (this.turn.ayudanteNombreApel){
            existCategory = true;
          }
          break
        case 3:
          if (this.turn.refuerzoNombreApel){
            existCategory = true; 
          }
          break
        case 4:
          if (this.turn.jefaTurnoNombreApel){
            existCategory = true;
          }
          break   
        default:
          existCategory = false;
      }
      if (!existCategory) {
        this.listUser = [];
        const dialogData = new ErrorDialogModel('Error', 'No hay un usuario con esta categoría registrado en el turno seleccionado');
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            maxWidth: '400px',
            data: dialogData,
          });
      }
    }
    if(this.idCategoria)
      this.loadUserByCategory();
  }

  loadUserByCategory(): void {
    
      this.ngxLoaderService.start();
      this.personService.getUsersPersons(this.idCategoria).subscribe({
        next: (res) => {
          this.listUser = res;
          this.ngxLoaderService.stop();
        },
        error: (err) => {
          this.ngxLoaderService.stop();
          let msg = 'Lo sentimos, ocurrió un error al obtener los usuarios';
          const dialogData = new ErrorDialogModel('Error', msg);
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            maxWidth: '400px',
            data: dialogData,
          });
        },
      });
    
  }

  loadCategoriesData(): void {
    if (localStorage.getItem('listCategoriesOficce') != null){
      const categories = localStorage.getItem('listCategoriesOficce');
      if (categories) {
        this.listCategories = JSON.parse(categories).listCategoriesOficce;
      }
    } else {
      this.ngxLoaderService.start();
      this.authService.getCategories().subscribe({
        next: (res) => {
          this.listCategories = res.result;
          this.listCategories = this.listCategories.filter(value => value.hOficina);

          localStorage.setItem(
            'listCategoriesOficce',
            JSON.stringify({
              listCategoriesOficce: this.listCategories,
            })
          );

          this.ngxLoaderService.stop();
        },
        error: (err) => {
          this.ngxLoaderService.stop();
          let msg = 'Lo sentimos, ocurrió un error al obtener las categorías';
          const dialogData = new ErrorDialogModel('Error', msg);
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            maxWidth: '400px',
            data: dialogData,
          });
        },
      });
    }
  }

}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ShiftDialogModel {

  constructor(public title: string, public message: string,
              public confirmBottom: boolean, public confirmLabelBottom: string,
              public dismissBottom: boolean, public dismissLabelBottom: string, public showCategory: boolean, public turn?: OpenTurnModel) {
  }
}
