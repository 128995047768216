import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Import library Ngx-Ui-Loader para el spinner
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  NgxUiLoaderRouterModule,
} from 'ngx-ui-loader';

/** Configuraciones del spinner */
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  /** Background spinner para el cambio de rutas etc */
  bgsColor: '#3f51b5',
  bgsOpacity: 0.6,
  bgsPosition: POSITION.bottomRight,
  bgsSize: 60,
  bgsType: SPINNER.ballSpinClockwise,
  blur: 5,
  /** Foreground spinner para las peticiones http */
  fgsColor: '#3f51b5',
  fgsPosition: POSITION.centerCenter, // 'center-center',
  fgsSize: 60,
  fgsType: SPINNER.ballSpinClockwise,
  gap: 16,
  masterLoaderId: 'master',
  overlayColor: 'rgba(255,255,255,0.2)',
  pbColor: '#ED3237',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: false,
  text: '',
  textColor: '#373739',
  textPosition: POSITION.centerCenter,
};

@NgModule({
  imports: [
    CommonModule,
    NgxUiLoaderRouterModule.forRoot({ showForeground: false }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
  ],
  exports: [NgxUiLoaderRouterModule, NgxUiLoaderModule],
})
export class LoadingSpinnerModule {}
