import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from "@angular/common/http";
import {ApiCodeMessage} from "../../../shared/consts/api-code-message.constant";
import {Observable, throwError} from "rxjs";
import {environment} from "../../../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {
  ClientEstimatedBalance, DaysWeek,
  DriverEstimatedBalance, PreciosOtraMonedaRequest, ServiceAssignDriverAutoEntity,
  ServiceCancelEntity,
  ServiceEntity, ServiceForPriceEntity,
  ServiceRequest,
  ServiceReSendRequest,
  ServiceStopEntity,
  ServiceTerminarLoteRequest
} from "../../../shared/models/service-model";
import {Clipboard} from "@angular/cdk/clipboard";
import {TipoServicio} from "../../../shared/consts/tipo-servicio";
import {NotificationService} from "../../../shared/services/notification.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {TipoMoneda} from "../../../shared/consts/tipo-moneda";
import {EstadoServicioId} from "../../../shared/consts/estado-servicio";
import {AuthService} from "../../../core/auth/auth.service";
import {AppConfigurationEntity} from "../../../shared/models/configuration-model";
import {CodTipoTrayectoria, ModoCalculoPrecio, TipoTrayectoria} from "../../../shared/consts/modo-calculo-precio";
import { PersonService } from '../../users/services/persons.service';
import { PersonEntity } from 'src/app/shared/models/person-model';
import { AdministrationService } from '../../administration/services/administration.service';
import { ConfigVariableEntity } from 'src/app/shared/models/admin-model';
import {TracesService} from "../../traces/services/traces.service";
import { format } from 'date-fns';
import { DatePipe } from '@angular/common';
import { TraceRouteRequest } from 'src/app/shared/models/google-model';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  user: number;

  traceUyD = 'Urgente y Disponibilidad';
  traceDC = 'Datos al Chofer';
  traceCC = 'Confirmar al Cliente';

  constructor(private _http: HttpClient, private notificacionService: NotificationService, private authService: AuthService, private tracesService: TracesService,
              private clipboard: Clipboard, protected ngxLoaderService: NgxUiLoaderService, private personService: PersonService, private administracionService: AdministrationService, public datepipe: DatePipe) {
                this.user = 0;
              }

  handleServiceError(error: HttpErrorResponse) {
    let mensajeError = undefined;
    if (error.error instanceof ErrorEvent) {
      console.error('Error:', error.error.message);
      mensajeError = `Error: ${error.error.message}`;
    } else {
      switch (error.status) {
        case 400:
          console.error(`Error del backend, código: ${error.status}, `);
          mensajeError = error.error.error;
          break;
        case 403:
          console.error(`Error del backend, código: ${error.status}, `);
          mensajeError = error.error;
          break;
        case 500:
          console.error(`Error del backend, código: ${error.status} `);
          break;
        case 503:
          console.error(`Error del backend, código: ${error.status} `);
          mensajeError = ApiCodeMessage.MSG_CODE_503;
          break;
        default:
          console.error(`Error: ${error} `);
          mensajeError = error;
          break;
      }
    }
    return throwError(mensajeError);
  }

  /** Obtener todos los servicios*/
  getAllServices(): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.serviceService, {headers})
      .pipe(
        map((data) => data.result),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener listado de servicios por su estado */
  getServices(serviceRequest: ServiceRequest, statusId: number, pageNumber: number, pageSize: number,
              sort: string, searchParam: string, esInmediato?: boolean, enCurso?: boolean, precio?: number ): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    serviceRequest.clienteNombre
      ? (queryParams = queryParams.append('clienteNombre', serviceRequest.clienteNombre))
      : null;
    serviceRequest.clienteTelefCelular
      ? (queryParams = queryParams.append('clienteTelefCelular', serviceRequest.clienteTelefCelular))
      : null;
    serviceRequest.choferNombre
      ? (queryParams = queryParams.append('choferNombre', serviceRequest.choferNombre))
      : null;
    serviceRequest.choferTelefCelular
      ? (queryParams = queryParams.append('choferTelefCelular', serviceRequest.choferTelefCelular))
      : null;
    serviceRequest.lugarOrigen
      ? (queryParams = queryParams.append('lugarOrigen', serviceRequest.lugarOrigen))
      : null;
      serviceRequest.lugarDestino
      ? (queryParams = queryParams.append('lugarDestino', serviceRequest.lugarDestino))
      : null;
    serviceRequest.tieneDeuda
      ? (queryParams = queryParams.append('tieneDeuda', serviceRequest.tieneDeuda))
      : null;
    serviceRequest.conFacturacion
      ? (queryParams = queryParams.append('conFacturacion', serviceRequest.conFacturacion))
      : null;
    serviceRequest.esInmediato !== undefined
      ? (queryParams = queryParams.append('esInmediato', serviceRequest.esInmediato))
      : null;
    serviceRequest.enCurso !== undefined
      ? (queryParams = queryParams.append('enCurso', serviceRequest.enCurso))
      : null;
    serviceRequest.precioUrgente !== undefined
      ? (queryParams = queryParams.append('precioUrgente', serviceRequest.precioUrgente))
      : null;
    serviceRequest.precio !== undefined
      ? (queryParams = queryParams.append('precio', serviceRequest.precio))
      : null;
    serviceRequest.precioInProcess !== undefined
      ? (queryParams = queryParams.append('precioInProcess', serviceRequest.precioInProcess))
      : null;
    serviceRequest.confirmCliente !== undefined
      ? (queryParams = queryParams.append('confirmCliente', serviceRequest.confirmCliente))
      : null;
    serviceRequest.fechaInicio
      ? (queryParams = queryParams.append('fechaInicio', serviceRequest.fechaInicio))
      : null;
    serviceRequest.fechaFin
      ? (queryParams = queryParams.append('fechaFin', serviceRequest.fechaFin))
      : null;
    statusId !== undefined
      ? (queryParams = queryParams.append('estadoId', statusId))
      : null;
    serviceRequest.modoPagoId !== undefined
      ? (queryParams = queryParams.append('modoPagoId', serviceRequest.modoPagoId))
      : null;
    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    sort !== undefined
      ? (queryParams = queryParams.append('sort', sort))
      : null;
    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceService, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener listado del historial de servicios por su estado */
  getServicesHistoric(serviceRequest: ServiceRequest, statusId: number, pageNumber: number, pageSize: number,
              sort: string, searchParam: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    serviceRequest.clienteNombre
      ? (queryParams = queryParams.append('clienteNombre', serviceRequest.clienteNombre))
      : null;
    serviceRequest.clienteTelefCelular
      ? (queryParams = queryParams.append('clienteTelefCelular', serviceRequest.clienteTelefCelular))
      : null;
    serviceRequest.choferNombre
      ? (queryParams = queryParams.append('choferNombre', serviceRequest.choferNombre))
      : null;
    serviceRequest.choferTelefCelular
      ? (queryParams = queryParams.append('choferTelefCelular', serviceRequest.choferTelefCelular))
      : null;
    serviceRequest.tieneDeuda
      ? (queryParams = queryParams.append('tieneDeuda', serviceRequest.tieneDeuda))
      : null;
    serviceRequest.modoPagoId !== undefined
      ? (queryParams = queryParams.append('modoPagoId', serviceRequest.modoPagoId))
      : null;
    serviceRequest.conFacturacion
      ? (queryParams = queryParams.append('conFacturacion', serviceRequest.conFacturacion))
      : null;
    serviceRequest.fechaInicio
      ? (queryParams = queryParams.append('fechaInicio', serviceRequest.fechaInicio))
      : null;
    serviceRequest.fechaFin
      ? (queryParams = queryParams.append('fechaFin', serviceRequest.fechaFin))
      : null;
    statusId !== undefined && statusId !== EstadoServicioId.history_id
      ? (queryParams = queryParams.append('estadoId', statusId))
      : null;
    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    sort !== undefined
      ? (queryParams = queryParams.append('sort', sort))
      : null;
    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceServiceHistoric, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener estadisticas de los servicios en rango de fecha */
  getServicesStatistics(statisticsRequest: ServiceRequest, pageNumber: number, pageSize: number,
              sort: string, searchParam: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    statisticsRequest.fechaInicio
      ? (queryParams = queryParams.append('fechaInicio', statisticsRequest.fechaInicio))
      : null;
    statisticsRequest.fechaFin
      ? (queryParams = queryParams.append('fechaFin', statisticsRequest.fechaFin))
      : null;
    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    sort !== undefined
      ? (queryParams = queryParams.append('sort', sort))
      : null;
    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceService + '/estadisticas', options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener contadores de los servicios en rango de fecha */
  getServicesCounts(statisticsRequest: ServiceRequest): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    statisticsRequest.fechaInicio
      ? (queryParams = queryParams.append('fechaInicio', statisticsRequest.fechaInicio))
      : null;
    statisticsRequest.fechaFin
      ? (queryParams = queryParams.append('fechaFin', statisticsRequest.fechaFin))
      : null;

    let user = JSON.parse(window.atob(JSON.parse(localStorage.getItem('currentUser')!).user));

    if(user.rolDescCorta === 'VISITA'){
      queryParams = queryParams.append('conFacturacion', true);
    } else {
      statisticsRequest.conFacturacion ? queryParams = queryParams.append('conFacturacion', statisticsRequest.conFacturacion)
      : null;
    }

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceCounts, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Llamada a endpoint que traza la ruta */
  traceRouteTravel(request: TraceRouteRequest): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.serviceGoogle + `/trace-route-travel`, request, {
        headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener servicio por id */
  getServiceById(serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.serviceService + `/${serviceId}`, {headers})
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /** Obtener servicio por id */
  getServiceByIdVale(serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.serviceService + `/${serviceId}` + `/vale`, {headers})
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  /**
   * Metodo para bloquear el servicio por su id
   * @param personId
   * @param autoId
   */
  markBlockedService(serviceId: number, bloked: boolean): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .patch<any>(environment.serviceService + `/${serviceId}` + `?bloqueado=${bloked}` , {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }

  /**
   * Metodo para saber si el servicio esta bloqueado por su id
   * @param personId
   * @param autoId
   */
  getBlockedService(serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.serviceService + `/${serviceId}` + `/bloqueado` , {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }

  /** Generar identificador del viaje */
  generateServiceCode(): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.serviceGenerateCod, {headers})
      .pipe(
        map((data) => data.result),
        catchError(this.handleServiceError)
      );
  }

  /** Adicionar servicio */
  addService(serviceEntity: ServiceEntity): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.serviceService, serviceEntity, {
        headers,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  /** Editar operacion */
  editService(serviceEntity: ServiceEntity, serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .put<any>(environment.serviceService + `/${serviceId}`, serviceEntity, {
        headers,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  /** Eliminar un servicio */
  deleteService(serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .delete<any>(environment.serviceService + `/${serviceId}/`, {headers})
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }

  //Para generar el codigo de la factura. Para el reporte factura de servicios
  getCodFacturaServicios(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    return this._http
      .get<any>(environment.serviceService + `/generar_cod_factura`, { headers })
      .pipe(
        map((data) => data.result),
        catchError(this.handleServiceError)
      );
  }

  /**************** CANCELACIONES ****************/
  /** Obtener las cancelaciones de un servicio por su id */
  getServiceCancel(serviceId: number, pageNumber?: number, pageSize?: number,
                   sort?: string, searchParam?: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    pageNumber !== undefined
      ? (queryParams = queryParams.append('pageNumber', pageNumber))
      : null;
    pageSize !== undefined
      ? (queryParams = queryParams.append('pageSize', pageSize))
      : null;
    sort !== undefined
      ? (queryParams = queryParams.append('sort', sort))
      : null;
    searchParam
      ? (queryParams = queryParams.append('searchParam', searchParam))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http
      .get<any>(environment.serviceService + `/${serviceId}` + '/cancelaciones', options)
      .pipe(
        map((data) => data.result),
        catchError(this.handleServiceError)
      );
  }

  /** Adicionar cancelacion de un servicio */
  addServiceCancel(serviceCancelEntity: ServiceCancelEntity, serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.serviceService + `/${serviceId}` + '/cancelaciones', serviceCancelEntity, {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }

  /** Editar Cancelacion de un servicio */
  editServiceCancel(serviceCancelEntity: ServiceCancelEntity, serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .put<any>(environment.serviceService + `/${serviceId}` + '/cancelaciones', serviceCancelEntity, {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }
  /**************** END CANCELACIONES ****************/

  /**************** CLONAR ****************/
  /** Clonar un servicio */
  cloneService(serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.serviceService + `/${serviceId}` + '/clonar', {headers})
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }
  /**************** END CLONAR ****************/

  /**************** CONFIRMAR ****************/
  /** Confirmar un servicio */
  confirmService(serviceId: number, mensaje: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    mensaje
      ? (queryParams = queryParams.append('?mensaje', mensaje))
      : null;

    return this._http
      .patch<any>(environment.serviceService + `/${serviceId}` + `/confirmarCliente` + queryParams , {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }

  /** Enviar confirmacion de un servicio */
  sendConfirmService(serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .patch<any>(environment.serviceService + `/${serviceId}` + `/enviarConfirmCliente` , {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }
  /**************** END CONFIRMAR ****************/

  /**************** ENVIAR ****************/
  sendService(serviceEntity: ServiceEntity): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.serviceService + '/enviar', serviceEntity, {
        headers,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  sendExistService(serviceEntity: ServiceEntity, serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .put<any>(environment.serviceService+ `/${serviceId}` + '/enviar', serviceEntity, {
        headers,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  sendEnRecepcion(serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .patch<any>(environment.serviceService+ `/${serviceId}` + '/enRecepcion', {
        headers,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  sendEnEspera(serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .patch<any>(environment.serviceService+ `/${serviceId}` + '/enEspera', {
        headers,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }
  /**************** END ENVIAR ****************/

  /**************** ASIGNAR CHOFER Y AUTO ****************/
  /**
   * Metodo para asignar un chofer con su auto al viaje por su id
   * @param serviceId
   */
  assignDriverAuto(serviceId: number, serviceAssignDriverAutoEntity: ServiceAssignDriverAutoEntity, tiempoEstimChofer: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    tiempoEstimChofer
      ? (queryParams = queryParams.append('?tiempoEstimChofer', tiempoEstimChofer))
      : null;

    return this._http
      .patch<any>(environment.serviceService + `/${serviceId}` + `/asignarChoferAuto` + queryParams, serviceAssignDriverAutoEntity , {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }
  /************** END ASIGNAR CHOFER Y AUTO **************/

  /**************** REENVIAR ****************/

  /** Reenviar un servicio */
  reSendService(serviceReSendRequest: ServiceReSendRequest, serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    serviceReSendRequest.precio
      ? (queryParams = queryParams.append('?precio', serviceReSendRequest.precio))
      : null;

    return this._http
      .put<any>(environment.serviceService + `/${serviceId}` + '/reenviar' + queryParams, {headers})
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }
  /**************** END REENVIAR ****************/

  /**************** PARADAS ****************/
  /** Obtener las paradas de un servicio por su id */
  getServiceStops(serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .get<any>(environment.serviceService + `/${serviceId}` + '/paradas', {headers})
      .pipe(
        map((data) => data.result),
        catchError(this.handleServiceError)
      );
  }

  /** Adicionar paradas a un servicio */
  addServiceStop(serviceStopEntity: ServiceStopEntity, serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.serviceService + `/${serviceId}` + '/paradas', serviceStopEntity, {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }

  /** Editar paradas de un servicio */
  editServiceStop(serviceStopEntity: ServiceStopEntity, serviceId: number, paradaId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .put<any>(environment.serviceService + `/${serviceId}` + '/paradas' + `/${paradaId}`, serviceStopEntity, {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }

  /** Eliminar las paradas de un servicio */
  deleteServiceStop(serviceId: number, paradaId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .delete<any>(environment.serviceService + `/${serviceId}` + '/paradas' + `/${paradaId}`, {headers})
      .pipe(
        map((data) => data),
        catchError(this.handleServiceError)
      );
  }
  /**************** END PARADAS ****************/

  /****************** PRECIO ******************/
  calculateServicePrice(serviceEntity: ServiceForPriceEntity): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.serviceCalculatePrice, serviceEntity, {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }
  /**************** END PRECIO ****************/

  /****************** PRECIO AYUDA OPERADORA ******************/
  calcularPrecioAyudaOperadora(totalKm: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });
    return this._http
      .get<any>(environment.serviceService + '/calcular_precio_ayuda_operadora' + `?totalKm=${totalKm}`, {headers})
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }
  /**************** END PRECIO AYUDA OPERADORA ****************/

  /****************** TERMINAR ******************/
  finishService(serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .put<any>(environment.serviceService + `/${serviceId}` + '/completar', {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }

  finishServiceLote(serviceLote: ServiceTerminarLoteRequest): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .put<any>(environment.serviceService + '/completar_lote', serviceLote, {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }
  /**************** END TERMINAR ****************/

  /****************** LANZAR ******************/
  launchService(serviceId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .put<any>(environment.serviceService + `/${serviceId}` + '/lanzar', {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }
  /**************** END LANZAR ****************/

  /****************** SALDO ESTIMADO CHOFER ******************/
  calculateDriverEstimatedBalance(driverEstimatedBalance: DriverEstimatedBalance): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.serviceService + '/estimar_saldo_chofer', driverEstimatedBalance, {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }
  /**************** END SALDO ESTIMADO CHOFER ****************/

  /****************** SALDO ESTIMADO CHOFER V2 ******************/
  calculateDriverEstimatedBalanceV2(driverEstimatedBalance: DriverEstimatedBalance): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.serviceService + '/estimar_saldo_chofer_v2', driverEstimatedBalance, {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }
  /**************** END SALDO ESTIMADO CHOFER V2 ****************/

  /**************** PROMEDIO DE SERVICIOS ***********************/
  getTravelPriceAverage(fechaInicio: string, fechaFin: string, byMonth: boolean): Observable<any> {

    let endpoint = '/promedio_precios_viajes_rango_dia';

    if(byMonth){
      endpoint = '/promedio_precios_viajes_rango_mes';
    }

    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    fechaInicio
      ? (queryParams = queryParams.append('fechaInicio', fechaInicio))
      : null;
    fechaFin
      ? (queryParams = queryParams.append('fechaFin', fechaFin))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceService + endpoint, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  getAveragePriceKmTravel(fechaInicio: string, fechaFin: string, byMonth: boolean): Observable<any> {

    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    fechaInicio
      ? (queryParams = queryParams.append('fechaInicio', fechaInicio))
      : null;
    fechaFin
      ? (queryParams = queryParams.append('fechaFin', fechaFin))
      : null;
    byMonth ? (queryParams = queryParams.append('month', byMonth)) : false;

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceService + '/promedio_precio_km', options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  getCountTravelRegisteredByRange(fechaInicio: string, fechaFin: string, byMonth: boolean): Observable<any> {

    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    fechaInicio
      ? (queryParams = queryParams.append('fechaInicio', fechaInicio))
      : null;
    fechaFin
      ? (queryParams = queryParams.append('fechaFin', fechaFin))
      : null;
    byMonth ? (queryParams = queryParams.append('month', byMonth)) : false;

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceService + '/cant_viajes_medio_transporte', options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }
  /**************** END PROMEDIO DE SERVICIOS ***********************/

   getAgencySales(fechaInicio: string, fechaFin: string, byMonth: boolean): Observable<any> {

    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    fechaInicio
      ? (queryParams = queryParams.append('fechaInicio', fechaInicio))
      : null;
    fechaFin
      ? (queryParams = queryParams.append('fechaFin', fechaFin))
      : null;
    byMonth ? (queryParams = queryParams.append('month', byMonth)) : false;

    const options = {
      headers: headers,
      params: queryParams,
    };
    return this._http.get<any>(environment.serviceService + '/ventas_agencia', options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /****************** SALDO ESTIMADO CLIENTE ******************/
  calculateClientEstimatedBalance(clientEstimatedBalance: ClientEstimatedBalance): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .post<any>(environment.serviceService + '/estimar_saldo_cliente', clientEstimatedBalance, {
        headers,
      })
      .pipe(
        map((res) => res.result),
        catchError(this.handleServiceError)
      );
  }
  /**************** END SALDO ESTIMADO CLIENTE ****************/

  /****************** CALCULO DEL PRECIO EN MLC ******************/
  getMLCServicePrice(servicePrice: number, tipoMoneda:string, modoPagoId: number): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    servicePrice !== undefined
      ? (queryParams = queryParams.append('precioViaje', servicePrice))
      : null;
    tipoMoneda !== undefined
      ? (queryParams = queryParams.append('tipoMoneda', tipoMoneda))
      : null;
    queryParams = queryParams.append('modoPagoId', modoPagoId);

    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http
      .get<any>(environment.serviceService + `/calcular_precio_mlc`, options)
      .pipe(
        map((data) => data.result),
        catchError(this.handleServiceError)
      );
  }

  /****************** CALCULO DEL PRECIO EN MONEDA DISTINTA A CUP ******************/
  getMonedaExtranjeraServicePrice(servicePrice: number, tipoMoneda:string, accionMoneda:string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    let queryParams = new HttpParams();

    servicePrice !== undefined
      ? (queryParams = queryParams.append('precioViaje', servicePrice))
      : null;
    tipoMoneda !== undefined
      ? (queryParams = queryParams.append('tipoMoneda', tipoMoneda))
      : null;
    accionMoneda !== undefined
      ? (queryParams = queryParams.append('accionMoneda', accionMoneda))
      : null;

    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http
      .get<any>(environment.serviceService + `/calcular_precio_moneda`, options)
      .pipe(
        map((data) => data.result),
        catchError(this.handleServiceError)
      );
  }

  /**************** END CALCULO DEL PRECIO EN MONEDA DISTINTA A CUP ****************/

  /**
   * Listado de colunmas para la tabla de servicios segun el estado y la etapa
   * @param serviceStatusCode
   * @param serviceStageCode
   */
  getDataColumnsTableServices(serviceStatusCode: string, serviceStageCode: string) {
    let dataColumn: string[] = [];

    switch (serviceStatusCode) {
      case 'in_process':
        switch (serviceStageCode) {
          case 'in_reception':
            dataColumn = [
              'bloqueado',
              'codViaje',
              //'fechaRecep',
              'tiempoRecepcion',
              'urgenteDisponib',
              'fechaInicio',
              'viaComunicacionNombre',
              'cantPasajeros',
              'clienteNombreApel',
              'clienteTelefonoMovil',
              'lugarOrigen',
              'lugarDestino',
              'precioEstimChofer',
              'monedaPago',
            ];
            break;
          case 'searching':
            dataColumn = [
              'bloqueado',
              'codViaje',
              'cantReenvios',
              //'fechaRecep',
              'tiempoRecepcion',
              'urgenteDisponib',
              'fechaInicio',
              'viaComunicacionNombre',
              'cantPasajeros',
              'clienteNombreApel',
              'clienteTelefonoMovil',
              'lugarOrigen',
              'lugarDestino',
              'precioEstimChofer',
              'monedaPago',
              'modoPago',
            ];
            break;
          case 'client_confirm':
            dataColumn = [
              'bloqueado',
              'cfm',
              'codViaje',
              'fechaInicio',
              'viaComunicacionNombre',
              'clienteNombreApel',
              'clienteTelefonoMovil',
              'choferNombreApel',
              'marcaAuto',
              'colorAuto',
              'precioEstimChofer',
              'monedaPago',
            ];
            break;
          case 'price':
            dataColumn = [
              'bloqueado',
              'codViaje',
              'urgenteDisponib',
              'fechaInicio',
              'viaComunicacionNombre',
              'cantPasajeros',
              'clienteNombreApel',
              'clienteTelefonoMovil',
              'choferNombreApel',
              'lugarOrigen',
              'lugarDestino',
              'precioEstimChofer',
              'monedaPago',
            ];
            break;
          case 'p_urgente':
            dataColumn = [
              'bloqueado',
              'codViaje',
              'urgenteDisponib',
              'fechaInicio',
              'viaComunicacionNombre',
              'cantPasajeros',
              'clienteNombreApel',
              'clienteTelefonoMovil',
              'lugarOrigen',
              'lugarDestino',
              'precioEstimChofer',
              'monedaPago',
            ];
            break;
          case 'process':
            dataColumn = [
              //'select',
              'bloqueado',
              'codViaje',
              'fechaInicio',
              'clienteNombreApel',
              'clienteTelefonoMovil',
              'choferNombreApel',
              'estado',
              'lugarOrigen',
              'lugarDestino',
              'precioEstimChofer',
              'monedaPago',
            ];
            break;
        }
        break;
      case 'on_hold':
        dataColumn = [
          'bloqueado',
          'codViaje',
          'fechaRecep',
          'urgenteDisponib',
          'fechaInicio',
          'viaComunicacionNombre',
          'cantPasajeros',
          'clienteNombreApel',
          'lugarOrigen',
          'lugarDestino',
          'precioEstimChofer',
          'monedaPago',
        ];
        break;
      case 'in_reserve':
        dataColumn = [
          'bloqueado',
          'codViaje',
          'fechaRecep',
          'fechaInicio',
          'clienteNombreApel',
          'choferNombreApel',
          'estado',
          'lugarOrigen',
          'lugarDestino',
          'precioEstimChofer',
          'monedaPago',
        ];
        break;
      case 'cancelled':
        dataColumn = [
          'bloqueado',
          'codViaje',
          'fechaRecep',
          'fechaInicio',
          'viaComunicacionNombre',
          'usuarioCancelo',
          'causaCancelo',
          //'motivoCancelo',
          'clienteNombreApel',
          //'choferNombreApel',
          'lugarOrigen',
          'lugarDestino',
          'precioEstimChofer',
          'monedaPago',
        ];
        break;
      case 'finished':
        dataColumn = [
          'select',
          'bloqueado',
          'codViaje',
          'fechaRecep',
          'fechaInicio',
          'fechaFin',
          'clienteNombreApel',
          'clienteTelefonoMovil',
          'choferNombreApel',
          'lugarOrigen',
          'lugarDestino',
          'precioEstimChofer',
          'monedaPago',
          'terminadoAppMovil'
        ];
        break;
      case 'history':
        dataColumn = [
          'bloqueado',
          'codViaje',
          'fechaRecep',
          'fechaInicio',
          'clienteNombreApel',
          'choferNombreApel',
          //'estado',
          'procesoViaje',
          'precioEstimChofer',
          'monedaPago',
          'modoPago',
          'estadoPago',
          'categoriaServicio',
          'terminadoAppMovil'
        ];
        break;
    }

    return dataColumn;
  }

  getDataColumnsTableCancelServices() {
    let dataColumn: string[] = [];

    dataColumn = [
      'fechaCreacion',
      'personaCancela',
      'causaCancel',
      'observacion',
    ];

    return dataColumn;
  }

  /**************** CODIFICADORES ****************/

  /** Obtener Codificador Via de comunicacion */
  getCommunicationWay(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    return this._http.get<any>(environment.serviceCommunicationWay, {headers}).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener los tipos de servicios Comisiones */
  getConfigComision(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    return this._http.get<any>(environment.serviceConfigComision + '?activa=true', {headers}).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener las causas de la cancelacion */
  getCauseCancel(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    return this._http.get<any>(environment.serviceCauseCancel + '?activa=true&mostrarDesp=true', {headers}).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener el tipo de viaje */
  // getTravelType(): Observable<any> {
  //   const headers = new HttpHeaders({
  //     accept: '*/*',
  //   });
  //
  //   return this._http.get<any>(environment.serviceTravelType, {headers}).pipe(
  //     map((data) => data),
  //     catchError(this.handleServiceError)
  //   );
  // }

  /** Obtener el estado de viaje */
   getTravelState(): Observable<any> {
     const headers = new HttpHeaders({
       accept: '*/*',
    });

     return this._http.get<any>(environment.serviceTravelState, {headers}).pipe(
     map((data) => data),
      catchError(this.handleServiceError)
     );
   }

  /** Obtener el turno activo */
  getTurnoActivo(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const queryParams = new HttpParams().append('abierto', true);
    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.serviceTurnoActivo, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener Codificador tipo de moneda */
  getCurrencyType() {
    return [
      {
        id: 1,
        nombre: TipoMoneda.CUP,
        descCorta: TipoMoneda.CUP,
        descripcion: TipoMoneda.CUP,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 2,
        nombre: TipoMoneda.USD,
        descCorta: TipoMoneda.USD,
        descripcion: TipoMoneda.USD,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 3,
        nombre: TipoMoneda.EUR,
        descCorta: TipoMoneda.EUR,
        descripcion: TipoMoneda.EUR,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 4,
        nombre: TipoMoneda.MLC,
        descCorta: TipoMoneda.MLC,
        descripcion: TipoMoneda.MLC,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 5,
        nombre: TipoMoneda.OTHER,
        descCorta: TipoMoneda.Otra,
        descripcion: TipoMoneda.OTHER,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
    ];
  }

  /** Obtener Codificador tipo de moneda para el precio*/
  getCurrencyTypeForPrice() {
    return [
      {
        id: 1,
        nombre: TipoMoneda.CUP,
        descCorta: TipoMoneda.CUP,
        descripcion: TipoMoneda.CUP,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 2,
        nombre: TipoMoneda.USD,
        descCorta: TipoMoneda.USD,
        descripcion: TipoMoneda.USD,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 3,
        nombre: TipoMoneda.EUR,
        descCorta: TipoMoneda.EUR,
        descripcion: TipoMoneda.EUR,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
    ];
  }

  /** Obtener Codificador modo del calculo de precio */
  getCalculatePriceMode() {
    return [
      {
        id: 1,
        nombre: ModoCalculoPrecio.COMUN,
        descCorta: ModoCalculoPrecio.Comun,
        descripcion: ModoCalculoPrecio.COMUN,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 2,
        nombre: ModoCalculoPrecio.AEROPUERTO,
        descCorta: ModoCalculoPrecio.Aeropuerto,
        descripcion: ModoCalculoPrecio.AEROPUERTO,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 3,
        nombre: ModoCalculoPrecio.PLAYA,
        descCorta: ModoCalculoPrecio.Playa,
        descripcion: ModoCalculoPrecio.PLAYA,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 4,
        nombre: ModoCalculoPrecio.PROVINCIA,
        descCorta: ModoCalculoPrecio.Provincia,
        descripcion: ModoCalculoPrecio.PROVINCIA,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
    ];
  }

  /** Obtener Codificador modo del calculo de precio para usar en config horario */
  getCalculatePriceModeForConfigHorario() {
    return [
      {
        id: 1,
        nombre: ModoCalculoPrecio.COMUN,
        descCorta: ModoCalculoPrecio.Comun,
        descripcion: ModoCalculoPrecio.COMUN,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 2,
        nombre: ModoCalculoPrecio.AEROPUERTO,
        descCorta: ModoCalculoPrecio.Aeropuerto,
        descripcion: ModoCalculoPrecio.AEROPUERTO,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 3,
        nombre: ModoCalculoPrecio.PLAYA,
        descCorta: ModoCalculoPrecio.Playa,
        descripcion: ModoCalculoPrecio.PLAYA,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 4,
        nombre: 'Descuento',
        descCorta: 'DESCUENTO',
        descripcion: 'Descuento',
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
    ];
  }

  /** Obtener Codificador tipo de trayectoria */
  getTrajectoryType() {
    return [
      {
        id: 1,
        nombre: TipoTrayectoria.IDA,
        descCorta: CodTipoTrayectoria.Ida,
        descripcion: TipoTrayectoria.IDA,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 2,
        nombre: TipoTrayectoria.VUELTA,
        descCorta: CodTipoTrayectoria.Recogida,
        descripcion: TipoTrayectoria.VUELTA,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 3,
        nombre: TipoTrayectoria.IDA_Y_VUELTA,
        descCorta: CodTipoTrayectoria.Ida_y_vuelta,
        descripcion: TipoTrayectoria.IDA_Y_VUELTA,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
    ];
  }

  /** Obtener los tipos de servicios del chofer */
  getFactorServices(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    return this._http.get<any>(environment.serviceAllServices + '?mostrarDespachador=true', {headers}).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener todos los tipos de servicios del chofer */
  getAllFactorServices(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    return this._http.get<any>(environment.serviceAllServices, {headers}).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }

  /** Obtener Codificador tipo de operacion */
  getPaymentMode(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });

    return this._http.get<any>(environment.servicePaymentMode, {headers}).pipe(
      map((data) => data),
      catchError(this.handleServiceError)
    );
  }

  /**Multiplicar servicios programados*/
  multiplyScheduledService(fechaInicio: string, fechaFin: string, idViaje: number, daysWeek: DaysWeek): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    const options = {
      headers: headers
    };
    return this._http.post<any>(environment.serviceService + `/${idViaje}` + '/clonar_viaje_programado' + '?fechaInicio=' +`${fechaInicio}` + '&fechaFin=' +`${fechaFin}`, daysWeek, options).pipe(
      map((data) => data.result),
      catchError(this.handleServiceError)
    );
  }

  /**************** END CODIFICADORES ****************/

  /***********************COPYS************************/
  /**
   * Servicio para copiar los viajes al clipboard
   * @param copyType
   */
  copyToClipboard (copyType: string, copyAction: string, serviceSelected: ServiceEntity, telefonoCliente?: string,
                   telefonoCasaCliente?: string, telefonoWhatsappCliente?: string, serviceStopEntity?: ServiceStopEntity[],
                   tipoComision?: string, selectedClientPerson?: PersonEntity | undefined, selectedChoferPerson?: PersonEntity | undefined) {
    /** Primero busco las paradas del viaje */
    this.ngxLoaderService.start();
    this.user = JSON.parse(window.atob(JSON.parse(localStorage.getItem('currentUser')!).user)).id;
    let variableConfigList: ConfigVariableEntity[] = [];

    if (serviceSelected.id && !serviceStopEntity){

      this.administracionService.getConfigVariableByNombre('VISUALIZAR_FONDO_CHOFER').subscribe({
        next: (result) => {
          variableConfigList = result;

          /** construyo el string del Copy con las paradas y lo copio al portapapeles*/
          if (copyAction === TipoServicio.U_D.toString()) {
            this.buildUyDCopy(copyType, serviceSelected, serviceSelected.paradas);
          } else if (copyAction === TipoServicio.C_C.toString()) {
            this.buildCCCopy(copyType, serviceSelected, serviceSelected.paradas, selectedClientPerson);
          } else if (copyAction === TipoServicio.D_C.toString()) {
            this.buildDCCopy(copyType, serviceSelected, serviceSelected.paradas,
              telefonoCliente, telefonoCasaCliente, telefonoWhatsappCliente, tipoComision, selectedChoferPerson, variableConfigList, selectedClientPerson);
          } else if (copyAction === TipoServicio.I_C.toString()) {
            this.buildICCopy(copyType, serviceSelected, serviceSelected.paradas, selectedClientPerson);
          }

        },
        error: (err) => {
          if (copyAction === TipoServicio.U_D.toString()) {
            this.buildUyDCopy(copyType, serviceSelected, serviceSelected.paradas);
          } else if (copyAction === TipoServicio.C_C.toString()) {
            this.buildCCCopy(copyType, serviceSelected, serviceSelected.paradas, selectedClientPerson);
          } else if (copyAction === TipoServicio.D_C.toString()) {
            this.buildDCCopy(copyType, serviceSelected, serviceSelected.paradas,
              telefonoCliente, telefonoCasaCliente, telefonoWhatsappCliente, tipoComision, selectedChoferPerson, variableConfigList, selectedClientPerson);
          } else if (copyAction === TipoServicio.I_C.toString()) {
            this.buildICCopy(copyType, serviceSelected, serviceSelected.paradas, selectedClientPerson);
          }
        },
      });

    } else {

      if (serviceStopEntity!.length > 0){

        this.administracionService.getConfigVariableByNombre('VISUALIZAR_FONDO_CHOFER').subscribe({
          next: (result) => {
            variableConfigList = result;

            /** construyo el string del Copy con las paradas y lo copio al portapapeles*/
            if (copyAction === TipoServicio.U_D.toString()) {
              this.buildUyDCopy(copyType, serviceSelected, serviceStopEntity);
            } else if (copyAction === TipoServicio.C_C.toString()) {
              this.buildCCCopy(copyType, serviceSelected, serviceStopEntity, selectedClientPerson, selectedChoferPerson);
            } else if (copyAction === TipoServicio.D_C.toString()) {
              this.buildDCCopy(copyType, serviceSelected, serviceStopEntity,
                telefonoCliente, telefonoCasaCliente, telefonoWhatsappCliente, tipoComision, selectedChoferPerson, variableConfigList, selectedClientPerson);
            } else if (copyAction === TipoServicio.I_C.toString()) {
              this.buildICCopy(copyType, serviceSelected, serviceStopEntity, selectedClientPerson);
            }
          },
          error: (err) => {
            if (copyAction === TipoServicio.U_D.toString()) {
              this.buildUyDCopy(copyType, serviceSelected, []);
            } else if (copyAction === TipoServicio.C_C.toString()) {
              this.buildCCCopy(copyType, serviceSelected, [], selectedClientPerson, selectedChoferPerson);
            } else if (copyAction === TipoServicio.D_C.toString()) {
              this.buildDCCopy(copyType, serviceSelected, [],
                telefonoCliente, telefonoCasaCliente, telefonoWhatsappCliente, tipoComision, selectedChoferPerson, variableConfigList, selectedClientPerson);
            } else if (copyAction === TipoServicio.I_C.toString()) {
              this.buildICCopy(copyType, serviceSelected, [], selectedClientPerson);
            }
          },
        });

      } else {

        this.administracionService.getConfigVariableByNombre('VISUALIZAR_FONDO_CHOFER').subscribe({
          next: (result) => {
            variableConfigList = result;
            /** construyo el string del Copy sin las paradas y lo copio al portapapeles*/
            if (copyAction === TipoServicio.U_D.toString()) {
              this.buildUyDCopy(copyType, serviceSelected, []);
            } else if (copyAction === TipoServicio.C_C.toString()) {
              this.buildCCCopy(copyType, serviceSelected, [], selectedClientPerson, selectedChoferPerson);
            } else if (copyAction === TipoServicio.D_C.toString()) {
              this.buildDCCopy(copyType, serviceSelected, [],
                telefonoCliente, telefonoCasaCliente, telefonoWhatsappCliente, tipoComision, selectedChoferPerson, variableConfigList, selectedClientPerson);
            } else if (copyAction === TipoServicio.I_C.toString()) {
              this.buildICCopy(copyType, serviceSelected, [], selectedClientPerson);
            }

          },
          error: (err) => {
            if (copyAction === TipoServicio.U_D.toString()) {
              this.buildUyDCopy(copyType, serviceSelected, []);
            } else if (copyAction === TipoServicio.C_C.toString()) {
              this.buildCCCopy(copyType, serviceSelected, [], selectedClientPerson, selectedChoferPerson);
            } else if (copyAction === TipoServicio.D_C.toString()) {
              this.buildDCCopy(copyType, serviceSelected, [],
                telefonoCliente, telefonoCasaCliente, telefonoWhatsappCliente, tipoComision, selectedChoferPerson, variableConfigList, selectedClientPerson);
            } else if (copyAction === TipoServicio.I_C.toString()) {
              this.buildICCopy(copyType, serviceSelected, [], selectedClientPerson);
            }
          },
        });

      }

    }

    this.ngxLoaderService.stop();
  }

  priceToClipboard(copyAction: string, serviceSelected: ServiceEntity, serviceStopEntity: ServiceStopEntity[], selectedClientPerson: PersonEntity | undefined){
    this.user = JSON.parse(window.atob(JSON.parse(localStorage.getItem('currentUser')!).user)).id;
    if(serviceSelected.modoCalcPrecio === 'AEROPUERTO')
      this.buildCopyAirport(copyAction, serviceSelected);
    else if(serviceSelected.modoCalcPrecio === 'PLAYA')
      this.buildCopyBeach(copyAction, serviceSelected);
    else
      this.buildPriceCopy(copyAction, serviceSelected, serviceStopEntity, selectedClientPerson);    
  }

  priceTimeToClipboard(serviceSelected: ServiceEntity, serviceStopEntity: ServiceStopEntity[], selectedClientPerson: PersonEntity | undefined){
    this.user = JSON.parse(window.atob(JSON.parse(localStorage.getItem('currentUser')!).user)).id;
    this.buildPriceTimeCopy(serviceSelected, serviceStopEntity, selectedClientPerson);
  }

  copyDataToClipboard(data: string){
    this.clipboard.copy(data);
    /*this.notificacionService.notificationSuccess(
      'Copiado al portapapeles.'
    );*/
  }

  isTransferExternPaymentMode(modoPago: string):boolean{
    let result = false;
    switch (modoPago) {
      case 'ZELLE':
        result = true;
        break;
      case 'PAYPAL':
        result = true;
        break;
      case 'BIZUM':
        result = true;
        break;
      case 'WISE':
        result = true;
        break;
      case 'TROPIPAY':
        result = true;
        break;
      case 'VISA':
        result = true;
        break;
      case 'MASTERCARD':
        result = true;
        break;
      case 'EXTERIOR':
        result = true;
        break;
      default:
        result = false;
        break;
    }
    return result;
  }

  isTransferToAgencyPaymentMode(modoPago: string):boolean{
    let result = false;
    switch (modoPago) {
      case 'TRANF_CHOFER':
        result = false;
        break;
      case 'TRANSFERENCIA CUP':
        result = false;
        break;
      case 'TRANSFERENCIA CUP CHOFER':
        result = false;
        break;
      case 'TRANF_MLC_CHOFER':
        result = false;
        break;
      case 'TRANSFERENCIA MLC CHOFER':
        result = false;
        break;
      case 'EFECTIVO':
        result = false;
        break;
      case 'TRANF_AGENCIA':
        result = true;
        break;
      case 'TRANSFERENCIA MLC':
        result = true;
        break;
      case 'FONDO':
        result = false;
        break;
      case 'CHEQUE':
        result = false;
        break;
      case 'ZELLE':
        result = true;
        break;
      case 'PAYPAL':
        result = true;
        break;
      case 'BIZUM':
        result = true;
        break;
      case 'WISE':
        result = true;
        break;
      case 'TROPIPAY':
        result = true;
        break;
      case 'VISA':
        result = true;
        break;
      case 'MASTERCARD':
        result = true;
        break;
      case 'TRANF_CUP_AGENCIA':
        result = false;
        break;
      case 'TRANSFERENCIA CUP AGENCIA':
        result = false;
        break;
      case 'EXTERIOR':
        result = true;
        break;
      default:
        result = false;
        break;
    }
    return result;
  }

  buildUyDCopy(copyType: string, serviceSelected: ServiceEntity, serviceStopEntity?: ServiceStopEntity[]) {
    let UyDCopy: string = '';

    let choferLicenciaOperativa = false;

    let esAeropuerto = false;

    let factorMoto = false;

    serviceSelected.cantPasajeros = serviceSelected.cantPasajeros ? serviceSelected.cantPasajeros : 0;
    serviceSelected.lugarOrigen = serviceSelected.lugarOrigen ? serviceSelected.lugarOrigen : '-';
    serviceSelected.lugarDestino = serviceSelected.lugarDestino ? serviceSelected.lugarDestino : '-';
    serviceSelected.precioEstimChofer = serviceSelected.precioEstimChofer ? serviceSelected.precioEstimChofer : 0;

    serviceSelected.importeComisionAgencia = serviceSelected.importeComisionAgencia ? serviceSelected.importeComisionAgencia : 0;
    serviceSelected.importeComisionCliente = serviceSelected.importeComisionCliente ? serviceSelected.importeComisionCliente : 0;

    let valorComision: any | undefined;
    let tipoComision = '';

	  if(serviceSelected.comision && serviceSelected.comision.descCorta) {
      tipoComision = serviceSelected.comision.descCorta;
    } else if (serviceSelected.tipoComision)
      tipoComision = serviceSelected.tipoComision;

    let tieneFactores = false;

    if (serviceSelected.factoresViaje?.length > 0) {
      tieneFactores = true;
      let factorMotor = serviceSelected.factoresViaje.filter(factor => factor.esFactorMoto);
      if (factorMotor.length > 0) {
        factorMoto = true;
      }
      let factorExtranjero = serviceSelected.factoresViaje.filter((factor: { nombre: string; }) => factor.nombre === 'Extranjero');
      if (factorExtranjero.length > 0) {
        choferLicenciaOperativa = true;
      }
      let factorNoEstandar = serviceSelected.factoresViaje.filter((factor: { nombre: string; }) => factor.nombre !== 'Estandar');
      if (factorNoEstandar.length === 0) {
        tieneFactores = false;
      }
    }

    if (factorMoto) {
      UyDCopy += "*------ ReneMoto ------*" + "\n\n";
    }

    if(serviceSelected && serviceSelected.cantPasajeros >= 5 && serviceSelected.cantPasajeros <= 8){
      UyDCopy += "*ALMENDRON*" + "\n\n";
    }

    const fecha = "*Fecha:* ";
    const fechaHoy = "*Fecha: (Hoy) Hora:* ";
    const hora = " *Hora:* ";

    const fechaInicio = new Date(serviceSelected.fechaInicio);
    const fechaInicioFormateada = format(fechaInicio, 'dd/MM/yyyy');
    const horaInicioFormateada = format(fechaInicio, 'HH:mm');

    switch (copyType) {
      case TipoServicio.INMEDIATE.toString():
        UyDCopy += "*INMEDIATO*" + "\n\n";
        //UyDCopy += fechaHoy + "\n\n";
        break;
      case TipoServicio.RESERVE.toString():
        UyDCopy += "*RESERVA*" + "\n\n";
        UyDCopy += fechaHoy;
        UyDCopy += horaInicioFormateada + "\n";
        break;
      case TipoServicio.tOMORROW.toString():
        UyDCopy += "*RESERVA MAÑANA*" + "\n\n";
        UyDCopy += fecha;
        UyDCopy += fechaInicioFormateada;
        UyDCopy += hora;
        UyDCopy += horaInicioFormateada+ "\n";
        break;
      case TipoServicio.LATER.toString():
        UyDCopy += "*RESERVA MÁS ADELANTE*" + "\n\n";
        UyDCopy += fecha;
        UyDCopy += fechaInicioFormateada;
        UyDCopy += hora;
        UyDCopy += horaInicioFormateada+ "\n";
        break;
    }

    UyDCopy +="*Pasajeros:* " + serviceSelected.cantPasajeros + "\n\n";
    UyDCopy += "*Origen:* " + serviceSelected.lugarOrigen + "\n";

    if (serviceStopEntity && serviceStopEntity!.length > 0) {
      // Ordenar el array utilizando la función de comparación personalizada
      serviceStopEntity!.sort(compararPorAtributoNoOrden);
      UyDCopy += "\n";
      UyDCopy += "*Paradas:*" + "\n";
      serviceStopEntity!.forEach(stop => {
        UyDCopy += "*P" + stop.numOrden + "*: " + stop.lugar + "\n";
      });
      UyDCopy += "\n";
      UyDCopy += "*Destino:* " + serviceSelected.lugarDestino + "\n\n";
    } else
      UyDCopy += "\n" + "*Destino:* " + serviceSelected.lugarDestino + "\n\n";

    const details = '*Detalles del servicio:*';
    //const px = '👨‍👩‍👧‍👦 Pasajeros: ' + serviceSelected.cantPasajeros;

    if (serviceSelected.modoCalcPrecio === 'AEROPUERTO'){
      esAeropuerto = true;
    }

    if(esAeropuerto || serviceSelected.conComprobante) {
      choferLicenciaOperativa = true;
    }
    

    if(choferLicenciaOperativa || tieneFactores || serviceSelected.conDescuento || esAeropuerto){
      UyDCopy += "\n" + details + "\n";
    }

    /*if(serviceSelected.cantPasajeros){
      UyDCopy += px + "\n";
    }*/

    if ((serviceSelected.factoresViaje?.length === 1 && serviceSelected.factoresViaje[0].nombre !== 'Estandar') || serviceSelected.factoresViaje?.length > 1) {
      serviceSelected.factoresViaje?.forEach(factor => {
        switch (factor.nombre) {
          case 'Horario Pico':
            UyDCopy += "🕗 " + factor.nombre + "\n";
            break;
          case 'Madrugada':
            UyDCopy += "🌃 " + factor.nombre + "\n";
            break;
          case 'Nocturno':
            UyDCopy += "🏙️ " + factor.nombre + "\n";
            break;
          case 'Carga':
            UyDCopy +="🚚 "+factor.nombre + "\n";
            break;
          case 'Aire':
            UyDCopy +="❄️ "+factor.nombre + "\n";
            break;
          case 'Confort':
            UyDCopy +="🚘 "+factor.nombre + "\n";
            break;
          case 'Equipaje':
            UyDCopy +="🧳 "+factor.nombre + "\n";
            break;
          /*case 'Estandar':
            UyDCopy +="🚙 "+factor.nombre + "\n";
            break;*/
          case 'Extranjero':
            UyDCopy +="🌎 "+factor.nombre + "\n";
            break;
          case 'Mascotas':
            UyDCopy +="🐶 "+factor.nombre + "\n";
            break;
          case 'Moderno':
            UyDCopy +="🚘 "+factor.nombre + "\n";
            break;
          case 'Periferico':
            UyDCopy +="🛣️ "+factor.nombre + "\n";
            break;
          case 'Provincia':
            UyDCopy +="🛣️ "+factor.nombre + "\n";
            break;
          case 'Día festivo':
            UyDCopy +="🎇 "+factor.nombre + "\n";
            break;
          case 'Moto estándar':
            UyDCopy += "🛵 " + factor.nombre + "\n";
            break;
          case 'Moto combustión':
            UyDCopy += "🛵 " + factor.nombre + "\n";
            break;
          case 'Moto eléctrica':
            UyDCopy += "🛵 " + factor.nombre + "\n";
            break;
          case 'Almendron':
            UyDCopy +="🚗 "+factor.nombre + "\n";
            break;
          /*default:
            UyDCopy +=" "+factor.nombre + "\n";
            break;*/
        }

      });
    }

    if (serviceSelected.conDescuento) {
      const oferta = "❗" + 'OFERTA CLIENTE' + "❗";
      UyDCopy += oferta + "\n";
    }

    if (choferLicenciaOperativa) {
      const choferLic = "📋 " + 'Con Licencia Operativa';
      UyDCopy += choferLic + "\n";
    }

    if (serviceSelected.modoCalcPrecio === 'AEROPUERTO') {

      let valueMinutosEspera;

      const appConfigEncoded = localStorage.getItem('appConfig');
      let config;
      if (appConfigEncoded) {
        config = JSON.parse(atob(appConfigEncoded));
      }

      if (config && config.length > 0) {
        valueMinutosEspera = config.find((element: { nombre: string; }) => element.nombre == "CANT_MINT_ESP_CLI_INCLUID_PLAY_AEROP");
      }

      const head = 'El cliente cuenta con ' + valueMinutosEspera.valor + ' minutos de espera en el aeropuerto libre de costo, pasado este tiempo debe informar a la operadora';

      switch (serviceSelected.tipoTrayecto) {
        case 'VUELTA':
        case 'IDA_Y_VUELTA':
          UyDCopy += head + "\n";
          break;
      }
    }

    const detailsModoPago = '*Modo de pago:*';

    if(serviceSelected.modoPagoDescCorta){
      UyDCopy += "\n";
      UyDCopy += detailsModoPago + "\n";
    } else {
      UyDCopy += "\n";
      UyDCopy += detailsModoPago + "\n";
      if(serviceSelected.modoPago && serviceSelected.modoPago.nombre){
        serviceSelected.modoPagoDescCorta = serviceSelected.modoPago.nombre.toUpperCase();
      }
    }

    switch (serviceSelected.modoPagoDescCorta) {
      case 'TRANF_CHOFER':
        UyDCopy += "🫰💳 Transferencia CUP Chofer \n";
        break;
      case 'TRANSFERENCIA CUP':
        UyDCopy += "🫰💳 Transferencia CUP Chofer \n";
        break;
      case 'TRANSFERENCIA CUP CHOFER':
        UyDCopy += "🫰💳 Transferencia CUP Chofer \n";
        break;
      case 'TRANF_MLC_CHOFER':
        UyDCopy += "🫰💳 Transferencia MLC Chofer \n";
        break;
      case 'TRANSFERENCIA MLC CHOFER':
        UyDCopy += "🫰💳 Transferencia MLC Chofer \n";
        break;
      case 'EFECTIVO':
        switch (serviceSelected.monedaPago) {
          case 'USD':
            UyDCopy +="🫰 Efectivo USD\n";
            break;
          case 'CUP':
            UyDCopy +="🫰 Efectivo CUP\n";
            break;
          case 'EUR':
            UyDCopy +="🫰 Efectivo EUR\n";
            break;
          case 'MLC':
            UyDCopy +="🫰 Efectivo CUP\n";
            break;
        }
        break;
      case 'TRANF_AGENCIA':
        UyDCopy += "🫰💳 Transferencia a la Agencia \n";
        break;
      case 'TRANSFERENCIA MLC':
        UyDCopy += "🫰💳 Transferencia a la Agencia \n";
        break;
      case 'FONDO':
        UyDCopy +="🫰 A CUENTA\n";
        break;
      case 'CHEQUE':
        UyDCopy += "🫰 A CUENTA\n";
        break;
      case 'EXTERIOR':
        UyDCopy += "🫰💳 Transferencia a la Agencia\n";
        break;
      case 'ZELLE':
        UyDCopy += "🫰💳 Transferencia a la Agencia\n";
        break;
      case 'PAYPAL':
        UyDCopy += "🫰💳 Transferencia a la Agencia\n";
        break;
      case 'BIZUM':
        UyDCopy += "🫰💳 Transferencia a la Agencia\n";
        break;
      case 'WISE':
        UyDCopy += "🫰💳 Transferencia a la Agencia\n";
        break;
      case 'TROPIPAY':
        UyDCopy += "🫰💳 Transferencia a la Agencia\n";
        break;
      case 'VISA':
        UyDCopy += "🫰💳 Transferencia a la Agencia\n";
        break;
      case 'MASTERCARD':
        UyDCopy += "🫰💳 Transferencia a la Agencia\n";
        break;
      case 'TRANF_CUP_AGENCIA':
        UyDCopy += "🫰💳 Transferencia a la Agencia (CUP)\n";
        break;
      case 'TRANSFERENCIA CUP AGENCIA':
        UyDCopy += "🫰💳 Transferencia a la Agencia (CUP)\n";
        break;
      default:
        UyDCopy += "🫰 Efectivo" + "\n";
        break;
    }

    if ((serviceSelected.incidenciaChofer != null && serviceSelected.incidenciaChofer != '')
    || serviceSelected.tiempoIncluido || serviceSelected.precioAumentado || (serviceSelected.precioEstimChofer && serviceSelected.pagoVueltoCliente)) {
      UyDCopy += "\n";
      UyDCopy += "*Incidencia(s):* " + "\n";
    }

    if (serviceSelected.precioAumentado) {
      UyDCopy += "📈 " + "Precio Aumentado" + "\n";
    }

    if (serviceSelected.incidenciaChofer != null && serviceSelected.incidenciaChofer != '') {
      UyDCopy +=serviceSelected.incidenciaChofer + "\n";
    }

    if (serviceSelected.tiempoIncluido) {
      let tiempoIncluido = '';
      if (serviceSelected.tiempoIncluido) {
        tiempoIncluido = convertirMinutosAHoras(Number(serviceSelected.tiempoIncluido));
      }
      UyDCopy += "Incluye *"+  tiempoIncluido + "* de recorrido total, una vez culminado este tiempo informar a la operadora, ya que el mismo podría variar" + "\n";
    }

    if(serviceSelected.precioEstimChofer && serviceSelected.pagoVueltoCliente){
      UyDCopy += "El cliente paga con " + serviceSelected.monedaPagaCliente + "\n";
      if (serviceSelected.pagoVueltoCliente) {
        if (serviceSelected.pagoVueltoCliente > 0)
          UyDCopy += "Debe pagar además " + "*" + serviceSelected.pagoVueltoCliente + "*" + " " + "*" + 'CUP' + "*" + "\n\n";
        if (serviceSelected.pagoVueltoCliente < 0)
          UyDCopy += "Se le debe devolver " + "*" + Math.abs(serviceSelected.pagoVueltoCliente) + "*" + " " + "*" + 'CUP' + "*" + "\n\n";
      }
    }

    if (serviceSelected.detallesCliente != null && serviceSelected.detallesCliente != ''){
      UyDCopy +=serviceSelected.detallesCliente + "\n";
    }

    UyDCopy += "\n";
    let decorator = '*';

    let isTransferExterior = this.isTransferExternPaymentMode(serviceSelected.modoPagoDescCorta);

    let isTransferMLCChofer = serviceSelected.modoPagoDescCorta === 'TRANF_MLC_CHOFER' || serviceSelected.modoPagoDescCorta === 'TRANSFERENCIA MLC CHOFER' ? true : false;

    let isTransferMLCAgencia = serviceSelected.modoPagoDescCorta === 'TRANF_AGENCIA' || serviceSelected.modoPagoDescCorta === 'TRANSFERENCIA MLC' ? true : false;

    let isTransferCUPAgencia = serviceSelected.modoPagoDescCorta === 'TRANF_CUP_AGENCIA' || serviceSelected.modoPagoDescCorta === 'TRANSFERENCIA CUP AGENCIA' ? true : false;

    if(serviceSelected.monedaPrecioEstimado !== 'CUP' && (serviceSelected.monedaPago === 'MLC' || serviceSelected.monedaPago === 'CUP')){

      if(serviceSelected.monedaPago === 'MLC' && isTransferMLCChofer){
        UyDCopy += "*Precio:* " + serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + " / ";
        UyDCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
      } else if(serviceSelected.monedaPago === 'MLC' && isTransferMLCAgencia){
        UyDCopy += "*Precio:* " + serviceSelected.precioMonedaExtranjera + ' ' + decorator + serviceSelected.monedaPrecioEstimado + decorator + "\n"
      } else if(serviceSelected.monedaPago === 'CUP' && isTransferCUPAgencia){
        UyDCopy += "*Precio:* " + serviceSelected.precioMonedaExtranjera + ' ' + decorator + serviceSelected.monedaPrecioEstimado + decorator + "\n"
      } else {
        UyDCopy += "*Precio:* " + serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
        //UyDCopy += "*Precio:* " + serviceSelected.precioMonedaExtranjera + ' ' + decorator + serviceSelected.monedaPrecioEstimado + decorator + "\n";
      }      

    } else if(serviceSelected.monedaPrecioEstimado !== 'CUP' && serviceSelected.monedaPrecioEstimado === serviceSelected.monedaPago){

      UyDCopy += "*Precio:* " + serviceSelected.precioMonedaExtranjera + ' ' + decorator + serviceSelected.monedaPrecioEstimado + decorator + "\n"

    } else if(serviceSelected.monedaPrecioEstimado !== 'CUP' && serviceSelected.monedaPrecioEstimado !== serviceSelected.monedaPago){

      if (serviceSelected.monedaPago == TipoMoneda.EUR.toString()) {

        if(isTransferExterior){
          UyDCopy += "*Precio:* " + serviceSelected.precioMonedaExtranjera + ' ' + decorator + serviceSelected.monedaPrecioEstimado + decorator + "\n";
        } else 
          UyDCopy += "*Precio:* " + serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";
      } else if (serviceSelected.monedaPago == TipoMoneda.USD.toString()) {
        if(isTransferExterior){
          UyDCopy += "*Precio:* " + serviceSelected.precioMonedaExtranjera + ' ' + decorator + serviceSelected.monedaPrecioEstimado + decorator + "\n";
        } else 
          UyDCopy += "*Precio:* " + serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";
      }

    } else {

      if(isTransferExterior){
        UyDCopy += "*Precio:* " + serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
      } else if (serviceSelected.monedaPago == TipoMoneda.EUR.toString()) {
        UyDCopy += "*Precio:* " + serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + " / "
        UyDCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
      } else if (serviceSelected.monedaPago == TipoMoneda.USD.toString()) {
        UyDCopy += "*Precio:* " + serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + " / "
        UyDCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
      } else {

        if(serviceSelected.monedaPago === 'MLC' && isTransferMLCChofer){
          UyDCopy += "*Precio:* " + serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + " / ";
          UyDCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
        } else {
          UyDCopy += "*Precio:* " + serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
        }

      }

    }

    if (serviceSelected.totalKm) {
      UyDCopy += "*Km:* " + serviceSelected.totalKm + "\n";
    } else
      UyDCopy += "*Km:*  - \n";

      if (tipoComision) {
        if (tipoComision === 'COMISION_AGENCIA') {
          if(serviceSelected.monedaComisionAgencia !== 'CUP'){
            valorComision = "*Comisión:* " + serviceSelected.importeComisionAgenciaMonedaExt + " *" + serviceSelected.monedaComisionAgencia + "*";
          } else
              valorComision = "*Comisión:* " + serviceSelected.importeComisionAgencia + " *CUP*";
        } else if (tipoComision === 'COMISION_CLIENTE') {
          if(serviceSelected.monedaComisionCliente !== 'CUP'){
            valorComision = "*Comisión:* " + serviceSelected.importeComisionClienteMonedaExt + " *" + serviceSelected.monedaComisionCliente + "*";
          } else
              valorComision = "*Comisión:* " + serviceSelected.importeComisionCliente + " *CUP*";
        } else if (tipoComision === 'COMISION_AGENCIA_CLIENTE') {
          if(serviceSelected.monedaComisionCliente === 'CUP' && serviceSelected.monedaComisionAgencia === 'CUP'){
            let suma = Number(serviceSelected.importeComisionAgencia) + Number(serviceSelected.importeComisionCliente);
            valorComision = "*Comisión:* " + suma + " *CUP*";
          } else {
            let suma = Number(serviceSelected.importeComisionAgenciaMonedaExt) + Number(serviceSelected.importeComisionClienteMonedaExt);
            valorComision = "*Comisión:* "  + suma + " *" + serviceSelected.monedaComisionCliente + "*";
          }
        }
      }

      if (valorComision) {
        UyDCopy += "\n" + valorComision + "\n";
      }

    UyDCopy+="\n" + "(US-"+this.user+")"+ "\n";

    this.clipboard.copy(UyDCopy);
    /*this.notificacionService.notificationSuccess(
      'Copiado al portapapeles.'
    );*/

    /** Insertando traza */
    let detail = this.traceUyD;
    this.tracesService.saveTrace('urgente_disponibilidad', 'Servicios en procesos', detail, undefined, serviceSelected.codViaje);
  }

  buildCCCopy(copyType: string, serviceSelected: ServiceEntity, serviceStopEntity?: ServiceStopEntity[], selectedClientPerson?: PersonEntity | undefined, selectedChoferPerson?: PersonEntity | undefined) {
    let CCCopy: string = '';
    let nombreChofer: string = '';

    let decorator = '*';

    if(serviceSelected.viaComunicacion && serviceSelected.viaComunicacion.nombre == 'Telegram')
      decorator = '**';

    let factorMoto = false;

    serviceSelected.codViaje = serviceSelected.codViaje ? serviceSelected.codViaje : '-';

    if (selectedChoferPerson?.identificador) {
      serviceSelected.choferIdentificador = selectedChoferPerson?.identificador;
    } else if (serviceSelected.chofer && serviceSelected.chofer.identificador) {
      serviceSelected.choferIdentificador = serviceSelected.chofer.identificador;
    } else serviceSelected.choferIdentificador = '-';

    serviceSelected.tiempoEstimChofer = serviceSelected.tiempoEstimChofer ? serviceSelected.tiempoEstimChofer : 0;

    if (serviceSelected.chofer && serviceSelected.chofer.autoSeleccionado) {
      if (serviceSelected.chofer.autoSeleccionado.colorAutoEntity) {
        serviceSelected.colorAuto = serviceSelected.chofer.autoSeleccionado.colorAutoEntity.nombre;
      }
      if (serviceSelected.chofer.autoSeleccionado.marcaAutoEntity) {
        serviceSelected.marcaAuto = serviceSelected.chofer.autoSeleccionado.marcaAutoEntity.nombre;
      }
    } else if(selectedChoferPerson && selectedChoferPerson.autoSeleccionado){
      if (selectedChoferPerson.autoSeleccionado.colorAutoEntity) {
        serviceSelected.colorAuto = selectedChoferPerson.autoSeleccionado.colorAutoEntity.nombre;
      }
      if (selectedChoferPerson.autoSeleccionado.marcaAutoEntity) {
        serviceSelected.marcaAuto = selectedChoferPerson.autoSeleccionado.marcaAutoEntity.nombre;
      }
    }
    serviceSelected.colorAuto = serviceSelected.colorAuto ? serviceSelected.colorAuto : '-';
    serviceSelected.marcaAuto = serviceSelected.marcaAuto ? serviceSelected.marcaAuto : '-';

    serviceSelected.cantPasajeros = serviceSelected.cantPasajeros ? serviceSelected.cantPasajeros : 0;
    serviceSelected.lugarOrigen = serviceSelected.lugarOrigen ? serviceSelected.lugarOrigen : '-';
    serviceSelected.lugarDestino = serviceSelected.lugarDestino ? serviceSelected.lugarDestino : '-';
    serviceSelected.precioEstimChofer = serviceSelected.precioEstimChofer ? serviceSelected.precioEstimChofer : 0;
    serviceSelected.monedaPago = serviceSelected.monedaPago == null || serviceSelected.monedaPago == TipoMoneda.Otra.toString() /*|| serviceSelected.monedaPago == TipoMoneda.CUP.toString()*/ ? 'MLC' : serviceSelected.monedaPago;

    let tieneFactores = false;

    if (serviceSelected.factoresViaje?.length > 0) {
      tieneFactores = true;
      let factorMotor = serviceSelected.factoresViaje.filter(factor => factor.esFactorMoto);
      if (factorMotor.length > 0) {
        factorMoto = true;
      }
    }

    if (serviceSelected.precioAumentado) {

      let textoPrecioAumentado = "Le habíamos dado una tarifa inicialmente, pero no contamos en este momento con disponibilidad para su servicio por el precio anterior. Tenemos un auto disponible para usted: ";
      let textoOpcion = "Es solo una opción, " + decorator + "en caso de que no lo desee continuamos la gestión por el precio inicial." + decorator + " Díganos por favor si va a desear el servicio por este costo.";

      if (factorMoto) {
        CCCopy += decorator + "------ ReneMoto ------" + decorator + "\n\n";
        textoPrecioAumentado = "Le habíamos dado una tarifa inicialmente, pero no contamos en este momento con disponibilidad para su servicio por el precio anterior. Tenemos una moto disponible para usted: ";
      }

      const tiempo = "Tiempo: ";
      const fechaReserva = "Fecha: ";
      const horaReserva = "Hora: ";
      const precio = "Precio: ";

      const fechaInicio = new Date(serviceSelected.fechaInicio);
      const fechaInicioFormateada = format(fechaInicio, 'dd/MM/yyyy');
      const horaInicioFormateada = format(fechaInicio, 'HH:mm');

      CCCopy += textoPrecioAumentado + "\n\n";
      switch (copyType) {
        case TipoServicio.INMEDIATE.toString():
          CCCopy += tiempo + decorator + serviceSelected.tiempoEstimChofer + decorator + " " + decorator + "min." + decorator + "\n";
          break;
        case TipoServicio.RESERVE.toString():
          CCCopy += fechaReserva;
          CCCopy += fechaInicioFormateada + " ";
          CCCopy += horaReserva;
          CCCopy += horaInicioFormateada + "\n";
          break;
      }      
      CCCopy += precio + decorator + serviceSelected.precioEstimChofer + decorator + " " + decorator + "CUP." + decorator + "\n";
      CCCopy += "\n";
      CCCopy += textoOpcion + "\n";

    } else {

      if (selectedChoferPerson?.nombreApellidos){
        nombreChofer = selectedChoferPerson.nombreApellidos.split(' ')[0];
      } else if (serviceSelected.chofer) {
        nombreChofer = serviceSelected.chofer.nombreApellidos.split(' ')[0];
      }

      const fecha = "🗓️ " + "Fecha: ";
      const fechaHoy = "🗓️ " + "Fecha: (Hoy)";
      const hora = "⏰ " + "Hora: ";

      if (factorMoto) {
        CCCopy += decorator + "------ ReneMoto ------" + decorator + "\n\n";
      }

      CCCopy +="Id: " + serviceSelected.codViaje + "   ";
      CCCopy +=" Chofer: " + serviceSelected.choferIdentificador + "\n";

      const fechaInicio = new Date(serviceSelected.fechaInicio);
      const fechaInicioFormateada = format(fechaInicio, 'dd/MM/yyyy');
      const horaInicioFormateada = format(fechaInicio, 'HH:mm');

      switch (copyType) {
        case TipoServicio.INMEDIATE.toString():
          CCCopy += decorator + "Le recoge en" + decorator + " " + decorator + serviceSelected.tiempoEstimChofer + decorator + " " + decorator + "minutos" + decorator + "\n";
          break;
        case TipoServicio.RESERVE.toString():
          CCCopy += decorator + "Le recoge a las" + decorator + " " + decorator + horaInicioFormateada + decorator + "\n";
          break;
      }

      CCCopy +="Nombre: " + nombreChofer + "\n";
      if(serviceSelected.marcaAuto!='-'){
        CCCopy += "Marca: " + serviceSelected.marcaAuto + "\n";
      }
      if(serviceSelected.colorAuto!='-'){
        CCCopy += "Color: " + serviceSelected.colorAuto + "\n";
      }

      switch (copyType) {
        case TipoServicio.INMEDIATE.toString():
          CCCopy += fechaHoy + "\n";
          break;
        case TipoServicio.RESERVE.toString():
          CCCopy += fecha;
          CCCopy += fechaInicioFormateada + "\n";
          break;
      }

      CCCopy += "👨‍👩‍👦 " + "Pasajeros: " + serviceSelected.cantPasajeros + "\n\n";
      CCCopy +="🏳️ " + "Origen: " + serviceSelected.lugarOrigen + "\n";

      if (serviceStopEntity!.length > 0) {
        // Ordenar el array utilizando la función de comparación personalizada
        serviceStopEntity!.sort(compararPorAtributoNoOrden);
        CCCopy += "\n";
        CCCopy += "Paradas:" + "\n";
        serviceStopEntity!.forEach(stop => {
          CCCopy += "P" + stop.numOrden + ": " + stop.lugar + "\n";
        });
        CCCopy += "\n";
        CCCopy += "🏁 " + "Destino: " + serviceSelected.lugarDestino + "\n\n";
      } else
        CCCopy += "\n" + "🏁 " + "Destino: " + serviceSelected.lugarDestino + "\n\n";

      
      let precioPorMinuto = 0;
      let tipoViaje = 'recorrido';
      if (serviceSelected.precioPorMinuto){
        precioPorMinuto = Number(serviceSelected.precioPorMinuto.toFixed(2));
      }
      let tiempoIncluido = '';
      if (serviceSelected.tiempoIncluido) {
        tiempoIncluido = convertirMinutosAHoras(Number(serviceSelected.tiempoIncluido));
      }
      if (serviceSelected.lugarOrigen && serviceSelected.lugarDestino && serviceStopEntity && serviceStopEntity.length === 0) {
        tipoViaje = '';
      } else if (serviceSelected.lugarOrigen && serviceSelected.lugarDestino && serviceStopEntity && serviceStopEntity.length > 0) {
        tipoViaje = 'recorrido';
      }
      let timeService = 'En este servicio usted puede usar ' + decorator +  tiempoIncluido + decorator + ' el auto sin modificar el trayecto.';
      if (factorMoto) {
        timeService = 'En este servicio usted puede usar ' + decorator +  tiempoIncluido + decorator + ' la moto sin modificar el trayecto.';
      }
      const surCharge = 'Pasado este tiempo se cobrará un recargo de ' + decorator + precioPorMinuto + ' CUP' + decorator + ' por minuto.';
      const warn = 'Si modifica el trayecto, tanto el precio como el tiempo serán modificados.'

      if (tipoViaje === 'recorrido' && serviceSelected.tiempoIncluido){
        CCCopy += decorator + "Incidencia(s):" + decorator + " " + "\n";
        CCCopy += timeService + "\n";
        CCCopy += surCharge + "\n";
        CCCopy += warn + "\n";
        CCCopy += "\n";
      }

      /*if (serviceSelected.detallesCliente != null && serviceSelected.detallesCliente != ''
      || serviceSelected.tiempoIncluido) {
        CCCopy += "\n";
        CCCopy += "Incidencia(s): " + "\n";
      }

      if (serviceSelected.detallesCliente != null && serviceSelected.detallesCliente != '') {
        CCCopy +=serviceSelected.detallesCliente+ "\n";
      }

      if (serviceSelected.tiempoIncluido) {
        CCCopy += "Incluye " +  serviceSelected.tiempoIncluido + " min de recorrido total, una vez culminado este tiempo informar a la operadora, ya que el mismo podría variar" + "\n";
      }*/

      const details = decorator + 'Detalles del servicio:' + decorator;

      if (tieneFactores) {
        CCCopy += details + "\n";
      }

      if (serviceSelected.factoresViaje?.length > 0) {
        serviceSelected.factoresViaje?.forEach(factor => {
          switch (factor.nombre) {
            case 'Horario Pico':
              CCCopy += "🕗 " + factor.nombre + "\n";
              break;
            case 'Madrugada':
              CCCopy += "🌃 " + factor.nombre + "\n";
              break;
            case 'Nocturno':
              CCCopy += "🏙️ " + factor.nombre + "\n";
              break;
            case 'Carga':
              CCCopy += "🚚 " + factor.nombre + "\n";
              break;
            case 'Aire':
              CCCopy += "❄️ " + factor.nombre + "\n";
              break;
            case 'Confort':
              CCCopy += "🚘 " + factor.nombre + "\n";
              break;
            case 'Equipaje':
              CCCopy += "🧳 " + factor.nombre + "\n";
              break;
            case 'Estandar':
              if(!factorMoto){
                CCCopy += "🚙 " + factor.nombre + "\n";
              }
              break;
            case 'Extranjero':
              CCCopy += "🌎 " + factor.nombre + "\n";
              break;
            case 'Mascotas':
              CCCopy += "🐶 " + factor.nombre + "\n";
              break;
            case 'Moderno':
              CCCopy += "🚘 " + factor.nombre + "\n";
              break;
            case 'Día festivo':
              CCCopy += "🎇 " + factor.nombre + "\n";
              break;
            case 'Moto estándar':
              CCCopy += "🛵 " + factor.nombre + "\n";
              break;
            case 'Moto combustión':
              CCCopy += "🛵 " + factor.nombre + "\n";
              break;
            case 'Moto eléctrica':
              CCCopy += "🛵 " + factor.nombre + "\n";
              break;
            case 'Almendron':
              CCCopy +="🚗 "+factor.nombre + "\n";
              break;
            case 'Provincia':
              CCCopy +="🛣️ "+factor.nombre + "\n";
              break;
            /*default:
              priceCopy +=" "+factor.nombre + "\n";
              break;*/
          }

        });

        if (serviceSelected.valorPeriferico) {
          CCCopy +="🛣️ "+ "Servicio periférico" + "\n";
        }

        CCCopy += "\n";
      }

      if (serviceSelected.datosParaChofer || ((!serviceSelected.lugarDestino || serviceSelected.lugarDestino === '-')
      && (!serviceSelected.precioEstimChofer || serviceSelected.precioEstimChofer === 0))) {
        CCCopy += "Incidencia(s): " + "\n";
      }

      if ((!serviceSelected.lugarDestino || serviceSelected.lugarDestino === '-') && (!serviceSelected.precioEstimChofer || serviceSelected.precioEstimChofer === 0)) {
        //CCCopy += "*Incidencia(s):* " + "\n";
        CCCopy += "\nEl precio de su servicio podrá variar en función del " + decorator + "km" + decorator + " y " + decorator + "tiempo" + decorator + ", este será dado a su chofer al finalizar el recorrido.\n";
      }

      if (serviceSelected.datosParaChofer != null && serviceSelected.datosParaChofer != '') {
        CCCopy +=serviceSelected.datosParaChofer+"\n";
      }

      if(serviceSelected.modoPago && serviceSelected.modoPago.nombre){
        serviceSelected.modoPagoDescCorta = serviceSelected.modoPago.nombre.toUpperCase();
      } else if (serviceSelected.modoPagoDescCorta){
        serviceSelected.modoPagoDescCorta = serviceSelected.modoPagoDescCorta.toUpperCase();
      }

      CCCopy +="\n";
      CCCopy += "💵: ";

      let isTransferMLCChofer = serviceSelected.modoPagoDescCorta === 'TRANF_MLC_CHOFER' || serviceSelected.modoPagoDescCorta === 'TRANSFERENCIA MLC CHOFER' ? true : false;

      if(serviceSelected.monedaPrecioEstimado !== 'CUP' && (serviceSelected.monedaPago === 'MLC' || serviceSelected.monedaPago === 'CUP')){

        if(isTransferMLCChofer){
          CCCopy += serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + " / ";
          CCCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
        } else if(serviceSelected.monedaPago === 'MLC'){
          CCCopy += serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + "\n";
        } else {
          CCCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
        }

      } else if(serviceSelected.monedaPrecioEstimado !== 'CUP' && serviceSelected.monedaPrecioEstimado === serviceSelected.monedaPago){

        CCCopy += serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPrecioEstimado + decorator + "\n";

      } else if(serviceSelected.monedaPrecioEstimado !== 'CUP' && serviceSelected.monedaPrecioEstimado !== serviceSelected.monedaPago){

        if (serviceSelected.monedaPago == TipoMoneda.EUR.toString() || serviceSelected.monedaPago == TipoMoneda.USD.toString()) {
          CCCopy += serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";
        }

      } else {

        if (serviceSelected.monedaPago == TipoMoneda.EUR.toString() || serviceSelected.monedaPago == TipoMoneda.USD.toString()) {

          if(serviceSelected.modoPagoDescCorta && serviceSelected.modoPagoDescCorta === 'EFECTIVO'){
            CCCopy += serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + " / ";
            CCCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
          } else
            CCCopy += serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";

        } else {

          if(isTransferMLCChofer){
            CCCopy += serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + " / ";
            CCCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
          } else if(serviceSelected.monedaPago === 'MLC'){
            CCCopy += serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + "\n";
          } else {
            CCCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
          }

        }

      }

      /*if (serviceSelected.modoPagoDescCorta === 'FONDO') {
        if(serviceSelected.id !== null){
          if (selectedClientPerson!=null && selectedClientPerson.cuenta!=null) {
            CCCopy += 'El fondo de su cuenta es: $' + (selectedClientPerson.cuenta.saldo + selectedClientPerson.cuenta.bonificacion) + "\n";
          }
        } else {
          if (selectedClientPerson!=null && selectedClientPerson.cuenta!=null) {
            CCCopy += 'El fondo de su cuenta es: $' + ((selectedClientPerson.cuenta.saldo + selectedClientPerson.cuenta.bonificacion) - serviceSelected.precioEstimChofer) + "\n";
          }
        }
      } else {
        if (selectedClientPerson!=null && selectedClientPerson.cuenta!=null && (selectedClientPerson.cuenta.saldo + selectedClientPerson.cuenta.bonificacion) !== 0) {
          CCCopy += 'El fondo de su cuenta es: $' + (selectedClientPerson.cuenta.saldo + selectedClientPerson.cuenta.bonificacion) + "\n";
        }
       }*/

      if (selectedClientPerson != null && selectedClientPerson.cuenta != null && (selectedClientPerson.cuenta.saldo + selectedClientPerson.cuenta.bonificacion) > 0) {
        CCCopy += "\n" + 'Fondo: $' + formatSaldo(selectedClientPerson.cuenta.saldo + selectedClientPerson.cuenta.bonificacion) + "\n";
      }

    }

    CCCopy+="\n" + "(US-"+this.user+")"+ "\n";

    this.clipboard.copy(CCCopy);
    /*this.notificacionService.notificationSuccess(
      'Copiado al portapapeles.'
    );*/

    /** Insertando traza */
    let detail = this.traceCC;
    this.tracesService.saveTrace('confirmar_cliente', 'Servicios en procesos', detail, undefined, serviceSelected.codViaje);
  }

  buildDCCopy(copyType: string, serviceSelected: ServiceEntity, serviceStopEntity?: ServiceStopEntity[],
              telefonoCliente?: string, telefonoCasaCliente?: string, telefonoWhatsappCliente?: string,
              tipoComision?: string, selectedChoferPerson?: PersonEntity | undefined, variableConfigList?: ConfigVariableEntity[], selectedClientPerson?: PersonEntity | undefined) {
    let DCCopy: string = '';

    serviceSelected.importeComisionAgencia = serviceSelected.importeComisionAgencia ? serviceSelected.importeComisionAgencia : 0;
    serviceSelected.importeComisionCliente = serviceSelected.importeComisionCliente ? serviceSelected.importeComisionCliente : 0;
    serviceSelected.codViaje = serviceSelected.codViaje ? serviceSelected.codViaje : '-';

    if (serviceSelected?.choferIdentificador) {
      serviceSelected.choferIdentificador = serviceSelected.choferIdentificador;
    } else if (serviceSelected.chofer && serviceSelected.chofer.identificador) {
      serviceSelected.choferIdentificador = serviceSelected.chofer.identificador;
    } else serviceSelected.choferIdentificador = '-';

    serviceSelected.cantPasajeros = serviceSelected.cantPasajeros ? serviceSelected.cantPasajeros : 0;
    serviceSelected.lugarOrigen = serviceSelected.lugarOrigen ? serviceSelected.lugarOrigen : '-';
    serviceSelected.lugarDestino = serviceSelected.lugarDestino ? serviceSelected.lugarDestino : '-';

    if(telefonoCliente){
      telefonoCliente = telefonoCliente;
    } else if(selectedClientPerson?.telefCelular){
      telefonoCliente = selectedClientPerson?.telefCelular;
    } else if(serviceSelected.cliente){
      telefonoCliente = serviceSelected.cliente.telefCelular;
    } if (telefonoCliente == null || telefonoCliente === undefined){telefonoCliente = '-';}


    if (telefonoCasaCliente) {
      telefonoCasaCliente = telefonoCasaCliente;
    } else if (selectedClientPerson?.telefCasa) {
      telefonoCasaCliente = selectedClientPerson?.telefCasa;
    } else if (serviceSelected.cliente) {
      telefonoCasaCliente = serviceSelected.cliente.telefCasa;
    } if (telefonoCasaCliente == null || telefonoCasaCliente === undefined || telefonoCasaCliente === '') { telefonoCasaCliente = '-'; }

    if (telefonoWhatsappCliente) {
      telefonoWhatsappCliente = telefonoWhatsappCliente;
    } else if (selectedClientPerson?.telefWhatsapp) {
      telefonoWhatsappCliente = selectedClientPerson?.telefWhatsapp;
    } else if (serviceSelected.cliente) {
      telefonoWhatsappCliente = serviceSelected.cliente.telefWhatsapp;
    } if (telefonoWhatsappCliente == null || telefonoWhatsappCliente === undefined || telefonoWhatsappCliente === ''){telefonoWhatsappCliente = '-';}

    serviceSelected.precioEstimChofer = serviceSelected.precioEstimChofer ? serviceSelected.precioEstimChofer : 0;
    serviceSelected.estadoFinalEstimado = serviceSelected.estadoFinalEstimado ? serviceSelected.estadoFinalEstimado : '-';
    serviceSelected.saldoFinalEstimado = serviceSelected.saldoFinalEstimado ? serviceSelected.saldoFinalEstimado : 0;
    serviceSelected.monedaPago = serviceSelected.monedaPago == null || serviceSelected.monedaPago == TipoMoneda.Otra.toString() /*|| serviceSelected.monedaPago == TipoMoneda.CUP.toString()*/ ? 'MLC' : serviceSelected.monedaPago;

    const appConfigEncoded = localStorage.getItem('appConfig');
    let appConfig: AppConfigurationEntity[];
    let veryCriticLimit: any = null;
    let criticLimit: any = null;
    let warningLimit: any = null;
    let valorComision: any | undefined;
    let nombreCliente: string = '';

    let choferLicenciaOperativa = false;
    let esAeropuerto = false;

    if (appConfigEncoded) {
      appConfig = JSON.parse(atob(appConfigEncoded));
      appConfig.forEach(elem => {
        if (elem.nombre.match('AVISO_SALDO_CHOFER_MUY_CRITICO')){
          veryCriticLimit = elem;
        } else if (elem.nombre.match('AVISO_SALDO_CHOFER_CRITICO')){
          criticLimit = elem;
        } else if (elem.nombre.match('AVISO_SALDO_CHOFER_FONDO')){
          warningLimit = elem;
        }
      });
      // avisoSaldoChoferList = appConfig.filter(elem => elem.nombre.includes('AVISO_SALDO_CHOFER'));
    }

    if (serviceSelected.clienteNombreApel){
      nombreCliente = serviceSelected.clienteNombreApel;
    } else if (serviceSelected.cliente) {
      nombreCliente = serviceSelected.cliente.nombreApellidos;
    }

    let factorMoto = false;

    let tieneFactores = false;

    if (serviceSelected.factoresViaje?.length > 0) {
      tieneFactores = true;
      let factorMotor = serviceSelected.factoresViaje.filter(factor => factor.esFactorMoto);
      if (factorMotor.length > 0) {
        factorMoto = true;
      }
      let factorExtranjero = serviceSelected.factoresViaje.filter((factor: { nombre: string; }) => factor.nombre === 'Extranjero');
      if (factorExtranjero.length > 0) {
        choferLicenciaOperativa = true;
      }
    }

    if (serviceSelected.modoCalcPrecio === 'AEROPUERTO'){
      esAeropuerto = true;
    }

    if(esAeropuerto || serviceSelected.conComprobante) {
      choferLicenciaOperativa = true;
    }
       

    if (factorMoto) {
      DCCopy += "*------ ReneMoto ------*" + "\n\n";
    }

    let tipoViaje = 'recorrido';

    if (serviceSelected.lugarOrigen && serviceSelected.lugarDestino && serviceSelected.lugarDestino !== '-' && serviceStopEntity && serviceStopEntity.length === 0) {
      tipoViaje = '';
    } else if (serviceSelected.lugarOrigen && serviceSelected.lugarDestino && serviceSelected.lugarDestino !== '-' && serviceStopEntity && serviceStopEntity.length > 0) {
      tipoViaje = 'recorrido';
    } else if(serviceSelected.lugarOrigen && (!serviceSelected.lugarDestino || serviceSelected.lugarDestino && serviceSelected.lugarDestino === '-')){
      tipoViaje = 'recorrido';
    }

    if (tipoViaje === 'recorrido') {
      DCCopy += "*RECORRIDO*" + "\n\n";
    }

    const fecha = "*Fecha:* ";
    const fechaHoy = "*Fecha:* (Hoy) ";
    const hora = "*Hora:* ";
    const link = 'https://maps.google.com/?q=';

    let decorator = '*';

    //let identificador = serviceSelected.choferIdentificador ? serviceSelected.choferIdentificador : selectedChoferPerson?.identificador;
    let identificador = (serviceSelected.choferIdentificador && serviceSelected.choferIdentificador != '-') ?
      serviceSelected.choferIdentificador : (selectedChoferPerson?.identificador && selectedChoferPerson?.identificador != '-' ? selectedChoferPerson?.identificador:
        (serviceSelected.chofer && serviceSelected.chofer.identificador && serviceSelected.chofer.identificador!= '-' ? serviceSelected.chofer.identificador: '-'));

    DCCopy +="*Id:* " + serviceSelected.codViaje;+"   "
    DCCopy +=" *Chofer:* " +  identificador + "\n";

    const fechaInicio = new Date(serviceSelected.fechaInicio);
    const fechaInicioFormateada = format(fechaInicio, 'dd/MM/yyyy');
    const horaInicioFormateada = format(fechaInicio, 'HH:mm');

    switch (copyType) {
      case TipoServicio.INMEDIATE.toString():
        DCCopy += fechaHoy;
        DCCopy += hora + horaInicioFormateada + "\n";
        break;
      case TipoServicio.RESERVE.toString():
        DCCopy += fecha;
        DCCopy += fechaInicioFormateada + " ";
        DCCopy += hora;
        DCCopy += horaInicioFormateada + "\n";
        break;
    }

    DCCopy +="*Pasajeros:* " + serviceSelected.cantPasajeros + "\n\n";
    DCCopy += "*Origen:* " + serviceSelected.lugarOrigen + "\n";

    if (serviceSelected.latitudOrigen && serviceSelected.longitudOrigen) {
      DCCopy += link + serviceSelected.latitudOrigen + ',' + serviceSelected.longitudOrigen + "\n";
    }

    if (serviceStopEntity!.length > 0) {
      // Ordenar el array utilizando la función de comparación personalizada
      serviceStopEntity!.sort(compararPorAtributoNoOrden);
      DCCopy += "\n";
      DCCopy += "*Paradas:*" + "\n";
      serviceStopEntity!.forEach(stop => {
        DCCopy += "*P" + stop.numOrden + "*: " + stop.lugar + "\n";
        if (stop.geoLatitud && stop.geoLongitud) {
          DCCopy += link + stop.geoLatitud + ',' + stop.geoLongitud + "\n";
        }
      });
      DCCopy += "\n";
    }

    if (serviceSelected.lugarDestino != null && serviceSelected.lugarDestino != '') {
      if (serviceStopEntity!.length === 0) {
        DCCopy += "\n";
      }
      DCCopy += "*Destino:* " + serviceSelected.lugarDestino + "\n";
      if (serviceSelected.latitudDestino && serviceSelected.longitudDestino) {
        DCCopy += link + serviceSelected.latitudDestino + ',' + serviceSelected.longitudDestino + "\n\n";
      } else {
        DCCopy += "\n";
      }
    }
    const details = '*Detalles del servicio:*';
    const px = '👨‍👩‍👧‍👦 Pasajeros: ' + serviceSelected.cantPasajeros;

    if(serviceSelected.cantPasajeros || serviceSelected.factoresViaje?.length > 0 || serviceSelected.conDescuento || choferLicenciaOperativa){
      DCCopy += details + "\n";
    }
    if(serviceSelected.cantPasajeros){
      DCCopy += px + "\n";
    }

    if (serviceSelected.factoresViaje?.length > 0) {
      serviceSelected.factoresViaje?.forEach(factor => {
        switch (factor.nombre) {
          case 'Horario Pico':
            DCCopy += "🕗 " + factor.nombre + "\n";
            break;
          case 'Madrugada':
            DCCopy += "🌃 " + factor.nombre + "\n";
            break;
          case 'Nocturno':
            DCCopy += "🏙️ " + factor.nombre + "\n";
            break;
          case 'Carga':
            DCCopy +="🚚 "+factor.nombre + "\n";
            break;
          case 'Aire':
            DCCopy +="❄️ "+factor.nombre + "\n";
            break;
          case 'Confort':
            DCCopy +="🚘 "+factor.nombre + "\n";
            break;
          case 'Equipaje':
            DCCopy +="🧳 "+factor.nombre + "\n";
            break;
          case 'Estandar':
            if(!factorMoto){
              DCCopy +="🚙 "+factor.nombre + "\n";
            }
            break;
          case 'Extranjero':
            DCCopy +="🌎 "+factor.nombre + "\n";
            break;
          case 'Mascotas':
            DCCopy +="🐶 "+factor.nombre + "\n";
            break;
          case 'Moderno':
            DCCopy +="🚘 "+factor.nombre + "\n";
            break;
          case 'Periferico':
            DCCopy +="🛣️ "+factor.nombre + "\n";
            break;
          case 'Provincia':
            DCCopy +="🛣️ "+factor.nombre + "\n";
            break;
          case 'Día festivo':
            DCCopy +="🎇 "+factor.nombre + "\n";
            break;
          case 'Moto estándar':
            DCCopy += "🛵 " + factor.nombre + "\n";
            break;
          case 'Moto combustión':
            DCCopy += "🛵 " + factor.nombre + "\n";
            break;
          case 'Moto eléctrica':
            DCCopy += "🛵 " + factor.nombre + "\n";
            break;
          case 'Almendron':
            DCCopy +="🚗 "+ factor.nombre + "\n";
            break;
          default:
            DCCopy +=" "+factor.nombre + "\n";
            break;
        }

      });
    }

    if (serviceSelected.conDescuento) {
      const oferta = "❗" + 'OFERTA CLIENTE' + "❗";
      DCCopy += oferta + "\n";
    }

    if (choferLicenciaOperativa) {
      const choferLic = "📋 " + 'Con Licencia Operativa';
      DCCopy += choferLic + "\n";
    }

    if (serviceSelected.modoCalcPrecio === 'AEROPUERTO') {

      let valueMinutosEspera;

      const appConfigEncoded = localStorage.getItem('appConfig');
      let config;
      if (appConfigEncoded) {
        config = JSON.parse(atob(appConfigEncoded));
      }

      if (config && config.length > 0) {
        valueMinutosEspera = config.find((element: { nombre: string; }) => element.nombre == "CANT_MINT_ESP_CLI_INCLUID_PLAY_AEROP");
      }

      const head = "\n" + 'El cliente cuenta con ' + valueMinutosEspera.valor + ' minutos de espera en el aeropuerto libre de costo, pasado este tiempo debe informar a la operadora';

      switch (serviceSelected.tipoTrayecto) {
        case 'VUELTA':
        case 'IDA_Y_VUELTA':
          DCCopy += head + "\n";
          break;
      }
    }

    DCCopy += "\n";

    const detailsModoPago = '*Modo de pago:*';

    if(serviceSelected.modoPagoDescCorta){
      DCCopy += detailsModoPago + "\n";
    } else {
      DCCopy += detailsModoPago + "\n";
      serviceSelected.modoPagoDescCorta = serviceSelected.modoPago.nombre.toUpperCase();
    }

    let mostrarPrecioExt = true;

    switch (serviceSelected.modoPagoDescCorta) {
      case 'TRANF_CHOFER':
        DCCopy += "🫰💳 Transferencia CUP Chofer \n";
        break;
      case 'TRANF_MLC_CHOFER':
          DCCopy += "🫰💳 Transferencia MLC Chofer \n";
          break;
      case 'TRANSFERENCIA MLC CHOFER':
        DCCopy += "🫰💳 Transferencia MLC Chofer \n";
        break;
      case 'TRANSFERENCIA CUP':
        DCCopy += "🫰💳 Transferencia CUP Chofer \n";
        break;
      case 'TRANSFERENCIA CUP CHOFER':
        DCCopy += "🫰💳 Transferencia CUP Chofer \n";
        break;        
      case 'TRANF_CUP_AGENCIA':
        DCCopy += "🫰💳 Transferencia a la Agencia (CUP)\n";
        break;
      case 'TRANSFERENCIA CUP AGENCIA':
        DCCopy += "🫰💳 Transferencia a la Agencia (CUP)\n";
        break;
      case 'EFECTIVO':
        switch (serviceSelected.monedaPago) {
          case 'USD':
            DCCopy +="🫰 Efectivo USD\n";
            break;
          case 'CUP':
            DCCopy +="🫰 Efectivo CUP\n";
            break;
          case 'EUR':
            DCCopy +="🫰 Efectivo EUR\n";
            break;
          case 'MLC':
            DCCopy +="🫰 Efectivo CUP\n";
            break;
        }
        break;
      case 'TRANF_AGENCIA':
        DCCopy += "🫰💳 Transferencia a la Agencia \n";
        break;
      case 'TRANSFERENCIA MLC':
        DCCopy += "🫰💳 Transferencia a la Agencia \n";
        break;
      case 'FONDO':
        DCCopy +="🫰 A CUENTA\n";
        break;
      case 'CHEQUE':
        DCCopy += "🫰 A CUENTA\n";
        break;
      case 'EXTERIOR':
        DCCopy += "🫰💳 Transferencia a la Agencia\n";
        break;  
      case 'ZELLE':
        DCCopy += "🫰💳 Transferencia a la Agencia\n";
        //mostrarPrecioExt = false;
        break;
      case 'PAYPAL':
        DCCopy += "🫰💳 Transferencia a la Agencia\n";
        //mostrarPrecioExt = false;
        break;
      case 'BIZUM':
        DCCopy += "🫰💳 Transferencia a la Agencia\n";
        //mostrarPrecioExt = false;
        break;
      case 'WISE':
        DCCopy += "🫰💳 Transferencia a la Agencia\n";
        //mostrarPrecioExt = false;
        break;
      case 'TROPIPAY':
        DCCopy += "🫰💳 Transferencia a la Agencia\n";
        //mostrarPrecioExt = false;
        break;
      case 'VISA':
        DCCopy += "🫰💳 Transferencia a la Agencia\n";
        //mostrarPrecioExt = false;
        break;
      case 'MASTERCARD':
        DCCopy += "🫰💳 Transferencia a la Agencia\n";
        //mostrarPrecioExt = false;
        break;
      default:
        DCCopy += "🫰 Efectivo" + "\n";
        break;
    }

    if (serviceSelected.incidenciaChofer != null && serviceSelected.incidenciaChofer != ''
    || serviceSelected.datosParaChofer != null && serviceSelected.datosParaChofer != ''
    || serviceSelected.detallesCliente != null && serviceSelected.detallesCliente != ''
    || serviceSelected.tiempoIncluido || serviceSelected.precioAumentado) {
      DCCopy += "\n" + "*Incidencia(s):* " + "\n";
    }

    if (serviceSelected.precioAumentado) {
      DCCopy += "📈 " + "Precio Aumentado" + "\n";
    }

    if (serviceSelected.incidenciaChofer != null && serviceSelected.incidenciaChofer != '') {
      DCCopy +=serviceSelected.incidenciaChofer + "\n";
    }

    if (serviceSelected.tiempoIncluido) {
      let tiempoIncluido = convertirMinutosAHoras(Number(serviceSelected.tiempoIncluido));
      DCCopy += "Incluye *"+  tiempoIncluido + "* de recorrido total, una vez culminado este tiempo informar a la operadora, ya que el mismo podría variar" + "\n";
    }

    if (serviceSelected.datosParaChofer != null && serviceSelected.datosParaChofer != '') {
      DCCopy +=serviceSelected.datosParaChofer + "\n";
    }

    if (serviceSelected.detallesCliente != null && serviceSelected.detallesCliente != ''){
      DCCopy +=serviceSelected.detallesCliente + "\n";
    }

    let codigoTelPais = '+53';

    if (selectedClientPerson && selectedClientPerson.telefCelPais) {
      codigoTelPais = '+' + selectedClientPerson.telefCelPais.codCel;
    } else if(serviceSelected.cliente && serviceSelected.cliente.telefCelPais){
      codigoTelPais = '+' + serviceSelected.cliente.telefCelPais.codCel;
    }

    DCCopy += "\n";
    DCCopy +="*Cliente:* " + nombreCliente + "\n";
    if(serviceSelected.telefonoConfirmacion){
      DCCopy += "*Teléfono a confirmar:* " + codigoTelPais + serviceSelected.telefonoConfirmacion + "\n";
    } else {
      if (telefonoCliente && telefonoCliente!= '-'){
        DCCopy += "*Movil:* " + codigoTelPais + telefonoCliente + "\n";
      }
      if (telefonoCasaCliente && telefonoCasaCliente!= '-'){
        DCCopy += "*Fijo:* " + telefonoCasaCliente + "\n";
      }
      if (telefonoWhatsappCliente && telefonoWhatsappCliente!= '-'){
        DCCopy += "*Whatsapp:* " + telefonoWhatsappCliente + "\n";
      }
    }

    DCCopy +="\n"
    DCCopy += "*Precio:* ";

    let isTransferExterior = this.isTransferExternPaymentMode(serviceSelected.modoPagoDescCorta);

    let isTransferMLCChofer = serviceSelected.modoPagoDescCorta === 'TRANF_MLC_CHOFER' || serviceSelected.modoPagoDescCorta === 'TRANSFERENCIA MLC CHOFER' ? true : false;
    let isTransferMLCAgencia = serviceSelected.modoPagoDescCorta === 'TRANF_AGENCIA' || serviceSelected.modoPagoDescCorta === 'TRANSFERENCIA MLC' ? true : false;
    let isTransferCUPAgencia = serviceSelected.modoPagoDescCorta === 'TRANF_CUP_AGENCIA' || serviceSelected.modoPagoDescCorta === 'TRANSFERENCIA CUP AGENCIA' ? true : false;

    if(serviceSelected.monedaPrecioEstimado !== 'CUP' && (serviceSelected.monedaPago === 'MLC' || serviceSelected.monedaPago === 'CUP')){

      if(serviceSelected.monedaPago === 'MLC' && isTransferMLCChofer){
        DCCopy += serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + " / ";
        DCCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
      } else if(serviceSelected.monedaPago === 'MLC' && isTransferMLCAgencia){
        DCCopy += serviceSelected.precioMonedaExtranjera + ' ' + decorator + serviceSelected.monedaPrecioEstimado + decorator + "\n"
      } else if(serviceSelected.monedaPago === 'CUP' && isTransferCUPAgencia){
        DCCopy += serviceSelected.precioMonedaExtranjera + ' ' + decorator + serviceSelected.monedaPrecioEstimado + decorator + "\n"
      } else
        DCCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";

    } else if(serviceSelected.monedaPrecioEstimado !== 'CUP' && serviceSelected.monedaPrecioEstimado === serviceSelected.monedaPago){
        
      DCCopy += serviceSelected.precioMonedaExtranjera + ' ' + decorator + serviceSelected.monedaPrecioEstimado + decorator + "\n";       

    } else if(serviceSelected.monedaPrecioEstimado !== 'CUP' && serviceSelected.monedaPrecioEstimado !== serviceSelected.monedaPago){

      if (serviceSelected.monedaPago == TipoMoneda.EUR.toString()) {
        if (isTransferExterior) {
          DCCopy += serviceSelected.precioMonedaExtranjera + ' ' + decorator + serviceSelected.monedaPrecioEstimado + decorator + "\n";
        } else
          DCCopy += serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";
      } else if (serviceSelected.monedaPago == TipoMoneda.USD.toString()) {
        if (isTransferExterior) {
          DCCopy += serviceSelected.precioMonedaExtranjera + ' ' + decorator + serviceSelected.monedaPrecioEstimado + decorator + "\n";
        } else
          DCCopy += serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";
      }

    } else {

      if (isTransferExterior) {
        DCCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
      } else if (serviceSelected.monedaPago == TipoMoneda.EUR.toString()) {
        DCCopy += serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + " / "
        DCCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
      } else if (serviceSelected.monedaPago == TipoMoneda.USD.toString()) {
        DCCopy += serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + " / "
        DCCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
      } else {
        if (serviceSelected.monedaPago === 'MLC' && isTransferMLCChofer) {
          DCCopy += serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + " / ";
          DCCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
        } else {
          DCCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
        }
      }

    }


    if (serviceSelected.totalKm) {
      DCCopy += "*Km:* " + serviceSelected.totalKm + "\n\n";
    } else
      DCCopy += "*Km:*  - \n\n";

    if(serviceSelected.comision && serviceSelected.comision.descCorta)
      tipoComision = serviceSelected.comision.descCorta;

    if (tipoComision) {
      if (tipoComision === 'COMISION_AGENCIA') {
        if (serviceSelected.monedaComisionAgencia !== 'CUP') {
          valorComision = "*Comisión:* " + serviceSelected.importeComisionAgenciaMonedaExt + " *" + serviceSelected.monedaComisionAgencia + "*";
        } else
          valorComision = "*Comisión:* " + serviceSelected.importeComisionAgencia + " *CUP*";
      } else if (tipoComision === 'COMISION_CLIENTE') {
        if (serviceSelected.monedaComisionCliente !== 'CUP') {
          valorComision = "*Comisión:* " + serviceSelected.importeComisionClienteMonedaExt + " *" + serviceSelected.monedaComisionCliente + "*";
        } else
          valorComision = "*Comisión:* " + serviceSelected.importeComisionCliente + " *CUP*";
      } else if (tipoComision === 'COMISION_AGENCIA_CLIENTE') {
        if(serviceSelected.monedaComisionCliente === 'CUP' && serviceSelected.monedaComisionAgencia === 'CUP'){
          let suma = Number(serviceSelected.importeComisionAgencia) + Number(serviceSelected.importeComisionCliente);
          valorComision = "*Comisión:* " + suma + " *CUP*";
        } else {
          let suma = Number(serviceSelected.importeComisionAgenciaMonedaExt) + Number(serviceSelected.importeComisionClienteMonedaExt);
          valorComision = "*Comisión:* "  + suma + " *" + serviceSelected.monedaComisionCliente + "*";
        }
      }
    }

    if (valorComision) {
      DCCopy += valorComision + "\n\n";
    }

    // console.log('=>', veryCriticalNotice, criticalNotice, fundNotice);

    if(variableConfigList && variableConfigList.length>0){

      if (variableConfigList[0] && variableConfigList[0].valor === 'true') {

        if (serviceSelected.estadoFinalEstimado != '-') {
          if (serviceSelected.estadoFinalEstimado != 'NORMAL') {
            DCCopy += "*Aviso Fondo:* " + serviceSelected.estadoFinalEstimado + "\n";
          }
        } else {
          let saldo = 0;

          let estadoFinalEstimado;

          if (serviceSelected.saldoFinalEstimado != 0) {
            saldo = serviceSelected.saldoFinalEstimado;
          } else if (selectedChoferPerson?.cuenta) {
            saldo = selectedChoferPerson.cuenta.saldo;
            if (selectedChoferPerson?.cuenta.bonificacion)
             saldo = saldo + selectedChoferPerson?.cuenta.bonificacion;
          } else if (serviceSelected.chofer.cuenta) {
            saldo = serviceSelected.chofer.cuenta.saldo;
            if (serviceSelected.chofer.cuenta.bonificacion)
             saldo = saldo + serviceSelected.chofer.cuenta.bonificacion;
          }

          if (saldo <= veryCriticLimit.valor) {
            estadoFinalEstimado = 'MUY_CRITICO';
          } else if (saldo <= criticLimit.valor) {
            estadoFinalEstimado = 'CRITICO';
          } else if (saldo <= warningLimit.valor) {
            estadoFinalEstimado = 'ALERTA';
          } else estadoFinalEstimado = 'NORMAL';

          if (estadoFinalEstimado !== 'NORMAL') {
            DCCopy += "*Aviso Fondo:* " + estadoFinalEstimado + "\n";
          }
        }


        if (serviceSelected.saldoFinalEstimado != 0) {
          DCCopy += "*Fondo:* " + formatSaldo(serviceSelected.saldoFinalEstimado) +  "\n\n";
        } else if (selectedChoferPerson?.cuenta){
          DCCopy += "*Fondo:* " + formatSaldo(selectedChoferPerson.cuenta.saldo + selectedChoferPerson.cuenta.bonificacion) +  "\n\n";
        } else if (serviceSelected.chofer.cuenta){
          if (serviceSelected.modoPagoDescCorta
            && (serviceSelected.modoPagoDescCorta === 'VISA' || serviceSelected.modoPagoDescCorta === 'BIZUM' || serviceSelected.modoPagoDescCorta === 'TRANF_AGENCIA' ||
            serviceSelected.modoPagoDescCorta === 'CHEQUE' || serviceSelected.modoPagoDescCorta === 'FONDO' ||
            serviceSelected.modoPagoDescCorta === 'MASTERCARD' || serviceSelected.modoPagoDescCorta === 'PAYPAL' || serviceSelected.modoPagoDescCorta === 'TROPIPAY' ||
            serviceSelected.modoPagoDescCorta === 'ZELLE' || serviceSelected.modoPagoDescCorta === 'EXTERIOR' || serviceSelected.modoPagoDescCorta === 'TRANF_CUP_AGENCIA' || serviceSelected.modoPagoDescCorta === 'WISE')
            && serviceSelected.esInmediato
            && serviceSelected.precioEstimChofer) {
              serviceSelected.chofer.cuenta.saldo = serviceSelected.chofer.cuenta.saldo - serviceSelected.precioEstimChofer;
          }
          DCCopy += "*Fondo:* " + formatSaldo(serviceSelected.chofer.cuenta.saldo + serviceSelected.chofer.cuenta.bonificacion) +  "\n\n";
        }

      }

    }

    DCCopy+="(US-"+this.user+")"+ "\n";

    this.clipboard.copy(DCCopy);
    /*this.notificacionService.notificationSuccess(
      'Copiado al portapapeles.'
    );*/

    /** Insertando traza */
    let detail = this.traceDC;
    this.tracesService.saveTrace('datos_chofer', 'Servicios en procesos', detail, undefined, serviceSelected.codViaje);
  }

  buildICCopy(copyType: string, serviceSelected: ServiceEntity, serviceStopEntity?: ServiceStopEntity[], selectedClientPerson?: PersonEntity | undefined) {
    let ICCopy: string = '';

    if(serviceSelected.clienteNombreApel){
      serviceSelected.clienteNombres = serviceSelected.clienteNombreApel ? serviceSelected.clienteNombreApel : '-';
      serviceSelected.clienteNombres = serviceSelected.clienteNombreApel.split(' ')[0];
    }

    if (serviceSelected.cliente && serviceSelected.cliente.nombres) {
      serviceSelected.clienteNombres = serviceSelected.cliente.nombres.split(' ')[0];
    } else if(selectedClientPerson && selectedClientPerson.nombres){
      serviceSelected.clienteNombres = selectedClientPerson.nombres.split(' ')[0];
    }

    serviceSelected.codViaje = serviceSelected.codViaje ? serviceSelected.codViaje : '-';

    const nombre = "Estimado(a): ";
    const servicio = "Su servicio en espera es ID: ";
    const mensaje = "Reenvie este código para confirmarlo o cancelarlo.";
    const saludos = "Muchas Gracias por elegirnos.";

    let factorMoto = false;

    if (serviceSelected.factoresViaje?.length > 0) {
      let factorMotor = serviceSelected.factoresViaje.filter(factor => factor.esFactorMoto);
      if (factorMotor.length > 0) {
        factorMoto = true;
      }
    }

    if (factorMoto) {
      ICCopy += "*------ ReneMoto ------*" + "\n\n";
    }

    //if (serviceSelected.estadoId == 2) {
      ICCopy += nombre + serviceSelected.clienteNombres + "\n";
      ICCopy += servicio + serviceSelected.codViaje + "\n";
      ICCopy += mensaje + "\n";
      ICCopy += saludos;
    /*} else if (serviceSelected.estadoId == 1 || serviceSelected.estadoId == 3) {
      ICCopy += nombre + serviceSelected.clienteNombres + "\n";
      ICCopy += servicioRecepcion + serviceSelected.codViaje + mensajeRecepción;
    }*/

    ICCopy += "\n";
    ICCopy+="(US-"+this.user+")"+ "\n";

    this.clipboard.copy(ICCopy);
    /*this.notificacionService.notificationSuccess(
      'Copiado al portapapeles.'
    );*/
  }

  buildPriceCopy(copyAction: string, serviceSelected: ServiceEntity, listStops: ServiceStopEntity[], selectedClientPerson: PersonEntity | undefined){
    let priceCopy: string = '';
    let tiempoIncluido = '';
    let precioPorMinuto = 0;
    let tipoViaje = 'recorrido';
    let decorator = '*';
    let decoratorSubrayado = '~';

    let isbirthday = false;

    if(selectedClientPerson && selectedClientPerson.fechaNacimiento){
      let fechaInicio = new Date(serviceSelected.fechaInicio);
      let birthday = this.datepipe.transform(selectedClientPerson.fechaNacimiento, 'yyyy/MM/dd HH:mm:ss')
      if (birthday) {
        let birthdayDate: Date = new Date(birthday);
        if (this.isBirthday(fechaInicio, birthdayDate)) {
          isbirthday = true;
        }
      }          
    }

    serviceSelected.totalKm = serviceSelected.totalKm ? serviceSelected.totalKm : 0;
    serviceSelected.cantPasajeros = serviceSelected.cantPasajeros ? serviceSelected.cantPasajeros : 0;
    serviceSelected.precioEstimChofer = serviceSelected.precioEstimChofer ? serviceSelected.precioEstimChofer : 0;
    serviceSelected.precioEstimMlc = serviceSelected.precioEstimMlc ? serviceSelected.precioEstimMlc : 0;
    serviceSelected.monedaPago = serviceSelected.monedaPago == null || serviceSelected.monedaPago == TipoMoneda.Otra.toString() /*|| serviceSelected.monedaPago == TipoMoneda.CUP.toString()*/ ? 'MLC' : serviceSelected.monedaPago;

    if (copyAction === TipoServicio.P_W.toString()){
      decorator = '*';
      decoratorSubrayado = '~';
    } else if (copyAction === TipoServicio.P_T.toString()){
      decorator = '**';
      decoratorSubrayado = '~~';
    }

    if (serviceSelected.tiempoIncluido){
      tiempoIncluido = convertirMinutosAHoras(Number(serviceSelected.tiempoIncluido));
    }

    if (serviceSelected.precioPorMinuto){
      precioPorMinuto = Number(serviceSelected.precioPorMinuto.toFixed(2));
    }

    if (serviceSelected.lugarOrigen && serviceSelected.lugarDestino && listStops.length === 0) {
      tipoViaje = '';
    } else if (serviceSelected.lugarOrigen && serviceSelected.lugarDestino && listStops.length > 0) {
      tipoViaje = 'recorrido';
    }

    let factorMoto = false;

    let tieneFactores = false;

    if (serviceSelected.factoresViaje?.length > 0) {
      tieneFactores = true;
      let factorMotor = serviceSelected.factoresViaje.filter(factor => factor.esFactorMoto);
      if (factorMotor.length > 0) {
        factorMoto = true;
      }
    }

    if (factorMoto) {
      priceCopy += "*------ ReneMoto ------*" + "\n\n";
    }

    const head = 'El precio a pagar por su servicio ' + tipoViaje + ' de ' + decorator + serviceSelected.totalKm + ' Km' + decorator +' es:';
    const details = decorator + 'Detalles del servicio:' + decorator ;
    const px = '👨‍👩‍👧‍👦 Pasajeros: ' + serviceSelected.cantPasajeros;
    const timeService = 'En este servicio usted puede usar ' + decorator +  tiempoIncluido + decorator + ' el auto sin modificar el trayecto.';
    const surCharge = 'Pasado este tiempo se cobrará un recargo de ' + decorator + precioPorMinuto + ' CUP' + decorator + ' por minuto.';
    const warn = 'Si modifica el trayecto, tanto el precio como el tiempo serán modificados.'

    if(serviceSelected.conDescuento && !isbirthday){
      const descuento = '‼️Hemos descontando un '+ serviceSelected.conDescuento + '% a su tarifa.‼️'
      priceCopy += descuento + "\n\n";
    }

    if(isbirthday && serviceSelected.modoCalcPrecio !== 'AEROPUERTO'){
      const descuento = '‼️ Feliz cumpleaños, hoy tiene un descuento en su tarifa de '+ Number(serviceSelected.discountBirth) +'% más la oferta del día. ‼️'
      priceCopy += descuento + "\n\n";
    }

    if(serviceSelected.conModificacion){
      const modifDesvioSinKm = 'Su nuevo precio producto a un desvío en la ruta respecto al servicio inicial es:';
      const modifDesvio = 'Su nuevo precio producto a un desvío de ' + serviceSelected.totalKmModificado + ' km del servicio inicial es:';
      if(serviceSelected.totalKmModificado){
        priceCopy += modifDesvio + "\n";
      } else
        priceCopy += modifDesvioSinKm + "\n";
    } else if(serviceSelected.minutosEspera){
        const modifEspera = 'Su nuevo precio debido a ' + serviceSelected.minutosEspera + ' min de espera en el origen es:';
        priceCopy += modifEspera + "\n";
    } if (serviceSelected.precioPorTmpo && serviceSelected.fechaFin) {
      const minutos = convertirAMinutos(serviceSelected.duracion);
      //let tiempoExcedido = (minutos - Number(serviceSelected.tiempoIncluido)).toFixed(0);
      let tiempo = Number(minutos).toFixed(0);
      let duracionServicio = convertirMinutosAHoras(Number(tiempo));
      priceCopy += 'El nuevo precio a pagar por su servicio debido a ' + duracionServicio +  ' de duración es:' + "\n";
    } else
      priceCopy += head + "\n";

    if (serviceSelected.modoPago) {
      serviceSelected.modoPagoDescCorta = serviceSelected.modoPago.nombre.toUpperCase();
    }

    //priceCopy += serviceSelected.precioEstimChofer +' '+ decorator + 'CUP' + decorator + "\n";
    //priceCopy += serviceSelected.precioEstimMlc +' '+ decorator + serviceSelected.monedaPago + decorator + "\n";

    let isTransferMLCChofer = serviceSelected.modoPagoDescCorta === 'TRANF_MLC_CHOFER' || serviceSelected.modoPagoDescCorta === 'TRANSFERENCIA MLC CHOFER' ? true : false;

    if(serviceSelected.monedaPrecioEstimado !== 'CUP' && (serviceSelected.monedaPago === 'MLC' || serviceSelected.monedaPago === 'CUP')){

      if(isTransferMLCChofer){
        priceCopy += serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + " / ";
        priceCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
      } else if(serviceSelected.monedaPago === 'MLC'){
        priceCopy += serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + "\n";
      } else {
        priceCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
      }

    } else if(serviceSelected.monedaPrecioEstimado !== 'CUP' && serviceSelected.monedaPrecioEstimado === serviceSelected.monedaPago){

      priceCopy += serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";

    } else if(serviceSelected.monedaPrecioEstimado !== 'CUP' && serviceSelected.monedaPrecioEstimado !== serviceSelected.monedaPago){

      if (serviceSelected.monedaPago == TipoMoneda.EUR.toString() || serviceSelected.monedaPago == TipoMoneda.USD.toString()) {
        priceCopy += serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";
      } 

    } else {

      if (serviceSelected.monedaPago == TipoMoneda.EUR.toString()) {

        if(serviceSelected.modoPagoDescCorta && serviceSelected.modoPagoDescCorta === 'EFECTIVO'){
          priceCopy += serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + " / ";
          priceCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
        } else {
          if (serviceSelected.conDescuento && serviceSelected.descuentoMlc) {
            let precioInicial = serviceSelected.precioEstimMlc + Number(serviceSelected.descuentoMlc);
            priceCopy += decoratorSubrayado + precioInicial.toFixed(2) + decoratorSubrayado + ' ' + decorator + serviceSelected.precioEstimMlc + decorator +' '+ decorator + serviceSelected.monedaPago + decorator + "\n";
          } else
            priceCopy += serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";
        }

        /*if (serviceSelected.conDescuento && serviceSelected.descuento) {
          let precioInicial = serviceSelected.precioEstimChofer + Number(serviceSelected.descuento);
          priceCopy += decoratorSubrayado + precioInicial + decoratorSubrayado + ' ' + decorator + serviceSelected.precioEstimChofer + decorator +' '+ decorator + 'CUP' + decorator + "\n";
        } else
          priceCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";*/

      } else if (serviceSelected.monedaPago == TipoMoneda.USD.toString()) {

        if(serviceSelected.modoPagoDescCorta && serviceSelected.modoPagoDescCorta === 'EFECTIVO'){
          priceCopy += serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + " / ";
          priceCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
        } else {
          if (serviceSelected.conDescuento && serviceSelected.descuentoMlc) {
            let precioInicial = serviceSelected.precioEstimMlc + Number(serviceSelected.descuentoMlc);
            priceCopy += decoratorSubrayado + precioInicial.toFixed(2) + decoratorSubrayado + ' ' + decorator + serviceSelected.precioEstimMlc + decorator +' '+ decorator + serviceSelected.monedaPago + decorator + "\n";
          } else
            priceCopy += serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";
        }

        /*if (serviceSelected.conDescuento && serviceSelected.descuento) {
          let precioInicial = serviceSelected.precioEstimChofer + Number(serviceSelected.descuento);
          priceCopy += decoratorSubrayado + precioInicial + decoratorSubrayado + ' ' + decorator + serviceSelected.precioEstimChofer + decorator +' '+ decorator + 'CUP' + decorator + "\n";
        } else
          priceCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";*/

      } else {

        if(isTransferMLCChofer){
          if (serviceSelected.conDescuento && serviceSelected.descuentoMlc) {
            let precioInicial = serviceSelected.precioEstimMlc + Number(serviceSelected.descuentoMlc);
            priceCopy += decoratorSubrayado + precioInicial.toFixed(2) + decoratorSubrayado + ' ' + decorator + serviceSelected.precioEstimMlc + decorator + ' ' + decorator + 'MLC' + decorator + " / ";
            let precioInicialCup = Number(serviceSelected.precioEstimChofer) + Number(serviceSelected.descuento);
            priceCopy += decoratorSubrayado + precioInicialCup + decoratorSubrayado + ' ' + decorator + serviceSelected.precioEstimChofer + decorator + ' ' + decorator + 'CUP' + decorator + "\n";
          } else {
            priceCopy += serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + " / ";
            priceCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
          } 
        } else if(serviceSelected.monedaPago === 'MLC'){
          if (serviceSelected.conDescuento && serviceSelected.descuentoMlc) {
            let precioInicial = serviceSelected.precioEstimMlc + Number(serviceSelected.descuentoMlc);
            priceCopy += decoratorSubrayado + precioInicial.toFixed(2) + decoratorSubrayado + ' ' + decorator + serviceSelected.precioEstimMlc + decorator + ' ' + decorator + 'MLC' + decorator + "\n";
          } else {
            priceCopy += serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + "\n";
          }            
        } else {
          if (serviceSelected.conDescuento && serviceSelected.descuento) {
            let precioInicial = Number(serviceSelected.precioEstimChofer) + Number(serviceSelected.descuento);
            priceCopy += decoratorSubrayado + precioInicial + decoratorSubrayado + ' ' + decorator + serviceSelected.precioEstimChofer + decorator + ' ' + decorator + 'CUP' + decorator + "\n";
          } else
            priceCopy += serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";
        }

      }

    }

    //Estado del fondo del cliente

    if (selectedClientPerson != null && selectedClientPerson.cuenta != null && (selectedClientPerson.cuenta.saldo + selectedClientPerson.cuenta.bonificacion) > 0) {
      priceCopy += "\n" + 'Fondo: $' + formatSaldo(selectedClientPerson.cuenta.saldo + selectedClientPerson.cuenta.bonificacion) + "\n";
    }

    priceCopy += "\n" + details + "\n";
    priceCopy += px + "\n";

    if (tieneFactores) {
      serviceSelected.factoresViaje?.forEach(factor => {
        switch (factor.nombre) {
          case 'Horario Pico':
            priceCopy += "🕗 " + factor.nombre + "\n";
            break;
          case 'Madrugada':
            priceCopy += "🌃 " + factor.nombre + "\n";
            break;
          case 'Nocturno':
            priceCopy += "🏙️ " + factor.nombre + "\n";
            break;
          case 'Carga':
            priceCopy +="🚚 "+factor.nombre + "\n";
            break;
          case 'Aire':
            priceCopy +="❄️ "+factor.nombre + "\n";
            break;
          case 'Confort':
            priceCopy +="🚘 "+factor.nombre + "\n";
            break;
          case 'Equipaje':
            priceCopy +="🧳 "+factor.nombre + "\n";
            break;
          case 'Estandar':
            if(!factorMoto){
              priceCopy +="🚙 "+factor.nombre + "\n";
            }
            break;
          case 'Extranjero':
            priceCopy +="🌎 "+factor.nombre + "\n";
            break;
          case 'Mascotas':
            priceCopy +="🐶 "+factor.nombre + "\n";
            break;
          case 'Moderno':
            priceCopy +="🚘 "+factor.nombre + "\n";
            break;
          case 'Día festivo':
            priceCopy +="🎇 "+factor.nombre + "\n";
            break;
          case 'Moto estándar':
            priceCopy += "🛵 " + factor.nombre + "\n";
            break;
          case 'Moto combustión':
            priceCopy += "🛵 " + factor.nombre + "\n";
            break;
          case 'Moto eléctrica':
            priceCopy += "🛵 " + factor.nombre + "\n";
            break;
          case 'Almendron':
            priceCopy +="🚗 "+ factor.nombre + "\n";
            break;
          case 'Provincia':
            priceCopy +="🛣️ "+factor.nombre + "\n";
            break;
          /*default:
            priceCopy +=" "+factor.nombre + "\n";
            break;*/
        }

      });

      if (serviceSelected.valorPeriferico) {
        priceCopy +="🛣️ "+ "Servicio periférico" + "\n";
      }

      priceCopy += "\n";
    }

    if (tipoViaje === 'recorrido' && !serviceSelected.precioPorTmpo){
      if (serviceSelected.tiempoIncluido && precioPorMinuto) {
        priceCopy += "*Incidencia(s):* " + "\n";
        priceCopy += timeService + "\n";
        priceCopy += surCharge + "\n";
        priceCopy += warn + "\n";
        priceCopy += "\n";
      }
    }

    priceCopy+="(US-"+this.user+")"+ "\n";

    this.clipboard.copy(priceCopy);
    /*this.notificacionService.notificationSuccess(
      'Copiado al portapapeles.'
    );*/
  }

  buildCopyAirport(copyAction: string, serviceSelected: ServiceEntity){
    let priceCopy: string = '';
    let precioPorMinuto = 0;
    let decorator = '*';
    let decoratorPleca = '- ';

    serviceSelected.cantPasajeros = serviceSelected.cantPasajeros ? serviceSelected.cantPasajeros : 0;
    serviceSelected.precioEstimChofer = serviceSelected.precioEstimChofer ? serviceSelected.precioEstimChofer : 0;
    serviceSelected.precioEstimMlc = serviceSelected.precioEstimMlc ? serviceSelected.precioEstimMlc : 0;
    serviceSelected.monedaPago = serviceSelected.monedaPago == null || serviceSelected.monedaPago == TipoMoneda.Otra.toString() ? 'MLC' : serviceSelected.monedaPago;

    if (copyAction === TipoServicio.P_W.toString()){
      decorator = '*';
    } else if (copyAction === TipoServicio.P_T.toString()){
      decorator = '**';
    }

    if (serviceSelected.precioPorMinuto){
      precioPorMinuto = Number(serviceSelected.precioPorMinuto.toFixed(2));
    }

    let factorExtranjero = false;

    if (serviceSelected.factoresViaje?.length > 0) {
      let factor = serviceSelected.factoresViaje.filter((factor: { nombre: string; }) => factor.nombre === 'Extranjero');
      if (factor.length > 0) {
        factorExtranjero = true;
      }
    }

    let valueMinutosEspera;
    let valueUsd;
    let valueCup;

    const appConfigEncoded = localStorage.getItem('appConfig');
    let config;
    if (appConfigEncoded) {
      config = JSON.parse(atob(appConfigEncoded));
    }
    if (config && config.length > 0) {
      valueMinutosEspera = config.find((element: { nombre: string; }) => element.nombre == "CANT_MINT_ESP_CLI_INCLUID_PLAY_AEROP");
    }
    if (config && config.length > 0) {
      valueUsd = config.find((element: { nombre: string; }) => element.nombre == "PRECIO_X_MIN_ESPERA_AEROP_EXT");
    }
    if (config && config.length > 0) {
      valueCup = config.find((element: { nombre: string; }) => element.nombre == "PRECIO_X_MIN_ESPERA_AEROP_CUB");
    }    

    const headIda = 'El precio de su servicio de aeropuerto es:';
    const headRetorno = 'El precio de su servicio de aeropuerto incluyendo ' + valueMinutosEspera.valor + ' minutos de espera es:';
    const details = decorator + 'Detalles del servicio:' + decorator ;
    const charge = 'Pasados los ' + valueMinutosEspera.valor + ' minutos se le cobrará un recargo de ' + valueUsd.valor + ' USD ' + decorator + '(' + valueCup.valor + ' CUP)' + decorator + ' por cada minuto extra.';

    switch (serviceSelected.tipoTrayecto) {
      case 'IDA':
        priceCopy += decorator+'------' + 'IDA' + '------' + decorator + "\n\n";
        break;
      case 'VUELTA':
        priceCopy += decorator+'------' + 'RECOGIDA' + '------' + decorator + "\n\n";
        break;
      case 'IDA_Y_VUELTA':
        priceCopy += decorator+'------' + 'IDA y VUELTA' + '------' + decorator + "\n\n";
        break;
    }

    if(serviceSelected.tipoTrayecto === 'IDA'){
      priceCopy += headIda + "\n";
    } else
      priceCopy += headRetorno + "\n";

    priceCopy += decoratorPleca + serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";

    if(serviceSelected.monedaPrecioEstimado !== 'CUP' && (serviceSelected.monedaPago === 'MLC' || serviceSelected.monedaPago === 'CUP')){
      priceCopy += decoratorPleca + serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + "\n";
    } else if(serviceSelected.monedaPrecioEstimado !== 'CUP' && serviceSelected.monedaPrecioEstimado === serviceSelected.monedaPago){
      priceCopy += decoratorPleca + serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";
    } else if(serviceSelected.monedaPrecioEstimado !== 'CUP' && serviceSelected.monedaPrecioEstimado !== serviceSelected.monedaPago){
      if (serviceSelected.monedaPago == TipoMoneda.EUR.toString() || serviceSelected.monedaPago == TipoMoneda.USD.toString()) {
        priceCopy += decoratorPleca + serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";
      }
    } else {

      if (serviceSelected.monedaPago == TipoMoneda.EUR.toString() || serviceSelected.monedaPago == TipoMoneda.USD.toString()) {
        priceCopy += decoratorPleca + serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";
      } else {
         priceCopy += decoratorPleca + serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + "\n";
      }

    }

    priceCopy += "\n" + details + "\n";

    switch (serviceSelected.tipoTrayecto) {
      case 'IDA':
        priceCopy += "⬆️ " + 'Ida' + "\n";
        break;
      case 'VUELTA':
        priceCopy += "⬇️ " + 'Recogida' + "\n";
        break;
      case 'IDA_Y_VUELTA':
        priceCopy += "🔃 " + 'Ida y Vuelta' + "\n";
        break;
    }

    if(factorExtranjero){
      priceCopy += "🌎 " + 'Extranjero' + "\n";
    } else 
      priceCopy += "🇨🇺 " + 'Cubano' + "\n";

    priceCopy += "👨‍👩‍👧‍👦 " + 'Hasta 4 pasajeros' + "\n\n";

    if(serviceSelected.tipoTrayecto !== 'IDA'){
      priceCopy += charge + "\n\n";
    } 
    
    priceCopy+="(US-"+this.user+")"+ "\n";

    this.clipboard.copy(priceCopy);
  }

  buildCopyBeach(copyAction: string, serviceSelected: ServiceEntity){
    let priceCopy: string = '';
    let precioPorMinuto = 0;
    let decorator = '*';
    let decoratorPleca = '- ';

    serviceSelected.cantPasajeros = serviceSelected.cantPasajeros ? serviceSelected.cantPasajeros : 0;
    serviceSelected.precioEstimChofer = serviceSelected.precioEstimChofer ? serviceSelected.precioEstimChofer : 0;
    serviceSelected.precioEstimMlc = serviceSelected.precioEstimMlc ? serviceSelected.precioEstimMlc : 0;
    serviceSelected.monedaPago = serviceSelected.monedaPago == null || serviceSelected.monedaPago == TipoMoneda.Otra.toString() ? 'MLC' : serviceSelected.monedaPago;

    if (copyAction === TipoServicio.P_W.toString()){
      decorator = '*';
    } else if (copyAction === TipoServicio.P_T.toString()){
      decorator = '**';
    }

    if (serviceSelected.precioPorMinuto){
      precioPorMinuto = Number(serviceSelected.precioPorMinuto.toFixed(2));
    }

    let head = 'El precio de su servicio a la playa es:';

    if(serviceSelected.marcoPlayaAeropDc){
      head = 'El precio de su servicio a la playa ' + serviceSelected.marcoPlayaAeropDc + ' es:';
    }

    const details = decorator + 'Detalles del servicio:' + decorator ;

    switch (serviceSelected.tipoTrayecto) {
      case 'IDA':
        priceCopy += decorator+'------' + 'IDA' + '------' + decorator + "\n\n";
        break;
      case 'VUELTA':
        priceCopy += decorator+'------' + 'RECOGIDA' + '------' + decorator + "\n\n";
        break;
      case 'IDA_Y_VUELTA':
        priceCopy += decorator+'------' + 'IDA y VUELTA' + '------' + decorator + "\n\n";
        break;
    }

    priceCopy += head + "\n";

    priceCopy += decoratorPleca + serviceSelected.precioEstimChofer + ' ' + decorator + 'CUP' + decorator + "\n";

    if(serviceSelected.monedaPrecioEstimado !== 'CUP' && (serviceSelected.monedaPago === 'MLC' || serviceSelected.monedaPago === 'CUP')){
      priceCopy += decoratorPleca + serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + "\n";
    } else if(serviceSelected.monedaPrecioEstimado !== 'CUP' && serviceSelected.monedaPrecioEstimado === serviceSelected.monedaPago){
      priceCopy += decoratorPleca + serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";
    } else if(serviceSelected.monedaPrecioEstimado !== 'CUP' && serviceSelected.monedaPrecioEstimado !== serviceSelected.monedaPago){
      if (serviceSelected.monedaPago == TipoMoneda.EUR.toString() || serviceSelected.monedaPago == TipoMoneda.USD.toString()) {
        priceCopy += decoratorPleca + serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";
      }
    } else {

      if (serviceSelected.monedaPago == TipoMoneda.EUR.toString() || serviceSelected.monedaPago == TipoMoneda.USD.toString()) {
        priceCopy += decoratorPleca + serviceSelected.precioEstimMlc + ' ' + decorator + serviceSelected.monedaPago + decorator + "\n";
      } else {
         priceCopy += decoratorPleca + serviceSelected.precioEstimMlc + ' ' + decorator + 'MLC' + decorator + "\n";
      }

    }

    priceCopy += "\n" + details + "\n";

    switch (serviceSelected.tipoTrayecto) {
      case 'IDA':
        priceCopy += "⬆️ " + 'Ida' + "\n";
        break;
      case 'VUELTA':
        priceCopy += "⬇️ " + 'Recogida' + "\n";
        break;
      case 'IDA_Y_VUELTA':
        priceCopy += "🔃 " + 'Ida y Vuelta' + "\n";
        break;
    }

    priceCopy += "👨‍👩‍👧‍👦 " + 'Hasta 4 pasajeros' + "\n\n"; 
    
    priceCopy+="(US-"+this.user+")"+ "\n";

    this.clipboard.copy(priceCopy);
  }

  isBirthday(dateToCheck: Date, birthday: Date): boolean {
    return birthday.getMonth() === dateToCheck.getMonth() &&
           birthday.getDate() === dateToCheck.getDate();
  }

  buildPriceTimeCopy(serviceSelected: ServiceEntity, listStops: ServiceStopEntity[], selectedClientPerson: PersonEntity | undefined){
    let priceCopy: string = '';
    let precioPorMinuto = 0;
    let decorator = '*';

    serviceSelected.totalKm = serviceSelected.totalKm ? serviceSelected.totalKm : 0;
    serviceSelected.cantPasajeros = serviceSelected.cantPasajeros ? serviceSelected.cantPasajeros : 0;

    if (serviceSelected.precioPorMinuto){
      precioPorMinuto = Number(serviceSelected.precioPorMinuto.toFixed(2));
    }

    let factorMoto = false;

    let tieneFactores = false;

    if (serviceSelected.factoresViaje?.length > 0) {
      tieneFactores = true;
      let factorMotor = serviceSelected.factoresViaje.filter(factor => factor.esFactorMoto);
      if (factorMotor.length > 0) {
        factorMoto = true;
      }
    }

    if (factorMoto) {
      priceCopy += "*------ ReneMoto ------*" + "\n\n";
    }

    const minutos = convertirAMinutos(serviceSelected.duracion);
    let tiempo = Number(minutos).toFixed(0);
    let duracionServicio = convertirMinutosAHoras(Number(tiempo));

    let precio = precioPorMinuto * Number(tiempo);

    const precioKmMayor = serviceSelected.desglosePrecioPorKm.reduce((maxPrecio, item) => item.precioPorKm > maxPrecio ? item.precioPorKm : maxPrecio, serviceSelected.desglosePrecioPorKm[0].precioPorKm);

    let totalKm = precio / precioKmMayor;

    priceCopy += 'El nuevo precio a pagar por su servicio de ' + duracionServicio + ' de duración es:' + "\n";

    priceCopy += precio.toFixed(0) + ' ' + decorator + 'CUP' + decorator + "\n\n";

    priceCopy += 'Este precio le incluye ' + decorator + totalKm.toFixed(2) + ' Km' + decorator + ' total, ';

    priceCopy += 'pasado este kilometraje deberá pagar ' + precioKmMayor + ' CUP' + ' por cada km extra.' + "\n";

    const details = decorator + 'Detalles del servicio:' + decorator ;
    const px = '👨‍👩‍👧‍👦 Pasajeros: ' + serviceSelected.cantPasajeros;

    priceCopy += "\n" + details + "\n";
    priceCopy += px + "\n";

    if (tieneFactores) {
      serviceSelected.factoresViaje?.forEach(factor => {
        switch (factor.nombre) {
          case 'Horario Pico':
            priceCopy += "🕗 " + factor.nombre + "\n";
            break;
          case 'Madrugada':
            priceCopy += "🌃 " + factor.nombre + "\n";
            break;
          case 'Nocturno':
            priceCopy += "🏙️ " + factor.nombre + "\n";
            break;
          case 'Carga':
            priceCopy +="🚚 "+factor.nombre + "\n";
            break;
          case 'Aire':
            priceCopy +="❄️ "+factor.nombre + "\n";
            break;
          case 'Confort':
            priceCopy +="🚘 "+factor.nombre + "\n";
            break;
          case 'Equipaje':
            priceCopy +="🧳 "+factor.nombre + "\n";
            break;
          case 'Estandar':
            if(!factorMoto){
              priceCopy +="🚙 "+factor.nombre + "\n";
            }
            break;
          case 'Extranjero':
            priceCopy +="🌎 "+factor.nombre + "\n";
            break;
          case 'Mascotas':
            priceCopy +="🐶 "+factor.nombre + "\n";
            break;
          case 'Moderno':
            priceCopy +="🚘 "+factor.nombre + "\n";
            break;
          case 'Día festivo':
            priceCopy +="🎇 "+factor.nombre + "\n";
            break;
          case 'Moto estándar':
            priceCopy += "🛵 " + factor.nombre + "\n";
            break;
          case 'Moto combustión':
            priceCopy += "🛵 " + factor.nombre + "\n";
            break;
          case 'Moto eléctrica':
            priceCopy += "🛵 " + factor.nombre + "\n";
            break;
          case 'Almendron':
            priceCopy +="🚗 "+ factor.nombre + "\n";
            break;
          case 'Provincia':
            priceCopy +="🛣️ "+factor.nombre + "\n";
            break; 
          /*default:
            priceCopy +=" "+factor.nombre + "\n";
            break;*/
        }

      });

      if (serviceSelected.valorPeriferico) {
        priceCopy +="🛣️ "+ "Servicio periférico" + "\n";
      }

      priceCopy += "\n";
    }

    priceCopy+="(US-"+this.user+")"+ "\n";

    this.clipboard.copy(priceCopy);
  }
  /*****************************END COPYS**************************/

/** Se calcula el vuelto del servicio tanto para el chofer como lo que falta por pagar
 * por parte del cliente
 */
  calcularVueltoServicio(requestPrecio: PreciosOtraMonedaRequest): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
    });

    return this._http
      .put<any>(environment.serviceService + '/calcular_vuelto', requestPrecio, {
        headers,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

  buildCCCopyForEmail(copyType: string, serviceSelected: ServiceEntity, serviceStopEntity?: ServiceStopEntity[], selectedClientPerson?: PersonEntity | undefined, selectedChoferPerson?: PersonEntity | undefined): string {
    let CCCopy: string = '';
    let nombreChofer: string = '';

    let factorMoto = false;

    serviceSelected.codViaje = serviceSelected.codViaje ? serviceSelected.codViaje : '-';

    if (selectedChoferPerson?.identificador) {
      serviceSelected.choferIdentificador = selectedChoferPerson?.identificador;
    } else if (serviceSelected.chofer && serviceSelected.chofer.identificador) {
      serviceSelected.choferIdentificador = serviceSelected.chofer.identificador;
    } else serviceSelected.choferIdentificador = '-';

    serviceSelected.tiempoEstimChofer = serviceSelected.tiempoEstimChofer ? serviceSelected.tiempoEstimChofer : 0;

    if (serviceSelected.chofer && serviceSelected.chofer.autoSeleccionado) {
      if (serviceSelected.chofer.autoSeleccionado.colorAutoEntity) {
        serviceSelected.colorAuto = serviceSelected.chofer.autoSeleccionado.colorAutoEntity.nombre;
      }
      if (serviceSelected.chofer.autoSeleccionado.marcaAutoEntity) {
        serviceSelected.marcaAuto = serviceSelected.chofer.autoSeleccionado.marcaAutoEntity.nombre;
      }
    } else if(selectedChoferPerson && selectedChoferPerson.autoSeleccionado){
      if (selectedChoferPerson.autoSeleccionado.colorAutoEntity) {
        serviceSelected.colorAuto = selectedChoferPerson.autoSeleccionado.colorAutoEntity.nombre;
      }
      if (selectedChoferPerson.autoSeleccionado.marcaAutoEntity) {
        serviceSelected.marcaAuto = selectedChoferPerson.autoSeleccionado.marcaAutoEntity.nombre;
      }
    }
    serviceSelected.colorAuto = serviceSelected.colorAuto ? serviceSelected.colorAuto : '-';
    serviceSelected.marcaAuto = serviceSelected.marcaAuto ? serviceSelected.marcaAuto : '-';

    serviceSelected.cantPasajeros = serviceSelected.cantPasajeros ? serviceSelected.cantPasajeros : 0;
    serviceSelected.lugarOrigen = serviceSelected.lugarOrigen ? serviceSelected.lugarOrigen : '-';
    serviceSelected.lugarDestino = serviceSelected.lugarDestino ? serviceSelected.lugarDestino : '-';
    serviceSelected.precioEstimChofer = serviceSelected.precioEstimChofer ? serviceSelected.precioEstimChofer : 0;
    serviceSelected.monedaPago = serviceSelected.monedaPago == null || serviceSelected.monedaPago == TipoMoneda.Otra.toString() /*|| serviceSelected.monedaPago == TipoMoneda.CUP.toString()*/ ? 'MLC' : serviceSelected.monedaPago;

    let tieneFactores = false;

    if (serviceSelected.factoresViaje?.length > 0) {
      tieneFactores = true;
      let factorMotor = serviceSelected.factoresViaje.filter(factor => factor.esFactorMoto);
      if (factorMotor.length > 0) {
        factorMoto = true;
      }
    }

    if (serviceSelected.precioAumentado) {

      let textoPrecioAumentado = "Le habíamos dado una tarifa inicialmente, pero no contamos en este momento con disponibilidad para su servicio por el precio anterior. Tenemos un auto disponible para usted: ";
      let textoOpcion = "Es solo una opción, en caso de que no lo desee continuamos la gestión por el precio inicial. Díganos por favor si va a desear el servicio por este costo.";

      if (factorMoto) {
        CCCopy += "*------ ReneMoto ------*" + "\n\n";
        textoPrecioAumentado = "Le habíamos dado una tarifa inicialmente, pero no contamos en este momento con disponibilidad para su servicio por el precio anterior. Tenemos una moto disponible para usted: ";
      }

      const tiempo = "Tiempo: ";
      const fechaReserva = "Fecha: ";
      const horaReserva = "Hora: ";
      const precio = "Precio: ";

      const fechaInicio = new Date(serviceSelected.fechaInicio);
      const fechaInicioFormateada = format(fechaInicio, 'dd/MM/yyyy');
      const horaInicioFormateada = format(fechaInicio, 'HH:mm');

      CCCopy += textoPrecioAumentado + "\n\n";
      switch (copyType) {
        case TipoServicio.INMEDIATE.toString():
          CCCopy += tiempo + serviceSelected.tiempoEstimChofer + " min." + "\n";
          break;
        case TipoServicio.RESERVE.toString():
          CCCopy += fechaReserva;
          CCCopy += fechaInicioFormateada + " ";
          CCCopy += horaReserva;
          CCCopy += horaInicioFormateada + "\n";
          break;
      }      
      CCCopy += precio + serviceSelected.precioEstimChofer + " CUP." + "\n";
      CCCopy += "\n";
      CCCopy += textoOpcion + "\n";

    } else {

      if (selectedChoferPerson?.nombreApellidos){
        nombreChofer = selectedChoferPerson.nombreApellidos.split(' ')[0];
      } else if (serviceSelected.chofer) {
        nombreChofer = serviceSelected.chofer.nombreApellidos.split(' ')[0];
      }

      const fecha = "🗓️ " + "Fecha: ";
      const fechaHoy = "🗓️ " + "Fecha: (Hoy)";
      const hora = "⏰ " + "Hora: ";

      if (factorMoto) {
        CCCopy += "------ ReneMoto ------" + "\n\n";
      }

      CCCopy +="Id: " + serviceSelected.codViaje + "   ";
      CCCopy +=" Chofer: " + serviceSelected.choferIdentificador + "\n";

      const fechaInicio = new Date(serviceSelected.fechaInicio);
      const fechaInicioFormateada = format(fechaInicio, 'dd/MM/yyyy');
      const horaInicioFormateada = format(fechaInicio, 'HH:mm');

      switch (copyType) {
        case TipoServicio.INMEDIATE.toString():
          CCCopy += "Le recoge en " + serviceSelected.tiempoEstimChofer + " minutos"+ "\n";
          break;
        case TipoServicio.RESERVE.toString():
          CCCopy += "Le recoge a las " + horaInicioFormateada + "\n";
          break;
      }

      CCCopy +="Nombre: " + nombreChofer + "\n";
      if(serviceSelected.marcaAuto!='-'){
        CCCopy += "Marca: " + serviceSelected.marcaAuto + "\n";
      }
      if(serviceSelected.colorAuto!='-'){
        CCCopy += "Color: " + serviceSelected.colorAuto + "\n";
      }

      switch (copyType) {
        case TipoServicio.INMEDIATE.toString():
          CCCopy += fechaHoy + "\n";
          break;
        case TipoServicio.RESERVE.toString():
          CCCopy += fecha;
          CCCopy += fechaInicioFormateada + "\n";
          break;
      }

      CCCopy += "👨‍👩‍👦 " + "Pasajeros: " + serviceSelected.cantPasajeros + "\n\n";
      CCCopy +="🏳️ " + "Origen: " + serviceSelected.lugarOrigen + "\n";

      if (serviceStopEntity!.length > 0) {
        // Ordenar el array utilizando la función de comparación personalizada
        serviceStopEntity!.sort(compararPorAtributoNoOrden);
        CCCopy += "\n";
        CCCopy += "Paradas:" + "\n";
        serviceStopEntity!.forEach(stop => {
          CCCopy += "P" + stop.numOrden + ": " + stop.lugar + "\n";
        });
        CCCopy += "\n";
        CCCopy += "🏁 " + "Destino: " + serviceSelected.lugarDestino + "\n\n";
      } else
        CCCopy += "\n" + "🏁 " + "Destino: " + serviceSelected.lugarDestino + "\n\n";

      let precioPorMinuto = 0;
      let tipoViaje = 'recorrido';
      if (serviceSelected.precioPorMinuto){
        precioPorMinuto = Number(serviceSelected.precioPorMinuto.toFixed(2));
      }
      let tiempoIncluido = '';
      if (serviceSelected.tiempoIncluido) {
        tiempoIncluido = convertirMinutosAHoras(Number(serviceSelected.tiempoIncluido));
      }
      if (serviceSelected.lugarOrigen && serviceSelected.lugarDestino && serviceStopEntity && serviceStopEntity.length === 0) {
        tipoViaje = '';
      } else if (serviceSelected.lugarOrigen && serviceSelected.lugarDestino && serviceStopEntity && serviceStopEntity.length > 0) {
        tipoViaje = 'recorrido';
      }
      let timeService = 'En este servicio usted puede usar ' +  tiempoIncluido + ' el auto sin modificar el trayecto.';
      if (factorMoto) {
        timeService = 'En este servicio usted puede usar ' +  tiempoIncluido + ' la moto sin modificar el trayecto.';
      }
      const surCharge = 'Pasado este tiempo se cobrará un recargo de ' + precioPorMinuto + ' CUP' + ' por minuto.';
      const warn = 'Si modifica el trayecto, tanto el precio como el tiempo serán modificados.'

      if (tipoViaje === 'recorrido' && serviceSelected.tiempoIncluido){
        CCCopy += "Incidencia(s): " + "\n";
        CCCopy += timeService + "\n";
        CCCopy += surCharge + "\n";
        CCCopy += warn + "\n";
        CCCopy += "\n";
      }

      const details = 'Detalles del servicio:';

      if (tieneFactores) {
        CCCopy += details + "\n";
      }

      if (serviceSelected.factoresViaje?.length > 0) {
        serviceSelected.factoresViaje?.forEach(factor => {
          switch (factor.nombre) {
            case 'Horario Pico':
              CCCopy += "🕗 " + factor.nombre + "\n";
              break;
            case 'Madrugada':
              CCCopy += "🌃 " + factor.nombre + "\n";
              break;
            case 'Nocturno':
              CCCopy += "🏙️ " + factor.nombre + "\n";
              break;
            case 'Carga':
              CCCopy += "🚚 " + factor.nombre + "\n";
              break;
            case 'Aire':
              CCCopy += "❄️ " + factor.nombre + "\n";
              break;
            case 'Confort':
              CCCopy += "🚘 " + factor.nombre + "\n";
              break;
            case 'Equipaje':
              CCCopy += "🧳 " + factor.nombre + "\n";
              break;
            case 'Estandar':
              if(!factorMoto){
                CCCopy += "🚙 " + factor.nombre + "\n";
              }
              break;
            case 'Extranjero':
              CCCopy += "🌎 " + factor.nombre + "\n";
              break;
            case 'Mascotas':
              CCCopy += "🐶 " + factor.nombre + "\n";
              break;
            case 'Moderno':
              CCCopy += "🚘 " + factor.nombre + "\n";
              break;
            case 'Día festivo':
              CCCopy += "🎇 " + factor.nombre + "\n";
              break;
            case 'Moto estándar':
              CCCopy += "🛵 " + factor.nombre + "\n";
              break;
            case 'Moto combustión':
              CCCopy += "🛵 " + factor.nombre + "\n";
              break;
            case 'Moto eléctrica':
              CCCopy += "🛵 " + factor.nombre + "\n";
              break;
            case 'Almendron':
              CCCopy +="🚗 "+factor.nombre + "\n";
              break;
            case 'Provincia':
              CCCopy +="🛣️ "+factor.nombre + "\n";
              break;
            /*default:
              priceCopy +=" "+factor.nombre + "\n";
              break;*/
          }

        });

        if (serviceSelected.valorPeriferico) {
          CCCopy +="🛣️ "+ "Servicio periférico" + "\n";
        }

        CCCopy += "\n";
      }

      if (serviceSelected.datosParaChofer || ((!serviceSelected.lugarDestino || serviceSelected.lugarDestino === '-')
      && (!serviceSelected.precioEstimChofer || serviceSelected.precioEstimChofer === 0))) {
        CCCopy += "Incidencia(s): " + "\n";
      }

      if ((!serviceSelected.lugarDestino || serviceSelected.lugarDestino === '-') && (!serviceSelected.precioEstimChofer || serviceSelected.precioEstimChofer === 0)) {
        //CCCopy += "*Incidencia(s):* " + "\n";
        CCCopy += "\nEl precio de su servicio podrá variar en función del km y tiempo, este será dado a su chofer al finalizar el recorrido.\n";
      }

      if (serviceSelected.datosParaChofer != null && serviceSelected.datosParaChofer != '') {
        CCCopy +=serviceSelected.datosParaChofer+"\n";
      }

      if(serviceSelected.modoPago && serviceSelected.modoPago.nombre){
        serviceSelected.modoPagoDescCorta = serviceSelected.modoPago.nombre.toUpperCase();
      } else if (serviceSelected.modoPagoDescCorta){
        serviceSelected.modoPagoDescCorta = serviceSelected.modoPagoDescCorta.toUpperCase();
      }

      CCCopy +="\n";
      CCCopy += "💵: ";

      let isTransferMLCChofer = serviceSelected.modoPagoDescCorta === 'TRANF_MLC_CHOFER' || serviceSelected.modoPagoDescCorta === 'TRANSFERENCIA MLC CHOFER' ? true : false;

      if(serviceSelected.monedaPrecioEstimado !== 'CUP' && (serviceSelected.monedaPago === 'MLC' || serviceSelected.monedaPago === 'CUP')){

        if(isTransferMLCChofer){
          CCCopy += serviceSelected.precioEstimMlc + ' ' + 'MLC' + " / ";
          CCCopy += serviceSelected.precioEstimChofer + ' ' + 'CUP' + "\n";
        } else if(serviceSelected.monedaPago === 'MLC'){
          CCCopy += serviceSelected.precioEstimMlc + ' ' + 'MLC' + "\n";
        } else {
          CCCopy += serviceSelected.precioEstimChofer + ' ' + 'CUP' + "\n";
        }

      } else if(serviceSelected.monedaPrecioEstimado !== 'CUP' && serviceSelected.monedaPrecioEstimado === serviceSelected.monedaPago){

        CCCopy += serviceSelected.precioEstimMlc + ' ' + serviceSelected.monedaPago + "\n";

      } else if(serviceSelected.monedaPrecioEstimado !== 'CUP' && serviceSelected.monedaPrecioEstimado !== serviceSelected.monedaPago){

        if (serviceSelected.monedaPago == TipoMoneda.EUR.toString() || serviceSelected.monedaPago == TipoMoneda.USD.toString()) {
          CCCopy += serviceSelected.precioEstimMlc + ' ' + serviceSelected.monedaPago + "\n";
        }

      } else {

        if (serviceSelected.monedaPago == TipoMoneda.EUR.toString() || serviceSelected.monedaPago == TipoMoneda.USD.toString()) {

          if(serviceSelected.modoPagoDescCorta && serviceSelected.modoPagoDescCorta === 'EFECTIVO'){
            CCCopy += serviceSelected.precioEstimMlc + ' ' + serviceSelected.monedaPago + " / ";
            CCCopy += serviceSelected.precioEstimChofer + ' ' + 'CUP' + "\n";
          } else
            CCCopy += serviceSelected.precioEstimMlc + ' ' + serviceSelected.monedaPago + "\n";

        }  else {

          if(isTransferMLCChofer){
            CCCopy += serviceSelected.precioEstimMlc + ' ' + 'MLC' + " / ";
            CCCopy += serviceSelected.precioEstimChofer + ' ' + 'CUP' + "\n";
          } else if(serviceSelected.monedaPago === 'MLC'){
            CCCopy += serviceSelected.precioEstimMlc + ' ' + 'MLC' + "\n";
          } else {
            CCCopy += serviceSelected.precioEstimChofer + ' ' + 'CUP' + "\n";
          }

        }

      }

      if (selectedClientPerson != null && selectedClientPerson.cuenta != null && (selectedClientPerson.cuenta.saldo + selectedClientPerson.cuenta.bonificacion) > 0) {
        CCCopy += "\n" + 'Fondo: $' + formatSaldo(selectedClientPerson.cuenta.saldo + selectedClientPerson.cuenta.bonificacion) + "\n";
      }

    }

    CCCopy+="\n" + "(US-"+this.user+")"+ "\n";

    return CCCopy;
  }

  buscarDireccionApiGeoCuba(direccion: string): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    headers.set
    let url = "https://direccionescuba.andariego.cu/api/address/getdireccionCompleta";
    let value = [   
      "taxis2024"  
    ];
    return this._http
      .post<any>(url + `/${direccion}` + '?page=1&limit=5', value, {
        headers,
      })
      .pipe(
        map((res) => res),
        catchError(this.handleServiceError)
      );
  }

}

function formatSaldo(price: number): string {
  const formattedPrice = price.toFixed(2); // Asegura que siempre tenga dos decimales
  const parts = formattedPrice.split('.');

  if (parts.length === 1) {
    // Si no hay parte decimal, agrega los ceros
    return `${formattedPrice}.00`;
  } else {
    // Si hay parte decimal, verifica si es necesario agregar ceros
    const decimalPart = parts[1];
    if (decimalPart.length === 1) {
      return `${formattedPrice}0`; // Agrega un cero si solo hay un dígito decimal
    } else {
      return formattedPrice; // Mantén el formato original si hay más de un dígito decimal
    }
  }
}

// Función de comparación personalizada
function compararPorAtributoNoOrden(a: ServiceStopEntity, b: ServiceStopEntity): number {
  if (a.numOrden < b.numOrden) {
    return -1;
  } else if (a.numOrden > b.numOrden) {
    return 1;
  } else {
    return 0;
  }
}

function convertirMinutosAHoras(minutos: number): string {
  if (minutos < 60) {
    return `${minutos} min`;
  }

  const horas = Math.floor(minutos / 60);
  const minutosRestantes = minutos % 60;

  return `${horas}h y ${minutosRestantes} min`;
}

// Función para convertir una cadena de tiempo a minutos
function convertirAMinutos(tiempo: string): number {
  // Dividir la cadena de tiempo en horas, minutos y segundos
  const partes = tiempo.split(':');

  // Convertir las horas y los segundos a minutos
  const horas = parseInt(partes[0]) * 60;
  const segundos = parseInt(partes[2]) / 60;

  // Sumar las horas, los minutos y los segundos para obtener el total de minutos
  return horas + parseInt(partes[1]) + segundos;
}



