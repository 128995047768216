<h2 mat-dialog-title>
  {{title}}
</h2>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="onConfirm()" *ngIf="confirmBottom">{{confirmLabelBottom}}</button>
  <button mat-button (click)="onDismiss()" *ngIf="dismissBottom">{{dismissLabelBottom}}</button>
</div>
