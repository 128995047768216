import { UserComponent } from './components/user/user.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularmaterialModule } from './material/angularmaterial/angularmaterial.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { PersonStatusPipe } from './pipes/person-status.pipe';
import { PersonCategoriesPipe } from './pipes/person-categories.pipe';
import { PersonRolePipe } from './pipes/person-role.pipe';
import { BooleanFieldPipe } from './pipes/boolean-field.pipe';
import { PersonWalletPipe } from './pipes/person-wallet.pipe';
import {AngularprimengModule} from "./primeng/angularprimeng/angularprimeng.module";
import { EmptyFieldPipe } from './pipes/empty-field.pipe';
import { ComunicationWayPipe } from './pipes/comunication-way.pipe';
import {DeudaPipe} from "./pipes/deuda.pipe";
import { HasValueFieldPipe } from './pipes/has-value-field.pipe';
import { ParadasPipe } from './pipes/paradas.pipe';
import {GoogleMapsModule} from "@angular/google-maps";
import { MapComponent } from './components/map/map.component';
import { NotifActivoPipe } from './pipes/notif-activo.pipe';
import {NgIdleKeepaliveModule} from "@ng-idle/keepalive";
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { ActivoFieldPipe } from './pipes/activo-field.pipe';
import { ProgramadaFieldPipe } from './pipes/programada-field.pipe';
import {XsegundoService} from "./services/xsegundo.service";
import {NgxPermissionsModule} from "ngx-permissions";
import { CreateShiftDialogComponent } from './components/create-shift-dialog/create-shift-dialog.component';
import { SelectOnFocusDirective } from './directives/select-on-focus.directive';
import { AboutDialogComponent } from './components/about-dialog/about-dialog.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import {MonthPipe} from "./pipes/month-pipe";
import {AmountPipe} from "./pipes/amount.pipe";
import { ShowReportDialogComponent } from './components/show-report-dialog/show-report-dialog.component';
import { CancelAlertDialogComponent } from './components/cancel-alert-dialog/cancel-alert-dialog.component';
import { ManageDocumentsComponent } from './components/manage-documents/manage-documents.component';
import { DialogShowImageDocumentComponent } from './components/manage-documents/dialog-show-image-document/dialog-show-image-document.component';
import { PhotoCarouselComponent } from './components/photo-carousel/photo-carousel.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    NotificationsComponent,
    UserComponent,
    ConfirmDialogComponent,
    PersonStatusPipe,
    PersonCategoriesPipe,
    PersonRolePipe,
    BooleanFieldPipe,
    PersonWalletPipe,
    EmptyFieldPipe,
    ComunicationWayPipe,
    MonthPipe,
    AmountPipe,
    DeudaPipe,
    HasValueFieldPipe,
    ParadasPipe,
    MapComponent,
    NotifActivoPipe,
    InformationDialogComponent,
    ActivoFieldPipe,
    ProgramadaFieldPipe,
    CreateShiftDialogComponent,
    SelectOnFocusDirective,
    AboutDialogComponent,
    ErrorDialogComponent,
    ShowReportDialogComponent,
    CancelAlertDialogComponent,
    ManageDocumentsComponent,
    DialogShowImageDocumentComponent,
    PhotoCarouselComponent,
  ],
  imports: [
    CommonModule,
    AngularmaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AngularprimengModule,
    GoogleMapsModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  exports: [
    AngularmaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    HeaderComponent,
    SidebarComponent,
    ConfirmDialogComponent,
    PersonStatusPipe,
    PersonCategoriesPipe,
    PersonRolePipe,
    BooleanFieldPipe,
    PersonWalletPipe,
    AngularprimengModule,
    EmptyFieldPipe,
    ComunicationWayPipe,
    MonthPipe,
    AmountPipe,
    DeudaPipe,
    HasValueFieldPipe,
    ParadasPipe,
    GoogleMapsModule,
    MapComponent,
    NotifActivoPipe,
    ActivoFieldPipe,
    ProgramadaFieldPipe,
    SelectOnFocusDirective,
    ManageDocumentsComponent,
  ],
  providers: [XsegundoService],
})
export class SharedModule {}
