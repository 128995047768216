import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CalendarModule} from "primeng/calendar";
import {CardModule} from "primeng/card";
import {PanelModule} from "primeng/panel";
import {TabPanel, TabViewModule} from "primeng/tabview";
import {FieldsetModule} from "primeng/fieldset";
import {DataViewModule} from "primeng/dataview";
import {RatingModule} from "primeng/rating";
import {FileUploadModule} from "primeng/fileupload";
import {SlideMenuModule} from "primeng/slidemenu";




const primengModules = [
  CalendarModule,
  CardModule,
  PanelModule,
  TabViewModule,
  FieldsetModule,
  DataViewModule,
  RatingModule,
  FileUploadModule,
  SlideMenuModule,



];

@NgModule({
  declarations: [],
  imports: [
    CommonModule, ...primengModules
  ],
  exports: [...primengModules],
})
export class AngularprimengModule { }
