export enum ModoCalculoPrecio {
  COMUN = 'Comun' as any,
  AEROPUERTO = 'Aeropuerto' as any,
  PLAYA = 'Playa' as any,
  PROVINCIA = 'Provincia' as any,

  Comun = 'COMUN' as any,
  Aeropuerto = 'AEROPUERTO' as any,
  Playa = 'PLAYA' as any,
  Provincia = 'PROVINCIA' as any,
}

export enum TipoTrayectoria {
  IDA = 'Ida' as any,
  VUELTA = 'Recogida' as any,
  IDA_Y_VUELTA = 'Ida y vuelta' as any,
}

export enum CodTipoTrayectoria {
  Ida = 'IDA' as any,
  Recogida = 'VUELTA' as any,
  Ida_y_vuelta = 'IDA_Y_VUELTA' as any,
}
