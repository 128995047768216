export enum TipoServicio {
  INMEDIATE = 'INMEDIATO' as any,
  RESERVE = 'RESERVA' as any,
  LATER = 'MAS_ADELANTE' as any,
  tOMORROW = 'MAÑANA' as any,

/** Acciones de un servicio */
  U_D = 'URGENTE_DISPONIBLE' as any,
  C_C = 'CLIENTE_CONFIRM' as any,
  D_C = 'DATOS_CHOFER' as any,
  I_C = 'ID_CLIENTE' as any,
  P_W = 'PRICE_WHATSAPP' as any,
  P_T = 'PRICE_TELEGRAM' as any,
}
