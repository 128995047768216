<h2 mat-dialog-title>
    <b>{{title}}</b>
  </h2>
  
  <div mat-dialog-content>
    <p>{{message}}</p>
  </div>
  
  <div mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="onConfirm()">Aceptar</button>
  </div>
