export enum EstadoServicio {
  in_process = 'En Proceso' as any,
  on_hold = 'En Espera' as any,
  in_reserve = 'En Reserva' as any,
  cancelled = 'Cancelado' as any,
  finished = 'Terminado' as any,
  history = 'Históricos' as any,
}

export enum EstadoServicioId {
  in_process_id = undefined as any,
  on_hold_id = 2 as any,
  in_reserve_id = 4 as any,
  accepted_id = 4 as any,
  started_id = 5 as any,
  arrived_id = 8 as any,
  pickedUp_id = 9 as any,
  dropped_id = 10 as any,
  cancelled_id = 11 as any,
  finished_id = 12 as any,
  archived_id = 13 as any,
  history_id = undefined as any,
}
