import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { RouteService } from "../../services/route.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  title: string;
  message: string;

  closeModalSubscription: Subscription;

  constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogModel, private routeService: RouteService) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.closeModalSubscription = this.routeService.closeModals$.subscribe((closeModal: any) => {
      if (closeModal) {
        this.dialogRef.close(false);
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.closeModalSubscription) {
      this.closeModalSubscription.unsubscribe();
    }
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }
}
/**
* Class to represent information dialog model.
*
* It has been kept here to keep it as part of shared component.
*/
export class ErrorDialogModel {
  constructor(public title: string, public message: string) {
  }
}
