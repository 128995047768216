import {Inject, Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {ModoTrabajo} from "../../shared/consts/modo-trabajo";

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  constructor(
    @Inject('LOCAL_URL') private localUrl: string,
    @Inject('BASE_URL') private baseUrl: string,
    @Inject('PROD_MODE') private productionMode: string
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Get work mode from the local storage.
    const workMode = localStorage.getItem('workMode');
    if (workMode && this.productionMode){
      if (workMode === ModoTrabajo.OFFLINE_PARTIAL.toString()) {
        if (request.method == "GET" || request.url.includes('viajes/calcular_precio')){
          const newUrlReq = request.url.replace(this.baseUrl, this.localUrl);
          const urlReq = request.clone({ url: `${newUrlReq}`});
          return next.handle(urlReq);
        }
      } else if (workMode === ModoTrabajo.OFFLINE_TOTAL.toString()) {
        if (request.method == "GET" || request.url.includes('viajes/calcular_precio')){
          const newUrlReq = request.url.replace(this.baseUrl, this.localUrl);
          const urlReq = request.clone({ url: `${newUrlReq}`});
          return next.handle(urlReq);
        }
      } else if (workMode === ModoTrabajo.LOCAL.toString()) { // && request.method == "GET"
        const newUrlReq = request.url.replace(this.baseUrl, this.localUrl);
        const urlReq = request.clone({ url: `${newUrlReq}`});
        return next.handle(urlReq);
      }
    }
    return next.handle(request);
  }
}
