import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {circle, LatLng, latLng, LatLngTuple, marker, polygon, tileLayer} from 'leaflet';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {PlaceEntity, ServiceEntity, ServiceStopEntity} from "../../../../shared/models/service-model";
import {RouteService} from "../../../../shared/services/route.service";
import {Observable, of, Subscription} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatChipInputEvent, MatChipList, MatChipSelectionChange} from "@angular/material/chips";
import {debounceTime, distinctUntilChanged, map, startWith} from "rxjs/operators";
import {ServiceService} from "../../services/service.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

import * as L from 'leaflet';
import 'leaflet-routing-machine';
import 'leaflet/dist/leaflet.css';
import 'polyline-encoded/Polyline.encoded.js';
import {NgxUiLoaderService} from "ngx-ui-loader";
import {
  ErrorDialogComponent,
  ErrorDialogModel
} from "../../../../shared/components/error-dialog/error-dialog.component";
import {NotificationService} from "../../../../shared/services/notification.service";
import {AdministrationService} from "../../../administration/services/administration.service";
import {
  InformationDialogComponent, InformationDialogModel
} from "../../../../shared/components/information-dialog/information-dialog.component";

@Component({
  selector: 'app-create-route-osm',
  templateUrl: './create-route-osm.component.html',
  styleUrls: ['./create-route-osm.component.scss']
})
export class CreateRouteOsmComponent implements OnInit, AfterContentChecked{
  @ViewChild('chipList') chipList!: MatChipList;
  @ViewChild('chipsInput') chipsInput!: ElementRef;

  routeForm!: FormGroup;
  serviceEntity!: ServiceEntity;
  listStops: ServiceStopEntity[] = []; /**Paradas del viaje*/
  selectedStop!: ServiceStopEntity | undefined; /**Parada seleccionada*/
  selectedStopIndex!: number | undefined; /**Indice de la parada seleccionada*/
  selectedChip!: MatChipSelectionChange | undefined; /**MatChip seleccionado*/
  inputSelected = 'lugarOrigen';

  originControl = new FormControl();
  filteredOriginOptions!: Observable<PlaceEntity[]>;
  listOriginPlace: PlaceEntity[] = [];

  destinyControl = new FormControl();
  filteredDestinyOptions!: Observable<PlaceEntity[]>;
  listDestinyPlace: PlaceEntity[] = [];

  stopControl = new FormControl();
  filteredStopOptions!: Observable<PlaceEntity[]>;
  listStopPlace: PlaceEntity[] = [];

  selectable = true;
  removable = true;
  addOnBlur = true;

  hasOrigin = false;
  restoredOrigin = false;
  savedOrigin: string | undefined;
  savedLatitudOrigen!: number;
  savedLongitudOrigen!: number;
  searchOriginByCoordinates = false;
  hasDestiny = false;
  restoredDestiny = false;
  savedDestiny: string | undefined;
  savedLatitudDestino!: number;
  savedLongitudDestino!: number;
  searchDestinyByCoordinates = false;
  removeByKeyboard = false;
  searchByCoordinates = false;

  /**ELEMENTOS DEL MAPA*/
  map!: L.Map;
  routingControl!: L.Routing.Control;
  originMarker!: L.Marker;
  stopsMarker: L.Marker[] = [];
  destinyMarker!: L.Marker;

  /**OPTIONS DE LOS ELEMENTOS DEL MAPA*/
  mapOptions: L.MapOptions = {
    //maxZoom: 20,
    //minZoom: 5
  }
  /**Origen*/
  originIconOptions: L.IconOptions = {
    iconUrl: 'assets/images/punto-origen.png',
    iconSize: [28, 28],
    iconAnchor: [15, 28],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    shadowAnchor: [12, 41]
  };
  customOriginIcon: L.Icon = L.icon(this.originIconOptions);
  originMarkerOptions: L.MarkerOptions = {
    icon: this.customOriginIcon,
    draggable: true,
  }

  /**Paradas*/
  stopIconOptions: L.IconOptions = {
    iconUrl: 'assets/images/stop-route-32.png',
    iconSize: [28, 28],
    iconAnchor: [15, 28],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    shadowAnchor: [12, 41],
  };
  stopIcon = L.divIcon({
    className: 'custom-icon',
    iconUrl: 'assets/images/stop-marker.png',
    iconSize: [28, 28],
    iconAnchor: [15, 28],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    shadowAnchor: [12, 41],
  });

  customStopIcon: L.Icon = L.icon(this.stopIconOptions);
  stopMarkerOptions: L.MarkerOptions = {
    icon: this.stopIcon,
    draggable: true,
  }

  /**Destino*/
  destinyIconOptions: L.IconOptions = {
    iconUrl: 'assets/images/punto-destino.png',
    iconSize: [28, 28],
    iconAnchor: [15, 28],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    shadowAnchor: [12, 41],
  };
  customDestinyIcon: L.Icon = L.icon(this.destinyIconOptions);
  destinyMarkerOptions: L.MarkerOptions = {
    icon: this.customDestinyIcon,
    draggable: true,
  }

  polyline!: L.Polyline;

  closeModalSubscription: Subscription;

  polyUtil = require('polyline-encoded/Polyline.encoded.js');

  mapBoxToken = 'pk.eyJ1IjoiZHBpZXRyb2NhcmxvIiwiYSI6ImNram9tOGFuMTBvb3oyeXFsdW5uYmJjNGQifQ._zE6Mub0-Vpl7ggMj8xSUQ';

  constructor(public dialogRef: MatDialogRef<CreateRouteOsmComponent>, public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: { serviceEntity: ServiceEntity, listStops: ServiceStopEntity[]},
              private routeService: RouteService, private serviceService: ServiceService, private cdr: ChangeDetectorRef, private fb: FormBuilder,
              protected ngxLoaderService: NgxUiLoaderService, private notificacionService: NotificationService, private administracionService: AdministrationService) {
    this.serviceEntity = data.serviceEntity;
    this.listStops = data.listStops;
    this.closeModalSubscription = this.routeService.closeModals$.subscribe(closeModal => {
      if (closeModal) {
        this.dialogRef.close(false);
      }
    });
  }

  ngOnInit(): void {
    this.routeForm = this.fb.group({
      latitudOrigen: new FormControl({ value: null, disabled: false},
        Validators.compose([
          Validators.pattern('^(?!-0(\\.0+)?$)-?(0|[1-9]|[1-8][0-9]|9[0-0]\\d*)(,\\d+)?(\\.\\d+)?$')
        ])),
      latitudDestino: new FormControl({ value: null, disabled: false},
        Validators.compose([
          Validators.pattern('^(?!-0(\\.0+)?$)-?(0|[1-9]|[1-8][0-9]|9[0-0]\\d*)(,\\d+)?(\\.\\d+)?$')
        ])),
      longitudOrigen: new FormControl({ value: null, disabled: false},
        Validators.compose([
          Validators.pattern('^(?!-0(\\.0+)?$)-?(0|[1-9]|[1-9][0-9]|1[0-7][0-9]|1[0-8][0-0]\\d*)(,\\d+)?(\\.\\d+)?$')
        ])),
      longitudDestino: new FormControl({ value: null, disabled: false},
        Validators.compose([
          Validators.pattern('^(?!-0(\\.0+)?$)-?(0|[1-9]|[1-9][0-9]|1[0-7][0-9]|1[0-8][0-0]\\d*)(,\\d+)?(\\.\\d+)?$')
        ])),
      lugarOrigen: new FormControl({ value: null, disabled: false},
        Validators.compose([Validators.required])),
      lugarDestino: new FormControl({ value: null, disabled: false},
        Validators.compose([Validators.required])),
      listStops: new FormControl({ value: null, disabled: false}),
      totalKm: new FormControl({ value: null, disabled: false}),
      intercambiarRuta: new FormControl({ value: false, disabled: false}),
      rutaOrigDestino: new FormControl({ value: false, disabled: false})
    });

    this.map = L.map('map',this.mapOptions).setView([23.135308681729665, -82.35969543457031], 13);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors'
    }).addTo(this.map);

    this.getMapBoxApiToken('map_box_key');

    /** Evento al hacer clic en el mapa para agregar los marcadores*/
    this.map.on('click', (e) => {
      if (this.inputSelected === 'lugarOrigen'){
        this.placeOriginMarkerAndPanTo(e.latlng, true);
      } else if (this.inputSelected === 'lugarDestino') {
        this.placeDestinyMarkerAndPanTo(e.latlng, true);
      } else if (this.inputSelected === 'lugarParadas'){
        this.placeStopsMarkerAndPanTo(e.latlng, true);
      }
      /**Si existe ruta anterior se borra del mapa*/
      if (this.routingControl){
        this.map.removeControl(this.routingControl);
        this.routeForm.controls.totalKm.setValue(null);
      }
    });

    this.getOriginPlace('lugarOrigen');
    this.getStopPlace('lugarParadas');
    this.getDestinyPlace('lugarDestino');

    this.fillForm(this.serviceEntity, this.listStops);
  }

  ngOnDestroy(): void {
    if (this.closeModalSubscription){
      this.closeModalSubscription.unsubscribe();
    }
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }


  /**
   * Metodo para llenar el formulario y el mapa con la direccion ya creada
   * @param serviceEntity
   * @param listStops
   */
  fillForm(serviceEntity: ServiceEntity, listStops: ServiceStopEntity[]) {
    let origin = false;
    let destiny = false;

    if (serviceEntity) {
      if (serviceEntity.lugarOrigen !== null) {
        this.hasOrigin = true;
        this.savedOrigin = serviceEntity.lugarOrigen;
        this.savedLatitudOrigen = serviceEntity.latitudOrigen;
        this.savedLongitudOrigen = serviceEntity.longitudOrigen;
      }
      //this.originControl.setValue(serviceEntity.lugarOrigen);
      this.routeForm.controls.lugarOrigen.setValue(serviceEntity.lugarOrigen);
      this.routeForm.controls.latitudOrigen.setValue(serviceEntity.latitudOrigen);
      this.routeForm.controls.longitudOrigen.setValue(serviceEntity.longitudOrigen);
      if (serviceEntity.latitudOrigen && serviceEntity.longitudOrigen) {
        const latlng = new LatLng(
          parseFloat(String(serviceEntity.latitudOrigen)),
          parseFloat(String(serviceEntity.longitudOrigen))
        );
        this.placeOriginMarkerAndPanTo(latlng, true);
        //this.searchByCoordinates = false;
        origin = true;
      }

      if (serviceEntity.lugarDestino !== null) {
        this.hasDestiny = true;
        this.savedDestiny = serviceEntity.lugarDestino;
        this.savedLatitudDestino = serviceEntity.latitudDestino;
        this.savedLongitudDestino = serviceEntity.longitudDestino;
      }
      //this.destinyControl.setValue(serviceEntity.lugarDestino);
      this.routeForm.controls.lugarDestino.setValue(serviceEntity.lugarDestino);
      this.routeForm.controls.latitudDestino.setValue(serviceEntity.latitudDestino);
      this.routeForm.controls.longitudDestino.setValue(serviceEntity.longitudDestino);
      if (serviceEntity.latitudDestino && serviceEntity.longitudDestino) {
        const latlng = new LatLng(
          parseFloat(String(serviceEntity.latitudDestino)),
          parseFloat(String(serviceEntity.longitudDestino))
        );
        this.placeDestinyMarkerAndPanTo(latlng, true);
        destiny = true;
      }
    }

    if (listStops) {
      if (listStops.length > 0) {
        listStops.forEach((eachStop, index) => {
          /** se obtiene la localizacion de cada parada*/
          if (eachStop.geoLatitud && eachStop.geoLongitud) {
            const stopLocation = new LatLng(
              parseFloat(String(eachStop.geoLatitud)),
              parseFloat(String(eachStop.geoLongitud))
            );

            /** Creando un marcador por cada parada en la lista de paradas*/
            //this.stopIcon.options.html = this.generateCustomIconContent('P' + (this.stopsMarker.length + 1).toString());
            this.stopIcon.options.html = this.generateCustomIconContent('P' + (eachStop.numOrden).toString());
            let stop = L.marker(stopLocation, this.stopMarkerOptions).addTo(this.map);
            stop.options.title = 'P' + (this.stopsMarker.length + 1).toString();
            this.stopsMarker.push(stop);
            /**Adicionando a la lista de marcadores*/
            this.listStops[index].isManual = true;
            this.routeForm.controls.listStops.setValue(this.listStops);
          }
        });
      }
    }
    //trazar la ruta cuando estoy editando
    if (this.originMarker?.getLatLng() && this.destinyMarker?.getLatLng()) {
      this.traceRoute();
    }
/**Centrar el mapa en el pto medio de la ruta*/
    // if(waypoints && waypoints.length>0){
    //   this.polyline = L.polyline(waypoints);
    //   // Obtener los límites de la polilínea
    //   const bounds = this.polyline.getBounds()
    //   // Obtener el centro de los límites de la polilínea
    //   const centerLatLng = bounds.getCenter();
    //   // Obtener la latitud y longitud del centro
    //   const centerLat = centerLatLng.lat;
    //   const centerLng = centerLatLng.lng;
    //   // Ajustar el mapa para que se muestre la polilínea
    //   this.map.fitBounds(bounds);
    //   // Crear un objeto LatLng con las coordenadas
    //   const locationLatLng = new LatLng(
    //     centerLat,
    //     centerLng
    //   );
    //   // Establecer la ubicación en el centro del mapa
    //   this.map.setView(locationLatLng, this.map.getZoom());
    // }

    /**Obtener coordenadas del centro de la ruta a partir del valor codificado de la ruta */
    if (this.serviceEntity.rutaOrigDestino) {
      const decodedPolyline: LatLngTuple[] = this.polyUtil.decode(this.serviceEntity.rutaOrigDestino, 5)
      const path = decodedPolyline.map(coords => L.latLng(coords[0], coords[1]));
      this.polyline = L.polyline(path, {
        color: '#4d90fe',
        weight: 6,
        opacity: 0.8
      });
        // Obtener los límites de la polilínea
        const bounds = this.polyline.getBounds()
        // Obtener el centro de los límites de la polilínea
        const centerLatLng = bounds.getCenter();
        // Obtener la latitud y longitud del centro
        const centerLat = centerLatLng.lat;
        const centerLng = centerLatLng.lng;
        // Ajustar el mapa para que se muestre la polilínea
        this.map.fitBounds(bounds);
        // Crear un objeto LatLng con las coordenadas
        const locationLatLng = new LatLng(
          centerLat,
          centerLng
        );
        // Establecer la ubicación en el centro del mapa
        //this.map.setView(locationLatLng, this.map.getZoom());

      // // Obtener la longitud de la polilínea
      // const length = this.polyline.getLatLngs().length;
      // // Calcular el índice del punto medio
      // const midIndex = Math.round(length / 2);
      // // Obtener las coordenadas del punto medio
      // const midPoint = this.polyline.getLatLngs()[midIndex];
      //
      // // Obtener la latitud y longitud del punto medio
      // const latitud = midPoint.lat;
      // const longitud = midPoint.lng;
      //
      // // Centrar el mapa en el punto medio
      // this.map.setView(midPoint,this.map.getZoom());
    }

  }

  getMapBoxApiToken(variable:string): void {
    this.administracionService.getConfigVariableByNombre(variable).subscribe({
      next: (result) => {
        let variableConfigList = result;
        if (variableConfigList && variableConfigList.length) {
          this.mapBoxToken = variableConfigList[0].valor;
        }
      },
      error: (err) => {

      },
    });
  }
    /**
   * Metodo para agregar el marcador del origen al mapa
   * @param latLng
   */
  placeOriginMarkerAndPanTo(latLng: LatLng, searchPlace?: boolean) {
    /**Si existe el marcador anterior se borra del mapa*/
    if (this.originMarker){
      this.map.removeLayer(this.originMarker);
    }
    this.originMarker = L.marker(latLng, this.originMarkerOptions).addTo(this.map);
    this.searchByCoordinates = false;

    this.map.setView(latLng);
    this.originMarker.on('dragend', (event: L.DragEndEvent) => {
      const marker = event.target;
      const position: LatLng = marker.getLatLng();
      this.searchPlaceReverse(position, 'lugarOrigen');
      /**Si existe ruta anterior se borra del mapa*/
      if (this.routingControl){
        this.map.removeControl(this.routingControl);
        this.routeForm.controls.totalKm.setValue(null);
      }
      //this.traceRoute();
    });
    this.originMarker.on('contextmenu', (event) => {
      this.map.removeLayer(this.originMarker);

      this.originControl.setValue(null);
      this.routeForm.controls.lugarOrigen.setValue(null);
      this.routeForm.controls.latitudOrigen.setValue(null);
      this.routeForm.controls.longitudOrigen.setValue(null);

      if (this.routingControl){
        this.map.removeControl(this.routingControl);
        this.routeForm.controls.totalKm.setValue(null);
      }
    });
    if (searchPlace){
      this.searchPlaceReverse(latLng, 'lugarOrigen');
    }
    //this.traceRoute();
  }

  /**
   * Metodo para agregar el marcador de las paradas al mapa
   * @param latLng
   */

  placeStopsMarkerAndPanTo(latLng: LatLng, searchPlace: boolean, wasSelected?: boolean) {
    if (this.selectedStop && this.selectedStopIndex !== undefined && this.selectedStop.geoLongitud && this.selectedStop.geoLatitud) {

      /**Actualizo el marcador correspondiente a la parada seleccionada, no necesariamente coinciden en index*/
      let marker = this.stopsMarker.filter(stopMarker =>
        (Number(this.selectedStop?.geoLatitud) === Number(stopMarker.getLatLng().lat) && Number(this.selectedStop?.geoLongitud) === Number(stopMarker.getLatLng().lng)));
      if (marker && marker.length > 0)
        marker[0].setLatLng(latLng);
      else {
        this.stopIcon.options.html = this.generateCustomIconContent('P' + (this.selectedStop.numOrden).toString());
        let stop = L.marker(latLng, this.stopMarkerOptions).addTo(this.map);
        stop.options.title = 'P' + (this.selectedStop.numOrden).toString().toString();
        this.stopsMarker.push(stop);
      }
      /**Arreglo de marcadores de las paradas*/
    } else {
        if(wasSelected)
          this.stopIcon.options.html = this.generateCustomIconContent((this.listStops) ? 'P' + (this.listStops.length).toString() : 'P' + (this.stopsMarker.length + 1).toString());
        else
          this.stopIcon.options.html = this.generateCustomIconContent((this.listStops && this.listStops.length > 0 && !this.selectedStop) ? 'P' + (this.listStops.length + 1).toString() : 'P' + (this.stopsMarker.length + 1).toString());
        let stop = L.marker(latLng, this.stopMarkerOptions).addTo(this.map);
        stop.options.title = (this.listStops && this.listStops.length > 0 && !this.selectedStop) ? 'P' + (this.listStops.length + 1).toString() : 'P' + (this.stopsMarker.length + 1).toString();
        this.stopsMarker.push(stop);
      }

    this.map.setView(latLng);
    this.searchByCoordinates = false;

    this.stopsMarker.forEach(stop =>{
      stop.on('dragend', (event: L.DragEndEvent) => {
        const marker = event.target;
        const position: LatLng = marker.getLatLng();
        let markerTitle = marker.options.title;
        let markerIndex = parseInt(markerTitle!.split('P')[1]) - 1;
        this.selectedStop = undefined;
        this.selectedStopIndex = undefined;
        /**si hay alguna parada seleccionada no se debe realizar la busqueda*/
        if (!this.selectedStop && this.selectedStopIndex === undefined){
          this.selectedStop = this.listStops.filter(stop => (stop.numOrden-1 === markerIndex))[0];
          this.selectedStopIndex = this.listStops.indexOf(this.selectedStop);

          /** Actualizo las coordenadas de la parada correspondiente al marcador que moví*/
          this.listStops[markerIndex].geoLatitud = position.lat;
          this.listStops[markerIndex].geoLongitud = position.lng;

          // if(!this.listStops[markerIndex].isManual)
          this.searchPlaceReverse(position, 'lugarParadas', true);
          /**Si existe ruta anterior se borra del mapa*/
          if (this.routingControl){
            this.map.removeControl(this.routingControl);
            this.routeForm.controls.totalKm.setValue(null);
          }
        } else{
          /**Actualizo la localizacion de la parada que se movio en el mapa para actualzar la ruta marcada*/
          this.listStops[markerIndex].geoLatitud = position.lat;
          this.listStops[markerIndex].geoLongitud = position.lng;
          /**Si existe ruta anterior se borra del mapa*/
          if (this.routingControl){
            this.map.removeControl(this.routingControl);
            this.routeForm.controls.totalKm.setValue(null);
          }
          this.selectedStop = undefined;
          this.selectedStopIndex = undefined;
          /** limpiando la seleccion del chipList **/
          // this.chipList._setSelectionByValue(this.chipList.selected, false);
          /**limpiar el input parada*/
          this.chipsInput.nativeElement.value = "";
          this.routeForm.controls.listStops.setValue(this.listStops);
          this.stopControl.setValue(this.listStops);
        }
        // if (searchPlace){
        //   this.searchPlaceReverse(latLng, 'lugarParadas');
        // }
        //
      });
      stop.on('contextmenu', (event) => {
        /**Quitando el marcador de la parada del mapa*/
        this.map.removeLayer(stop);

        /**Limpio arreglo de marcadores de las paradas*/
        this.stopsMarker = this.stopsMarker.filter(marker => (marker !== stop));

        /**Limpio arreglo de paradas del mat-chip-list */
        let deleteStop = this.listStops.filter(lStop =>
          (Number(lStop.geoLatitud) === Number(stop.getLatLng().lat) && Number(lStop.geoLongitud) === Number(stop.getLatLng().lng)));
        if (deleteStop.length > 0){
          this.removeStop(deleteStop[0]);
        }
        /**Si existe ruta anterior se borra del mapa*/
        if (this.routingControl){
          this.map.removeControl(this.routingControl);
          this.routeForm.controls.totalKm.setValue(null);
        }
      });
    })
    /** Si la parada fue seleccionada de un autocomplete, no vuelvo a realizar la búsqueda standard en el getStopPlace*/
    if (wasSelected)
      this.searchByCoordinates = true;

    if (searchPlace){
      this.searchPlaceReverse(latLng, 'lugarParadas', false);
    }
  }

  /**
   * Metodo para agregar el marcador del destino al mapa
   * @param latLng
   */
  placeDestinyMarkerAndPanTo(latLng: LatLng, searchPlace: boolean){
    /**Si existe el marcador anterior se borra del mapa*/
    if (this.destinyMarker){
      this.map.removeLayer(this.destinyMarker);
    }
    this.destinyMarker = L.marker(latLng, this.destinyMarkerOptions).addTo(this.map);
    this.map.setView(latLng);
    this.searchByCoordinates = false;
    this.destinyMarker.on('dragend', (event: L.DragEndEvent) => {
      const marker = event.target;
      const position: LatLng = marker.getLatLng();
      this.searchPlaceReverse(position, 'lugarDestino');
      /**Si existe ruta anterior se borra del mapa*/
      if (this.routingControl){
        this.map.removeControl(this.routingControl);
        this.routeForm.controls.totalKm.setValue(null);
      }
      //this.traceRoute();
    });
    this.destinyMarker.on('contextmenu', (event) => {
      this.map.removeLayer(this.destinyMarker);

      this.destinyControl.setValue(null);
      this.routeForm.controls.lugarDestino.setValue(null);
      this.routeForm.controls.latitudDestino.setValue(null);
      this.routeForm.controls.longitudDestino.setValue(null);

      if (this.routingControl){
        this.map.removeControl(this.routingControl);
        this.routeForm.controls.totalKm.setValue(null);
      }
    });
    if (searchPlace) {
      this.searchPlaceReverse(latLng, 'lugarDestino');
    }
    //this.traceRoute();
  }

  /**
   * Método para realizar la búsqueda de direcciones en reversa
   * @param latLng
   * @param placeType
   */
  searchPlaceReverse(latLng: LatLng, placeType: string, isDragging?: boolean) {
    this.ngxLoaderService.startBackground('do-background-things');
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latLng.lat}&lon=${latLng.lng}&zoom=17`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        const address = data.display_name;
        // console.log(address);
        /**Identifico que la busqueda es por coordenadas para que no lo busque por estándar en createFIeldSubscription()
         * cuando encuentre el resultado*/
        this.searchByCoordinates = true;

        if(placeType === 'lugarOrigen'){
          /**Si NO tiene origen se toma la dirección del servicio de geolocalizacion, de lo contraio se toma la guardada*/
          // if (!this.hasOrigin && !this.restoredOrigin){
          //   this.originControl.setValue(data);
          //   this.routeForm.controls.lugarOrigen.setValue(address);
          // } else if (!this.hasOrigin && this.restoredOrigin){
          //   this.routeForm.controls.lugarOrigen.setValue(this.savedOrigin);
          //   this.originControl.setValue(this.savedOrigin);
          //   this.restoredOrigin = false;
          // } else if (this.hasOrigin && this.searchByCoordinates /*this.searchOriginByCoordinates*/ && this.savedOrigin){
          //   this.routeForm.controls.lugarOrigen.setValue(this.savedOrigin);
          //   this.originControl.setValue(this.savedOrigin);
          // }
          if (!this.hasOrigin && this.restoredOrigin){
            this.routeForm.controls.lugarOrigen.setValue(this.savedOrigin);
            this.originControl.setValue(this.savedOrigin);
            this.restoredOrigin = false;
          } else {
            this.originControl.setValue(data);
            this.routeForm.controls.lugarOrigen.setValue(address);
          }
          /**Siempre se toman las coordenadas del mapa tenga o no origen*/
          this.routeForm.controls.latitudOrigen.setValue(latLng.lat);
          this.routeForm.controls.longitudOrigen.setValue(latLng.lng);
        } else if(placeType === 'lugarDestino'){
          /**Si NO tiene destino se toma la dirección del servicio de geolocalizacion, de lo contraio se toma la guardada*/
          // if (!this.hasDestiny && !this.restoredDestiny){
          //   this.destinyControl.setValue(data);
          //   this.routeForm.controls.lugarDestino.setValue(address);
          // }  else if (!this.hasDestiny && this.restoredDestiny){
          //   this.routeForm.controls.lugarOrigen.setValue(this.savedDestiny);
          //   this.destinyControl.setValue(this.savedDestiny);
          //   this.restoredDestiny = false;
          // } else if (this.hasDestiny && this.searchDestinyByCoordinates && this.savedDestiny){
          //   this.routeForm.controls.lugarDestino.setValue(this.savedDestiny);
          //   this.destinyControl.setValue(this.savedDestiny);
          // }
          if (!this.hasDestiny && this.restoredDestiny){
            this.routeForm.controls.lugarDestino.setValue(this.savedDestiny);
            this.destinyControl.setValue(this.savedDestiny);
            this.restoredDestiny = false;
          } else {
            this.destinyControl.setValue(data);
            this.routeForm.controls.lugarDestino.setValue(address);
          }
          /**Siempre se toman las coordenadas del mapa tenga o no destino*/
          this.routeForm.controls.latitudDestino.setValue(latLng.lat);
          this.routeForm.controls.longitudDestino.setValue(latLng.lng);
        } else if(placeType === 'lugarParadas'){
          if(this.selectedStop && this.selectedStopIndex !== undefined){
            /**Arreglo de paradas del mat-chip-list */
            this.listStops[this.selectedStopIndex].geoLatitud = latLng.lat;
            this.listStops[this.selectedStopIndex].geoLongitud = latLng.lng;
            /**Modifico el nombre de la parada no ha sido registrada de forma manual*/
            if (!this.listStops[this.selectedStopIndex].isManual){
              this.listStops[this.selectedStopIndex].lugar  = address;
            }
            this.selectedStop = undefined;
            this.selectedStopIndex = undefined;
            /** limpiando la seleccion del chipList **/
            this.chipList._setSelectionByValue(this.chipList.selected, false);
          } else {
            /**Añadir marcador parada siempre que no sea un drag en el mapa*/
            if (!isDragging)
              this.addStop(undefined, address, latLng);
          }
          /**limpiar el input parada*/
          this.chipsInput.nativeElement.value = "";
          /**Actualizo lista de paradas para cuando regrese al vale*/
          this.routeForm.controls.listStops.setValue(this.listStops);
          this.stopControl.setValue(this.listStops);
        }
      })
      .finally(() => {
        this.ngxLoaderService.stopBackground('do-background-things');
      });
  }

  /**
   * Método para realizar la búsqueda de direcciones
   * @param place
   * @param placeType
   */
  searchPlaceStandard(place: string, placeType: string) {
    this.ngxLoaderService.startBackground('do-background-things');
    //const url = `https://nominatim.openstreetmap.org/search?q=${place}&format=jsonv2&countrycodes=cu`;
    const url = `https://nominatim.openstreetmap.org/search?q=${place}&format=jsonv2`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          const resultsInCuba = data.filter((result: { display_name: string | string[]; }) => {
            return result.display_name.includes("Cuba");
          });
          console.log(resultsInCuba);
          if (resultsInCuba && resultsInCuba.length > 0) {
            if (placeType === 'lugarOrigen') {
              //this.listOriginPlace = data;
              this.listOriginPlace = resultsInCuba;
              this.filteredOriginOptions = this.originControl.valueChanges
                .pipe(
                  startWith(''),
                  map(value => typeof value === 'string' ? value : value.display_name),
                  map(name => name ? this._filterOrigin(name) : this.listOriginPlace.slice())
                );

              // if (this.listOriginPlace.length === 1) {
              //   this.originControl.setValue(this.listOriginPlace[0])
              // }
            } else if (placeType === 'lugarDestino') {
              //this.listDestinyPlace = data;
              this.listDestinyPlace = resultsInCuba;
              this.filteredDestinyOptions = this.destinyControl.valueChanges
                .pipe(
                  startWith(''),
                  map(value => typeof value === 'string' ? value : value.display_name),
                  map(name => name ? this._filterDestiny(name) : this.listDestinyPlace.slice())
                );

              // if (this.listDestinyPlace.length === 1) {
              //   this.destinyControl.setValue(this.listDestinyPlace[0])
              // }
            } else if (placeType === 'lugarParadas') {
              //this.listStopPlace = data;
              this.listStopPlace = resultsInCuba;
              this.filteredStopOptions = this.stopControl.valueChanges
                .pipe(
                  startWith(''),
                  map(value => typeof value === 'string' ? value : value.display_name),
                  map(name => name ? this._filterStop(name) : this.listStopPlace.slice())
                );
              // if (this.listStopPlace.length === 1) {
              //   this.stopControl.setValue(this.listStopPlace[0])
              // }
            }
            //const address = data;
            const address = resultsInCuba;
            console.log(address);
          } else {
            let msg = 'No se encontraron coincidencias para ';
            switch (placeType) {
              case 'lugarOrigen':
                msg += 'este origen';
                break;
              case 'lugarParadas':
                msg += 'esta parada';
                break;
              case 'lugarDestino':
                msg += 'este destino';
                break;
            }
            const dialogData = new InformationDialogModel('Información', msg);
            const dialogRef = this.dialog.open(InformationDialogComponent, {
              maxWidth: '400px',
              data: dialogData,
            });
          }
        } else {
          //let msg = 'No se encontraron detalles de la ruta.';
          let msg = 'No se encontraron coincidencias para ';
          switch (placeType) {
            case 'lugarOrigen':
              msg += 'este origen';
              break;
            case 'lugarParadas':
              msg += 'esta parada';
              break;
            case 'lugarDestino':
              msg += 'este destino';
              break;
          }
          const dialogData = new InformationDialogModel('Información', msg);
          const dialogRef = this.dialog.open(InformationDialogComponent, {
            maxWidth: '400px',
            data: dialogData,
          });
        }
      })
      .finally(() => {
        this.ngxLoaderService.stopBackground('do-background-things');
      });
  }

  createRoute() {
    if (this.originMarker?.getLatLng() && this.destinyMarker?.getLatLng()) {
      this.ngxLoaderService.startBackground('do-background-things');
      /**creando el arreglo de marcadores para la ruta*/
      let waypoints: LatLng[] = [];
      waypoints.push(this.originMarker.getLatLng());
      if (this.stopsMarker.length > 0){
        this.stopsMarker.forEach(stop =>{
          waypoints.push(stop.getLatLng());
        })
      }
      waypoints.push(this.destinyMarker.getLatLng());

      /**Si existe ruta anterior se borra del mapa*/
      if (this.routingControl){
        this.map.removeControl(this.routingControl);
        this.routeForm.controls.totalKm.setValue(null);
      }

      this.routingControl = L.Routing.control({
        waypoints: waypoints,
        router: L.Routing.mapbox(this.mapBoxToken, {
          //profile: 'mapbox/driving' // Perfil de conducción para obtener la ruta más corta
          //profile: 'mapbox/walking' // Perfil de peatón para obtener la ruta más corta
          //profile: 'mapbox/cycling' // Perfil de ciclismo para obtener la ruta más corta
        }),
        show: false,
        routeWhileDragging: false,
      }).addTo(this.map);

      this.routeForm.controls.rutaOrigDestino.setValue(this.polyUtil.encode(waypoints));

      this.routingControl.on('routesfound', (event) => {
        const routes = event.routes;
        const summary = routes[0].summary;
        const distanceInKm = (summary.totalDistance / 1000).toFixed(2);
        this.routeForm.controls.totalKm.setValue(distanceInKm);
        this.ngxLoaderService.stopBackground('do-background-things');

        this.dialogRef.close(this.routeForm.value);
      });
    } else
      this.dialogRef.close(this.routeForm.value);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  getOriginPlace(field: string) {
    this.originControl.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(val => {
      if (this.originControl.valid && this.originControl.value !== '' && this.originControl.value !== null && !this.originControl.value.hasOwnProperty('display_name')) {

        const regex = /^-?\d+(\.\d+)?, ?-?\d+(\.\d+)?$/; /** Expresion regular para evaluar que el lugarDestino escrito sea un par de coordenadas valido*/
        /**buscando por coordenadas**/
        if (regex.test(this.originControl.value)){
          const latlngStr = this.originControl.value.toString().split(',', 2);
          const latlng = new LatLng(
            parseFloat(latlngStr[0]),
            parseFloat(latlngStr[1])
          );
          /**agregando marcador al mapa*/
            this.placeOriginMarkerAndPanTo(latlng, true);

        } else {
          /** se verifica si viene de una búsqueda reversa*/
          if (this.searchByCoordinates){
            this.searchByCoordinates = false;
          } else {
            this.searchPlaceStandard(this.originControl.value, 'lugarOrigen');
          }
        }
      }
    });
  }

  getStopPlace(field: string) {
    this.stopControl.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(val => {
      if (this.stopControl.valid && this.stopControl.value !== '' && this.stopControl.value !== null && !this.stopControl.value.hasOwnProperty('display_name')) {
        //this.addStop({value: this.stopControl.value, chipInput: undefined, input: new HTMLInputElement});
        if (this.stopControl.value) {
          const regex = /^-?\d+(\.\d+)?, ?-?\d+(\.\d+)?$/; /** Expresion regular para evaluar que el value escrito sea un par de coordenadas valido*/
          if (regex.test(this.stopControl.value)) {
            const latlngStr = this.stopControl.value.toString().split(',', 2);
            const latlngStop = new LatLng(
              parseFloat(latlngStr[0]),
              parseFloat(latlngStr[1])
            );
            this.placeStopsMarkerAndPanTo(latlngStop, true);
          } else {
            /** se verifica si viene de una búsqueda reversa*/
            if (this.searchByCoordinates) {
              this.searchByCoordinates = false;
            } else {
              this.searchPlaceStandard(this.stopControl.value, 'lugarParadas');
            }
          }
        }
      }
    });
  }

  getDestinyPlace(field: string) {
    this.destinyControl.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(val => {
      if (this.destinyControl.valid && this.destinyControl.value !== '' && this.destinyControl.value !== null && !this.destinyControl.value.hasOwnProperty('display_name')) {

        const regex = /^-?\d+(\.\d+)?, ?-?\d+(\.\d+)?$/; /** Expresion regular para evaluar que el lugarDestino escrito sea un par de coordenadas valido*/
        /**buscando por coordenadas**/
        if (regex.test(this.destinyControl.value)){
          const latlngStr = this.destinyControl.value.toString().split(',', 2);
          const latlng = new LatLng(
            parseFloat(latlngStr[0]),
            parseFloat(latlngStr[1])
          );
          /**agregando marcador al mapa*/
            this.placeDestinyMarkerAndPanTo(latlng, true);

        } else {
          /** se verifica si viene de una búsqueda reversa*/
          if (this.searchByCoordinates){
            this.searchByCoordinates = false;
          } else {
            this.searchPlaceStandard(this.destinyControl.value, 'lugarDestino');
          }
        }
      }
    });
  }

  /**
   * Metodo para marcar que el origen está seleccionado
   */
  selectOriginInput(){
    this.inputSelected = 'lugarOrigen';
  }

  /**
   * Metodo para marcar que las paradas está seleccionado
   */
  selectStopsInput(){
    this.inputSelected = 'lugarParadas';
  }

  /**
   * Metodo para marcar que el destino está seleccionado
   */
  selectDestinyInput(){
    this.inputSelected = 'lugarDestino';
  }

  /**
   * Metodo para limpiar los datos del origen
   */
  clearCoordenadasOrigen(){
    this.routeForm.controls.latitudOrigen.setValue(null);
    this.routeForm.controls.longitudOrigen.setValue(null);
    this.hasOrigin = false;
    /**quitando el marcador y la ruta del mapa*/
    if (this.originMarker){
      this.map.removeLayer(this.originMarker);
    }
    if (this.routingControl){
      this.map.removeControl(this.routingControl);
      this.routeForm.controls.totalKm.setValue(null);
    }
  }

  /**
   * Metodo para limpiar los datos del destino
   */
  clearCoordenadasDestino(){
    this.routeForm.controls.latitudDestino.setValue(null);
    this.routeForm.controls.longitudDestino.setValue(null);
    this.hasDestiny = false;
    /**quitando el marcador y la ruta del mapa*/
    if (this.destinyMarker){
      this.map.removeLayer(this.destinyMarker);
    }
    if (this.routingControl) {
      this.map.removeControl(this.routingControl);
      this.routeForm.controls.totalKm.setValue(null);
    }
  }

  /**
   * Metodo para copiar las cordenadas
   * @param type
   */
  copyLocalization(type: string){
    let data: string;

    if (type === 'Origin' && this.routeForm.controls.latitudOrigen.value && this.routeForm.controls.longitudOrigen.value){
      data = this.routeForm.controls.latitudOrigen.value + ', ' + this.routeForm.controls.longitudOrigen.value;
      this.serviceService.copyDataToClipboard(data);
    } else if (type === 'Destiny' && this.routeForm.controls.latitudDestino.value && this.routeForm.controls.longitudDestino.value){
      data = this.routeForm.controls.latitudDestino.value + ', ' + this.routeForm.controls.longitudDestino.value;
      this.serviceService.copyDataToClipboard(data);
    }
  }

  /**
   * Metodo para actualizar los cambios al mover una parada de lugar
   * @param event
   */
  drop(event: CdkDragDrop<ServiceStopEntity[]>) {
    moveItemInArray(this.listStops, event.previousIndex, event.currentIndex);
    moveItemInArray(this.stopsMarker, event.previousIndex, event.currentIndex);

    this.listStops.forEach((stop, index) => {
      stop.numOrden = index + 1;
    });

    this.routeForm.controls.listStops.setValue(this.listStops);

    /**Actualizando los indices de los marcadores de las paradas en el mapa*/
    this.stopsMarker.forEach((stopMarker, index) => {
      stopMarker.options.title = 'P'+(index + 1).toString();
      stopMarker.setIcon(L.divIcon({
        className: 'custom-icon',
        html: this.generateCustomIconContent('P'+(index + 1).toString()),
        iconUrl: 'assets/images/stop-route-32.png',
        iconSize: [28, 28],
        iconAnchor: [15, 28],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
        shadowAnchor: [12, 41],
      }));
    });
      /**Si existe ruta anterior se borra del mapa*/
      if (this.routingControl){
          this.map.removeControl(this.routingControl);
        this.routeForm.controls.totalKm.setValue(null);
      }
  }

  /**
   * Metodo para detectar el Evento de cambio de la seleccion del chip
   * @param event
   */
  chipSelectionChange(event: MatChipSelectionChange){
    // console.log(event.source.value);
    if ( event.selected){
      const orden = event.source.value.split(":")[0].split("P")[1];
      this.selectedStop = this.listStops.filter(stop => stop.numOrden.toString() === orden)[0];
      this.selectedStopIndex = this.listStops.indexOf(this.selectedStop);
      this.selectedChip = event;
      this.selectStopsInput();
    }
  }

  /**
   * Metodo para detectar la eliminacion de un chip por la tecla delete o backspace y no permitirlo
   * @param event
   */
  onChipKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 8 || event.keyCode === 46) { // backspace or delete key
      this.removeByKeyboard = true;
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Metodo para agregar las paradas al arreglo de pardas
   * @param event
   * @param stopAddress
   * @param stopGeolocalization
   */
  addStop(event?: MatChipInputEvent, stopAddress?: string, stopGeolocalization?: LatLng): void {
    const value = (event?.value || '').trim();

    /**Inicializando la nueva parada*/
    let stop: ServiceStopEntity = new class implements ServiceStopEntity {
      distKmsParadaAnt = 0;
      geoLatitud = null;
      geoLongitud = null;
      id = 0;
      lugar = '';
      numOrden = 0;
      tiempoDemora = '';
      viajeId = 0;
      isManual = false;
    };
    if (value) {
      const regex = /^-?\d+(\.\d+)?, ?-?\d+(\.\d+)?$/; /** Expresion regular para evaluar que el value escrito sea un par de coordenadas valido*/
      if (regex.test(value)){
        const latlngStr = value.toString().split(',', 2);
        const latlngStop = new LatLng(
          parseFloat(latlngStr[0]),
          parseFloat(latlngStr[1])
        );
        this.placeStopsMarkerAndPanTo(latlngStop, true);
      } else {
        /** se verifica si viene de una búsqueda reversa*/
        if (this.searchByCoordinates){
          this.searchByCoordinates = false;
        } else {
          this.searchPlaceStandard(value, 'lugarParadas');
        }
      }
      /**Si existe ruta anterior se borra del mapa*/
      if (this.routingControl){
        this.map.removeControl(this.routingControl);
        this.routeForm.controls.totalKm.setValue(null);
      }
    } else if (stopAddress){
      stop.numOrden = this.listStops.length + 1;
      stop.lugar = stopAddress!;
      stop.geoLatitud = stopGeolocalization!.lat;
      stop.geoLongitud = stopGeolocalization!.lng;
      this.listStops.push(stop);
      /**limpiar el input parada*/
      this.chipsInput.nativeElement.value = "";
      this.routeForm.controls.listStops.setValue(this.listStops);
      this.stopControl.setValue(this.listStops);
      /**Si existe ruta anterior se borra del mapa*/
      if (this.routingControl){
        this.map.removeControl(this.routingControl);
        this.routeForm.controls.totalKm.setValue(null);
      }
    }

    // Clear the input value
    setTimeout(() => { /** para dar tiempo que encuentre el lugar para luego limpiar el chip input*/
    event?.chipInput!.clear();
    }, 1000);
  }

  /**
   * Metodo para eliminar las paradas de la ruta y del chiplist al eliminar en el chiplist
   * @param stop
   */
  removeStop(stop: ServiceStopEntity): void {
    setTimeout(() => {
      /**esta comprobacion es para saber si se presionó delete o backspace para eliminar la parada y no permitirlo**/
      if (!this.removeByKeyboard) {

        /**Limpio arreglo de paradas del mat-chip-list */
        const index = this.listStops.indexOf(stop);
        if (index >= 0) {
          if (stop.id) { /**si la parada tiene Id se borra del viaje mediante servicio*/
          this.ngxLoaderService.start();
            this.serviceService.deleteServiceStop(this.serviceEntity.id, stop.id)
              .subscribe({
                next: (delStop) => {

                  this.listStops.splice(index, 1);
                  this.listStops.forEach((stop, index) => {
                    stop.numOrden = index + 1;
                  });
                  /**limpiar el input parada*/
                  this.chipsInput.nativeElement.value = "";
                  this.routeForm.controls.listStops.setValue(this.listStops);
                  this.stopControl.setValue(this.listStops);

                  this.notificacionService.notificationSuccess(
                    'Se eliminó la parada correctamente.'
                  );
                  this.ngxLoaderService.stop();
                },
                error: (err) => {
                  this.ngxLoaderService.stop();
                  this.notificacionService.notificationWarning(
                    'Lo sentimos, ocurrió un error al eliminar la parada del viaje'
                  );
                },
              });
          } else { /**si no tiene Id se borra de la lista de paradas solamente*/
            this.listStops.splice(index, 1);
            this.listStops.forEach((stop, index) => {
              stop.numOrden = index + 1;
            });
            /**limpiar el input parada*/
            this.chipsInput.nativeElement.value = "";
            this.routeForm.controls.listStops.setValue(this.listStops);
            this.stopControl.setValue(this.listStops);
          }
        }

        /**Busco y borro el marcador de la parada del mapa*/
        let deleteMarker = this.stopsMarker.filter(marker =>
          (Number(marker.getLatLng().lat) === Number(stop.geoLatitud) && Number(marker.getLatLng().lng) === Number(stop.geoLongitud)));
        if (deleteMarker.length > 0) {
          this.map.removeLayer(deleteMarker[0]);
        }

        /**Limpio arreglo de marcadores de las paradas*/
        this.stopsMarker = this.stopsMarker.filter(marker =>
          (Number(marker.getLatLng().lat) !== Number(stop.geoLatitud) && Number(marker.getLatLng().lng) !== Number(stop.geoLongitud)));

        /**Actualizando los indices de los marcadores de las paradas en el mapa*/
        this.stopsMarker.forEach((stopMarker, index) => {
          stopMarker.options.title = 'P'+(index + 1).toString();
          stopMarker.setIcon(L.divIcon({
            className: 'custom-icon',
            html: this.generateCustomIconContent('P'+(index + 1).toString()),
            iconUrl: 'assets/images/stop-route-32.png',
            iconSize: [28, 28],
            iconAnchor: [15, 28],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
            shadowAnchor: [12, 41],
          }));
        });
          /**Si la parada eliminada ya estaba representada en el mapa y existe ruta anterior, se borra del mapa*/
          if (this.routingControl && stop.geoLatitud && stop.geoLongitud){
              this.map.removeControl(this.routingControl);
            this.routeForm.controls.totalKm.setValue(null);
          }
        //this.traceRoute();
      } else {
        this.removeByKeyboard = false;
      }
    }, 500);
  }

  /**
   * Metodo para trazar la ruta del viaje (Incurre en gastos de API)
   * @param directionsService
   * @param directionsRenderer
   */
  traceRoute(){
    if (this.originMarker?.getLatLng() && this.destinyMarker?.getLatLng()) {
      this.ngxLoaderService.startBackground('do-background-things');
      /**creando el arreglo de marcadores para la ruta*/
      let waypoints: LatLng[] = [];
      waypoints.push(this.originMarker.getLatLng());
      if (this.stopsMarker.length > 0){
        this.stopsMarker.forEach(stop =>{
          waypoints.push(stop.getLatLng());
        })
      }
      waypoints.push(this.destinyMarker.getLatLng());
      //this.waypoints = waypoints;
      /** Guardo el arreglo de puntos de la ruta*/
      //this.routeForm.controls.waypoints.setValue(waypoints);

      /**Si existe ruta anterior se borra del mapa*/
      if (this.routingControl){
          this.map.removeControl(this.routingControl);
        this.routeForm.controls.totalKm.setValue(null);
      }
      // this.routingControl = L.Routing.control({
      //   waypoints: waypoints,
      //   show: false,
      //   routeWhileDragging: false,
      //   //autoRoute: false
      // }).addTo(this.map);

        this.routingControl = L.Routing.control({
            waypoints: waypoints,
            router: L.Routing.mapbox(this.mapBoxToken, {
              //profile: 'mapbox/driving' // Perfil de conducción para obtener la ruta más corta
              //profile: 'mapbox/walking' // Perfil de peatón para obtener la ruta más corta
              //profile: 'mapbox/cycling' // Perfil de ciclismo para obtener la ruta más corta
            }),
            show: false,
            routeWhileDragging: false,
        }).addTo(this.map);

      /**Centrar el mapa en el pto medio de la ruta*/
      if(waypoints && waypoints.length>0){
        this.polyline = L.polyline(waypoints);
        // Obtener los límites de la polilínea
        const bounds = this.polyline.getBounds()
        // Obtener el centro de los límites de la polilínea
        const centerLatLng = bounds.getCenter();
        // Obtener la latitud y longitud del centro
        const centerLat = centerLatLng.lat;
        const centerLng = centerLatLng.lng;
        // Ajustar el mapa para que se muestre la polilínea
        this.map.fitBounds(bounds);
        // Crear un objeto LatLng con las coordenadas
        const locationLatLng = new LatLng(
          centerLat,
          centerLng
        );
        // Establecer la ubicación en el centro del mapa
        this.map.setView(locationLatLng, this.map.getZoom());
      }
      this.routeForm.controls.rutaOrigDestino.setValue(this.polyUtil.encode(waypoints));
      this.routingControl.on('routesfound', (event) => {
        const routes = event.routes;
        const summary = routes[0].summary;
        const distanceInKm = (summary.totalDistance / 1000).toFixed(2);
        const timeInHours = Math.floor(summary.totalTime / 3600);
        const timeInMinutes = Math.floor((summary.totalTime % 3600) / 60);
        this.routeForm.controls.totalKm.setValue(distanceInKm);
        //this.routeForm.controls.rutaOrigDestino.setValue(this.polyUtil.encode(waypoints));
        console.log(distanceInKm + ' km');
        console.log(timeInHours + ' hours ' + timeInMinutes + ' minutes');
        this.ngxLoaderService.stopBackground('do-background-things');
      });
    }
  }

  /**
   * Metodo para intercambiar el origen y el destino
   */
  changeOriginDestiny(){
    let savedOrigin: string | undefined;
    let savedLatitudOrigen!: number;
    let savedLongitudOrigen!: number;

    let savedDestiny: string | undefined;
    let savedLatitudDestino!: number;
    let savedLongitudDestino!: number;

    /**salvando Origen**/
    if (this.routeForm.controls.lugarOrigen.value){
      savedOrigin = this.routeForm.controls.lugarOrigen.value;
    }
    if (this.routeForm.controls.latitudOrigen.value && this.routeForm.controls.longitudOrigen.value){
      savedLatitudOrigen = this.routeForm.controls.latitudOrigen.value;
      savedLongitudOrigen = this.routeForm.controls.longitudOrigen.value;
    }

    /**salvando destino **/
    if (this.routeForm.controls.lugarDestino.value){
      savedDestiny = this.routeForm.controls.lugarDestino.value;
    }
    if (this.routeForm.controls.latitudDestino.value && this.routeForm.controls.longitudDestino.value){
      savedLatitudDestino = this.routeForm.controls.latitudDestino.value;
      savedLongitudDestino = this.routeForm.controls.longitudDestino.value;
    }

    /** haciendo el intercambio**/
    if (savedOrigin){
      this.routeForm.controls.lugarDestino.setValue(savedOrigin);
      this.destinyControl.setValue(savedOrigin);

      if (savedLatitudOrigen && savedLongitudOrigen){
        const latlng = new LatLng(
          parseFloat(String(savedLatitudOrigen)),
          parseFloat(String(savedLongitudOrigen))
        );
        this.placeDestinyMarkerAndPanTo(latlng, true);
      }

      if (this.hasDestiny){
        this.savedDestiny = savedOrigin;
        this.savedLatitudDestino = savedLatitudOrigen;
        this.savedLongitudDestino = savedLongitudOrigen;
      }
    }
    if (savedDestiny){
      this.routeForm.controls.lugarOrigen.setValue(savedDestiny);
      this.originControl.setValue(savedDestiny);

      if (savedLatitudDestino && savedLongitudDestino){
        const latlng = new LatLng(
          parseFloat(String(savedLatitudDestino)),
          parseFloat(String(savedLongitudDestino))
        );
        this.placeOriginMarkerAndPanTo(latlng, true);
      }

      if (this.hasOrigin){
        this.savedOrigin = savedDestiny;
        this.savedLatitudOrigen = savedLatitudDestino;
        this.savedLongitudOrigen = savedLongitudDestino;
      }
    }
    this.routeForm.controls.intercambiarRuta.setValue(true);
    if (this.routingControl){
      this.traceRoute();
    }
  }

  /**
   * Autocomplete Origin events
   * @param user
   */
  displayOriginFn(place: PlaceEntity): string {
    return place && place.display_name ? place.display_name : '';
  }

  private _filterOrigin(name: string): PlaceEntity[] {
    const filterValue = name.toLowerCase();

    return this.listOriginPlace.filter(place => place.display_name.toLowerCase().includes(filterValue));
  }

  /**
   * Autocomplete Destiny events
   */
  displayDestinyFn(place: PlaceEntity): string {
    return place && place.display_name ? place.display_name : '';
  }

  private _filterDestiny(name: string): PlaceEntity[] {
    const filterValue = name.toLowerCase();

    return this.listDestinyPlace.filter(place => place.display_name.toLowerCase().includes(filterValue));
  }

  /**
   * Autocomplete Stop events
   */
  displayStopFn(place: PlaceEntity): string {
    return place && place.display_name ? place.display_name : '';
  }

  private _filterStop(name: string): PlaceEntity[] {
    const filterValue = name.toLowerCase();

    return this.listStopPlace.filter(place => place.display_name.toLowerCase().includes(filterValue));
  }

  /**
   * Metodo para capturar el evento de seleccionar el origen
   * @param event
   */
  onSelectOrigin(event: any){
    if (event.option.value !== null) {
      /**Siempre se toman las coordenadas del mapa tenga o no origen*/

      this.routeForm.controls.lugarOrigen.setValue(event.option.value.display_name);
      this.routeForm.controls.latitudOrigen.setValue(event.option.value.lat);
      this.routeForm.controls.longitudOrigen.setValue(event.option.value.lon);
      const latlng = new LatLng(
        parseFloat(event.option.value.lat),
        parseFloat(event.option.value.lon)
      );
      this.originControl.setValue(event.option.value);
      this.hasOrigin = true;
      this.placeOriginMarkerAndPanTo(latlng, false);
    }
  }

  /**
   * Metodo para capturar el evento de seleccionar el destino
   * @param event
   */
  onSelectDestiny(event: any){
    if (event.option.value !== null) {
      /**Siempre se toman las coordenadas del mapa tenga o no destino*/
      this.routeForm.controls.lugarDestino.setValue(event.option.value.display_name);
      this.routeForm.controls.latitudDestino.setValue(event.option.value.lat);
      this.routeForm.controls.longitudDestino.setValue(event.option.value.lon);
      const latlng = new LatLng(
        parseFloat(event.option.value.lat),
        parseFloat(event.option.value.lon)
      );
      this.destinyControl.setValue(event.option.value);
      this.hasDestiny = true;
      this.placeDestinyMarkerAndPanTo(latlng, false);
    }
  }

  /**
   * Metodo para capturar el evento de seleccionar una parada
   * @param event
   */
  onSelectStop(event: any){
    if (event.option.value !== null) {
      /**Siempre se toman las coordenadas del mapa tenga o no destino*/

      const latlng = new LatLng(
          parseFloat(event.option.value.lat),
          parseFloat(event.option.value.lon)
      );
      if(this.selectedStop && this.selectedStopIndex !== undefined){
         /**Actualizando el marcador a la lista de paradas del sistema*/
        this.listStops[this.selectedStopIndex].geoLatitud = event.option.value.lat;
        this.listStops[this.selectedStopIndex].geoLongitud = event.option.value.lon;

        /**Actualizando el marcador en la lista de marcadores o ubicando el punto en el mapa, correspondiente a la dirección seleccionada*/
        if (this.stopsMarker && this.stopsMarker.length > 0) {

          /**Actualizo el marcador correspondiente a la parada seleccionada, no necesariamente coinciden en index*/
          let marker = this.stopsMarker.filter(stopMarker =>
            (this.selectedStop?.geoLatitud && this.selectedStop?.geoLongitud &&
              this.selectedStop?.geoLatitud === stopMarker.getLatLng().lat && this.selectedStop?.geoLongitud === stopMarker.getLatLng().lng))[0];

          if (marker)
            marker.setLatLng(latlng);
          else
            this.placeStopsMarkerAndPanTo(latlng, false, true);
          //this.stopsMarker[this.selectedStopIndex].setLatLng(latlng);
        }else
          this.placeStopsMarkerAndPanTo(latlng, false, true);
        /** */
        this.chipsInput.nativeElement.value = "";
        this.selectedStop = undefined;
        this.selectedStopIndex = undefined;
        this.chipList._setSelectionByValue(this.chipList.selected, false);/** limpiando la seleccion del chipList **/
      } else {
        let stop: ServiceStopEntity = new class implements ServiceStopEntity {
          distKmsParadaAnt = 0;
          geoLatitud = null;
          geoLongitud = null;
          id = 0;
          lugar = '';
          numOrden = 0;
          tiempoDemora = '';
          viajeId = 0;
          isManual = false;
        };
        stop.numOrden = this.listStops.length + 1;
        stop.lugar = event.option.value.display_name;
        stop.geoLatitud = event.option.value.lat;
        stop.geoLongitud = event.option.value.lon;
        this.listStops.push(stop);
        /**limpiar el input parada*/
        this.chipsInput.nativeElement.value = "";
        this.routeForm.controls.listStops.setValue(this.listStops);
        this.stopControl.setValue(this.listStops);
        this.placeStopsMarkerAndPanTo(latlng, false, true);
      }
      /**Si la parada eliminada ya estaba representada en el mapa y existe ruta anterior, se borra del mapa*/
      if (this.routingControl){
        this.map.removeControl(this.routingControl);
        this.routeForm.controls.totalKm.setValue(null);
      }
    }
  }


  /**
   * Método para restaurar el lugar de origen inicial
   */
  restoreOrigin() {
    this.routeForm.controls.lugarOrigen.setValue(this.savedOrigin);
    this.routeForm.controls.latitudOrigen.setValue(this.savedLatitudOrigen);
    this.routeForm.controls.longitudOrigen.setValue(this.savedLongitudOrigen);
    //this.originControl.setValue(this.savedOrigin);
    //this.restoredOrigin = true;
    if (this.savedLatitudOrigen && this.savedLongitudOrigen) {
      const latlng = new LatLng(
          parseFloat(String(this.savedLatitudOrigen)),
          parseFloat(String(this.savedLongitudOrigen))
      );
      this.placeOriginMarkerAndPanTo(latlng, true);
    }
    this.restoredOrigin = true;
  }
  /**
   * Método para restaurar el lugar de destino inicial
   */
  restoreDestiny() {
    this.routeForm.controls.lugarDestino.setValue(this.savedDestiny);
    this.routeForm.controls.latitudDestino.setValue(this.savedLatitudDestino);
    this.routeForm.controls.longitudDestino.setValue(this.savedLongitudDestino);
    //this.destinyControl.setValue(this.savedDestiny);
    //this.restoredDestiny = true;
    if (this.savedLatitudDestino && this.savedLongitudDestino) {
      const latlng = new LatLng(
          parseFloat(String(this.savedLatitudDestino)),
          parseFloat(String(this.savedLongitudDestino))
      );
      this.placeDestinyMarkerAndPanTo(latlng, true);
    }
    this.restoredDestiny = true;
  }

  generateCustomIconContent(text: string): string {
    return `
     <div class="leaflet-marker-icon leaflet-glyph-icon leaflet-zoom-animated leaflet-interactive leaflet-marker-draggable"
          style='background-image: url("../../../../../assets/images/stop-marker.png"); height: 41px; text-align: center; margin-left: 0px; margin-top: -7px; pointer-events: none; display: inline-block;'>
       <span class=" " style="font-size: 11px; color: white; width: 25px; line-height: 41px; text-align: center; margin-left: 0px; margin-top: -7px; pointer-events: none; display: inline-block;">${text}</span>
     </div>
    `;
  }

  protected readonly event = event;
}
