import {
  LoginRequest,
  LogoutRequest,
  ProvinceEntity,
} from './../../shared/models/login-request.model';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {RolePermisions, UserLogin} from './../../shared/models/user-model';
import { ApiCodeMessage } from 'src/app/shared/consts/api-code-message.constant';
import {ServiceRequest} from "../../shared/models/service-model";
import {EstadoServicioId} from "../../shared/consts/estado-servicio";
import {Router} from "@angular/router";
import {Routes} from "../../shared/consts/routes";
import {ConfigurationService} from "../../shared/services/configuration.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {NotificationService} from "../../shared/services/notification.service";
import {NgxPermissionsService, NgxRolesObject, NgxRolesService} from "ngx-permissions";
import {ModoTrabajo} from "../../shared/consts/modo-trabajo";
import { ErrorDialogModel, ErrorDialogComponent } from 'src/app/shared/components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';

const USER_KEY = 'currentUser';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user!: UserLogin;
  accessToken!: string | null;
  refreshToken!: string | null;

  private isLoginSource = new BehaviorSubject<boolean>(false);
  isLogin$ = this.isLoginSource.asObservable();

  public routes: typeof Routes = Routes;

  constructor(private _http: HttpClient, private router: Router, private permissionsService: NgxPermissionsService, private roleService: NgxRolesService,
              private configurationService: ConfigurationService, private notificacionService: NotificationService, public dialog: MatDialog) {}

  handleLoginError(error: HttpErrorResponse) {
    let mensajeError = undefined;
    if (error.error instanceof ErrorEvent) {
      console.error('Error:', error.error.message);
      mensajeError = `Error: ${error.error.message}`;
    } else {
      switch (error.status) {
        case 400:
          console.error(`Error del backend, código: ${error.status}, `);
          mensajeError = error.error.error;
          break;
        case 500:
          console.error(`Error del backend, código: ${error.status} `);
          break;
        case 503:
          console.error(`Error del backend, código: ${error.status} `);
          mensajeError = ApiCodeMessage.MSG_CODE_503;
          break;
        case 403:
          console.error(`Error de autenticación, código: ${error.status} `);

          // if (error.url?.match('refresh_token')){
          //   this.router.navigate([this.routes.LOGIN]);
          //   break;
          // }

          // let err = error.error.replaceAll('=', ':');
          mensajeError = error.error.error;
          break;
        default:
          console.error(`Error: ${error} `);
          mensajeError = error;
          break;
      }
    }
    return throwError(mensajeError);
  }

  saveCurrentUser(): void {
    let permisions: string[] = [];
    let role = this.user.rolDescCorta;

    this.user.acciones.forEach(accion =>{
      permisions.push(accion.descripcion)
    });

    this.permissionsService.loadPermissions(permisions); /*** Definiendo permisos para la libreria 'ngx-permissions' ***/

    const rolesActuales: NgxRolesObject = this.roleService.getRoles();
    for (const rol in rolesActuales) {
      if (rolesActuales.hasOwnProperty(rol) && rol !== role) {
        this.roleService.removeRole(rol);
      }
    }

    this.roleService.addRole(role, permisions); /*** Definiendo roles para la libreria 'ngx-permissions'***/

    /** base64 encode */
    const userEncoded = btoa(JSON.stringify(this.user));

    localStorage.setItem(
      'currentUser',
      JSON.stringify({
        user: userEncoded,
      })
    );
  }

  saveSecurityTokens(): void {
    if (this.accessToken) {
      localStorage.setItem('accessToken', this.accessToken);
    }

    if (this.refreshToken) {
      localStorage.setItem('refreshToken', this.refreshToken);
    }
  }

  login(loginRequest: LoginRequest, createTurno?: boolean, tipoPersona?: string, tipoSO?: string ): Observable<any> {
    const headers = new HttpHeaders({
      accept: 'application/json',
      // 'Access-Control-Expose-Headers': 'access_token, refresh_token',
    });

    let queryParams = new HttpParams();

    loginRequest.idCategoria !== undefined
      ? (queryParams = queryParams.append('?idCategoria', loginRequest.idCategoria))
      : null;
    loginRequest.idJefeTurno !== undefined
      ? (queryParams = queryParams.append('idJefeTurno', loginRequest.idJefeTurno))
      : null;
    loginRequest.username  !== undefined
      ? (queryParams = queryParams.append('username', loginRequest.username))
      : null;
    loginRequest.password !== undefined
      ? (queryParams = queryParams.append('password', loginRequest.password))
      : null;
    createTurno !== undefined
      ? (queryParams = queryParams.append('createTurno', createTurno))
      : null;
    tipoPersona !== undefined
      ? (queryParams = queryParams.append('tipoPersona', tipoPersona))
      : null;
    tipoSO
      ? (queryParams = queryParams.append('tipoSO', tipoSO))
      : null;

    return this._http.post<any>(environment.serviceLogin + queryParams, headers, {observe: 'response'}).pipe(
      map((data) => {
        if (data.body.result) {
          this.user = data.body.result;
          let headers = data.headers;
          this.accessToken = headers.get("access_token");
          this.refreshToken = headers.get("refresh_token");

          this.saveCurrentUser();
          this.saveSecurityTokens();
          this.saveAppConfig();
          this.isLoginSource.next(true);
          return data.body;
        } else {
          return data.body;
        }
      }),
      catchError(this.handleLoginError)
    );
  }

  refreshLogin(): Observable<any> {
    const refreshToken = localStorage.getItem('refreshToken');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${refreshToken}`
    });

    const options = {
      headers: headers,
      observe: 'response' as const
    };

    return this._http.get<any>(environment.serviceRefreshLogin, options).pipe(
      map((data) => {
        if (data.headers){
          let headers = data.headers;
          this.accessToken = headers.get("access_token");
          this.refreshToken = headers.get("refresh_token");
          this.saveSecurityTokens();
          return this.accessToken;
        } else {
          return false;
        }
      }),
      catchError(this.handleLoginError)
    );
  }

  // refreshLogin(): Observable<any> {
  //   const refreshToken = sessionStorage.getItem('refreshToken');
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ${refreshToken}`
  //   });
  //
  //   return this._http.get<any>(environment.serviceRefreshLogin, {header: headers, observe: 'response'}).pipe(
  //     map((data) => {
  //       if (data.body.result) {
  //         // this.user = data.body.result;
  //         let headers = data.headers;
  //         this.accessToken = headers.get("access_token");
  //         this.refreshToken = headers.get("refresh_token");
  //
  //         this.saveCurrentUser();
  //         this.saveSecurityTokens();
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     }),
  //     catchError(this.handleLoginError)
  //   );
  // }

  createShift(data: LoginRequest): Observable<any> {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this._http
      .post<any>(environment.serviceCreateShift, data, HttpOptions)
      .pipe(
        map((data) => {
          this.user = data.result;
          this.saveCurrentUser();
          return of(true);
        }),
        catchError(this.handleLoginError)
      );
  }

  logout(data: LogoutRequest): Observable<any> {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this._http
      .post<any>(environment.serviceLogout, data, HttpOptions)
      .pipe(
        map((res) => {
          localStorage.removeItem('currentUser');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('appConfig');
          localStorage.removeItem('nameProv');
          localStorage.removeItem('userProv');
          localStorage.removeItem('temasInformes');
          localStorage.removeItem('userRol');
          /** esta comentado para que no se limpien los codificafdores  que se guardaron ahi */
          // localStorage.clear();
          this.roleService.flushRolesAndPermissions();
          this.isLoginSource.next(false);
          return of(true);
        }),
        catchError(this.handleLoginError)
      );
  }

  public getUser(): Observable<any> {
    const userEncoded = localStorage.getItem('currentUser');
    if (userEncoded) {
      const user = JSON.parse(userEncoded).user;
      return of(JSON.parse(atob(user)));
    } else {
      this.router.navigate([this.routes.LOGIN]);
      return of(null);
    }
  }

  /** Obtener todas las categorías activas para listar en login */
  getCategories(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const queryParams = new HttpParams().append('activa', true);
    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.serviceCategories, options).pipe(
      map((data) => data),
      catchError(this.handleLoginError)
    );
  }

  /** Obtener todas las Provincias activas para listar en login */
  getProvinces(): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
    });
    const queryParams = new HttpParams().append('activa', true);
    const options = {
      headers: headers,
      params: queryParams,
    };

    return this._http.get<any>(environment.serviceProvinces, options).pipe(
      map((data) => data),
      catchError(this.handleLoginError)
    );
  }

  saveCurrentUserProvince(userProv: any): void {
    localStorage.setItem('nameProv', userProv[0].nombre);
    /** base64 encode */
    const userProvEncoded = btoa(JSON.stringify(userProv));

    localStorage.setItem(
      'userProv',
      JSON.stringify({
        prov: userProvEncoded,
      })
    );
  }

  saveWorkMode(workMode: string): void {
    if (workMode){
      localStorage.setItem('workMode', workMode);
    }
  }

  saveAppConfig() {
    this.configurationService.getAppConfig().subscribe({
      next: (res) => {
        /** base64 encode */
        const appConfigEncoded = btoa(JSON.stringify(res));

        localStorage.setItem('appConfig', appConfigEncoded);
      },
      error: (err) => {
        /*this.notificacionService.notificationError(
          'Lo sentimos, ocurrió un error al cargar las configuraciones'
        );*/
        let msg = 'Lo sentimos, ocurrió un error al obtener las configuraciones.';
        const dialogData = new ErrorDialogModel('Error', msg);
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          maxWidth: '400px',
          data: dialogData,
        });

        dialogRef.afterClosed().subscribe((dialogResult) => {
          if (dialogResult) {
          }
        });
      },
    });
  }

  public getAppConfig() {
    const appConfigEncoded = localStorage.getItem('appConfig');
    if (appConfigEncoded) {
      // const appConfig = JSON.parse(appConfigDecoded);
      return of(JSON.parse(atob(appConfigEncoded)));
    } else {
      return of(null);
    }
  }

  /** Obtener Codificador modo de trabajo */
  getWorkMode() {
    return [
      {
        id: 1,
        nombre: ModoTrabajo.ONLINE,
        descCorta: ModoTrabajo.ONLINE,
        descripcion: ModoTrabajo.ONLINE,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 2,
        nombre: ModoTrabajo.OFFLINE_PARTIAL,
        descCorta: ModoTrabajo.OFFLINE_PARTIAL,
        descripcion: ModoTrabajo.OFFLINE_PARTIAL,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 3,
        nombre: ModoTrabajo.OFFLINE_TOTAL,
        descCorta: ModoTrabajo.OFFLINE_TOTAL,
        descripcion: ModoTrabajo.OFFLINE_TOTAL,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
      {
        id: 4,
        nombre: ModoTrabajo.LOCAL,
        descCorta: ModoTrabajo.LOCAL,
        descripcion: ModoTrabajo.LOCAL,
        fechaCreacion: new Date(),
        fechaModif: new Date(),
      },
    ];
  }

  public isLoggedIn(): boolean {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) {
      return true;
    }

    return false;
  }
}
