import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogModel} from "../confirm-dialog/confirm-dialog.component";
import {NotificationService} from "../../services/notification.service";
import {RouteService} from "../../services/route.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent implements OnInit, OnDestroy {

  title: string;
  message: string;

  closeModalSubscription: Subscription;

  constructor(public dialogRef: MatDialogRef<InformationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel, private routeService: RouteService) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.closeModalSubscription = this.routeService.closeModals$.subscribe(closeModal => {
      if (closeModal) {
        this.dialogRef.close(false);
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.closeModalSubscription){
      this.closeModalSubscription.unsubscribe();
    }
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }
}
/**
 * Class to represent information dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class InformationDialogModel {
  constructor(public title: string, public message: string) {
  }
}
