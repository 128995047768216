<div class="page-holder align-items-center bg-gray-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4"></div>
      <div class="col-lg-4">
        <div class="text-center text-primary">
          <img
            class="img-fluid"
            width="60"
            src="assets/images/logo-rene-login.jpg"
            alt="logo rene"
          />
          <h1 class="mb-3">Control de Servicios</h1>
        </div>
        <div class="card text-center">
          <div class="card-header px-lg-5">
            <div class="card-heading text-center">Iniciar sesión</div>
          </div>
          <div class="card-body p-lg-4">
            <form [formGroup]="loginForm">
              <mat-form-field class="form-field-width" appearance="outline">
                <input
                  matInput
                  type="text"
                  id="username"
                  placeholder="Teléfono"
                  autocomplete="off"
                  formControlName="user"
                  (blur)="showError('user')"
                  required
                />
                <mat-error *ngIf="getMessageError('user') !== MSG_EMPTY">{{
                  getMessageError("user")
                }}</mat-error>
              </mat-form-field>
              <mat-form-field class="form-field-width" appearance="outline">
                <input
                  matInput
                  type="password"
                  id="password"
                  placeholder="Contraseña"
                  autocomplete="off"
                  formControlName="password"
                  (blur)="showError('password')"
                  required
                />
                <mat-error *ngIf="getMessageError('password') !== MSG_EMPTY">{{
                  getMessageError("password")
                }}</mat-error>
              </mat-form-field>
              <mat-form-field class="form-field-width" appearance="outline">
                <mat-label>Categoría</mat-label>
                <mat-select
                  formControlName="category"
                  (blur)="showError('category')"
                >
                  <mat-option
                    *ngFor="let category of listCategories"
                    [value]="category.id"
                  >
                    {{ category.nombre }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="getMessageError('category') !== MSG_EMPTY">{{
                  getMessageError("category")
                }}</mat-error>
              </mat-form-field>
              <mat-form-field class="form-field-width" appearance="outline">
                <mat-label>Provincia</mat-label>
                <mat-select formControlName="prov" (blur)="showError('prov')">
                  <mat-option *ngFor="let prov of listProv" [value]="prov.id">
                    {{ prov.nombre }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="getMessageError('prov') !== MSG_EMPTY">{{
                  getMessageError("prov")
                }}</mat-error>
              </mat-form-field>
              <div class="row">
                <div class="col-md-10">
                  <mat-form-field class="form-field-width" appearance="outline">
                    <mat-label>Modo Trabajo</mat-label>
                    <mat-select formControlName="workMode" (valueChange)="onSelectWorkMode($event)" (blur)="showError('workMode')">
                      <mat-option *ngFor="let workMode of listWorkMode" [value]="workMode.descCorta">
                        {{ workMode.nombre }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="getMessageError('workMode') !== MSG_EMPTY">{{
                        getMessageError("workMode")
                      }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <mat-icon matTooltip="Actualizar" class="color-mat-icon-button-grey maticon"
                            style="margin-top: 24px; margin-left: -55px;"
                            (click)="refreshWorkMode()">loop</mat-icon>
                </div>
              </div>

              <button
                class="form-field-width"
                mat-flat-button
                color="primary"
                type="submit"
                (click)="login()"
                [disabled]="!loginForm.valid"
              >
                Ingresar
              </button>
            </form>
          </div>
          <div class="card-footer px-lg-5">
            <div class="text-sm text-muted text-center">
              © 2023-{{annoActual}}  Rene-Taxis
            </div>
            <div class="text-sm text-muted text-center">
              Desarrollado por ReneSolución
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4"></div>
    </div>
  </div>
</div>
