import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './auth/login/login.component';
import {environment} from "../../environments/environment";

@NgModule({
  declarations: [LoginComponent],
  imports: [CommonModule, SharedModule],
  providers: [
    {
      provide: "LOCAL_URL", useValue: environment.localUrl,
    },
    {
      provide: "BASE_URL", useValue: environment.urlOrigin,
    },
    {
      provide: "PROD_MODE", useValue: environment.production,
    }

  ]
})
export class CoreModule {}
